import React                 from 'react';
import store                 from '../../helpers/store/Store';
import { connect }           from 'react-redux';
import { getHubChildStruct } from '../../helpers/axios/Get';
import {
  getSelectedModel,
  properties,
  getSelectedProject,
  getSelectedModelArray,
} from '../../helpers/actions/Actions';
import { Trans, withTranslation } from 'react-i18next';

import { requestData } from '../../helpers/axios/Get';
import {
  FETCH_SELECTED_PROJECT_DATA_PENDING,
  FETCH_SELECTED_PROJECT_DATA_SUCCESS,
  FETCH_SELECTED_PROJECT_DATA_ERROR,
  API_URL,
  HOST_URL,
} from '../../helpers/constants/Constants';

const axios = require('axios');

class ProjectBrowser extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      mobileWidth:     false,
      tabletWidth:     false,
      maxViewers:      1,
      hovered:         [false, "" ],
      models: [
        // {
        //   fileName: 'cc_Generisk_Kontorskomplex.rvt',
        //   urn:  HOST_URL + '/models/cc_Generisk_Kontorskomplex/Resource/3D View/SwecoCarbonCostTool 1013131/SwecoCarbonCostTool.svf'
        // },
        {
          fileName: 'cc_Generisk_Förskola.rvt',
          text: 'demo',
          children: false,
          fileType: "rvt",
          type: "item",
          urn:  HOST_URL+ '/models/cc_Generisk_Förskola/Resource/3D View/SwecoCarbonCostTool 1017138/SwecoCarbonCostTool.svf'
        },
        {
          fileName: 'cc_Generisk_Bostadshus.rvt',
          text: 'demo',
          children: false,
          fileType: "rvt",
          type: "item",
          urn:  HOST_URL+ '/models/cc_Generisk_Flerbostadshus/Resource/3D View/SwecoCarbonCostTool 1227194/SwecoCarbonCostTool.svf'
        },
        {
          fileName: 'cc_Generisk_Paddelhall.rvt',
          text: 'demo',
          children: false,
          fileType: "rvt",
          type: "item",
          urn:  HOST_URL+ '/models/cc_Generisk_Paddelhall/Resource/3D View/SwecoCarbonCostTool 1011323/SwecoCarbonCostTool.svf'
        }
      ]
    }
  }

  handleMouseEnter = (e) => {
    this.setState({ hovered: [true, e ] });
  }

  handleMouseLeave = (e) => {
    this.setState({ hovered: [false, e ] });
  }

  renderSubMenu = (children, expanded, version, depth) => {
    return (expanded && children && children.length > 0) 
      ? <ul>{this.renderMenuItems(children, version, depth+1)}</ul> 
      : null;
  };

  renderMenuItems = (items, version, depth) =>
    items.map((item, i) => {
      const { text, children, versions, expanded, childArray, type, fileType, active, fileName, hrefbim360} = item;
      const C3Project = item.ProjectStatus ? (item.ProjectStatus === "Approved" ? true : false) : true;

      if (item.Packages && item.childArray && item.childArray.length != 0) {
        item.Packages.forEach((p)=> p.active = true)
        childArray.forEach((child)=> child.Packages = item.Packages)
      }


      if (children || versions) {

        let isInBlackList = this.props.blackList.filter(obj => {
          return obj.type.toLowerCase() === text.toLowerCase();
        });

        if (!isInBlackList[0]) {

          return (
            <li
              key           = {i}
              aria-expanded = {expanded ? true : false}
              className     = {`${item.loading ? 'data-loading' : 'data-not-loading'}`}
              // style={{display: 'flex'}}
            >

              {item.versions ?
              // <div style={{display: 'flex'}}>
                <div
                  type           = 'button'
                  className      = 'btn icon-null icon-folder has-children'
                  onClick        = {(e) => this.updateState(e, item)}
                  data-toggle    = 'tooltip'
                  data-placement = 'right'
                  style={{display: 'grid', maxHeight: '30px', alignContent: 'center', transform: depth > 0 ? 'translateX('+depth*21+'px)': 'translateX(0px)'}}
                >

                <button
                  type           = 'button'
                  className      = 'btn icon-slim icon-file'
                  onClick        = {(e) => this.clickedItem(e, item, true)}
                  text           = {item.fileName + (item.version > 0 ? " - v"+item.version.toString() : "")}
                  data-toggle    = 'tooltip'
                  data-placement = 'right'
                  title          = {item.fileName + (item.version > 0 ? " - v"+item.version.toString() : "")}
                >
                  <div className = 'sk-circle'>
                    <div className = 'sk-circle1 sk-child'/>
                    <div className = 'sk-circle2 sk-child'/>
                    <div className = 'sk-circle3 sk-child'/>
                    <div className = 'sk-circle4 sk-child'/>
                    <div className = 'sk-circle5 sk-child'/>
                    <div className = 'sk-circle6 sk-child'/>
                    <div className = 'sk-circle7 sk-child'/>
                    <div className = 'sk-circle8 sk-child'/>
                    <div className = 'sk-circle9 sk-child'/>
                    <div className = 'sk-circle10 sk-child'/>
                    <div className = 'sk-circle11 sk-child'/>
                    <div className = 'sk-circle12 sk-child'/>
                  </div>
                  {this.nameShortening(item.fileName + (item.version >0 ? " - v"+item.version.toString() : ""), true)}
                </button>
              </div>
              :
              <button
                type           = 'button'
                className      = {item.type === "bim360projects" ? 'btn icon icon-cloud' + (C3Project ? ' has-children' : "") : 'btn icon icon-folder has-children'}
                onClick        = {(e) => C3Project ? this.updateState(e, item) : null}
                text           = {text}
                data-toggle    = 'tooltip'
                data-placement = 'right'
                title          = {text}
                onMouseEnter={() => this.handleMouseEnter(text)}
                onMouseLeave={() => this.handleMouseLeave(text)}
              >
                <div className = 'sk-circle'>
                  <div className = 'sk-circle1 sk-child'/>
                  <div className = 'sk-circle2 sk-child'/>
                  <div className = 'sk-circle3 sk-child'/>
                  <div className = 'sk-circle4 sk-child'/>
                  <div className = 'sk-circle5 sk-child'/>
                  <div className = 'sk-circle6 sk-child'/>
                  <div className = 'sk-circle7 sk-child'/>
                  <div className = 'sk-circle8 sk-child'/>
                  <div className = 'sk-circle9 sk-child'/>
                  <div className = 'sk-circle10 sk-child'/>
                  <div className = 'sk-circle11 sk-child'/>
                  <div className = 'sk-circle12 sk-child'/>
                </div>
                {!C3Project && this.state.hovered[0] && text === this.state.hovered[1]? 
                  <a target="_blank" rel="noreferrer" href="https://www.sweco.se/transforming-society-together/digitalisering/carbon-cost-compass/"><Trans i18nKey="projectBrowser.notC3"/></a>
                  :
                  this.nameShortening(text, item.type === "bim360projects" ? C3Project : true)
                }
              </button>
              }
              {C3Project ? this.renderSubMenu(childArray, expanded, item.version, depth) : null}
            </li>
          );
        }
      }
      else if (type === 'version' || type === 'item') {

        let isInWhiteList = this.props.whiteList.filter(obj => {
          return obj.type === fileType;
        });

        if (isInWhiteList[0]) {
          return (
            <li key = {i}>
              <button
                type           = 'button'
                className      = {`btn icon icon-file ${fileType ? 'icon-file-' + fileType : 'icon-file'} ${active ? 'active' : 'non-active'}`}
                onClick        = {(e) => C3Project ? this.clickedItem(e, item, true) : null}
                title          = {fileName + (item.version >0 ? " - v"+item.version.toString() : "")}
                // data-toggle    = 'tooltip'
                // data-placement = 'top'
                style          = {{transform: depth > 0 ? 'translateX('+depth*21+'px)': 'translateX(-10px)'}}
              >
                {this.nameShortening(fileName + (item.version >0 ? " - v"+item.version.toString() : ""), true)}
                
              </button>
            </li>
          );
        }
        else {

          return (
            <li key = {i}>
              <a
                className      = {`btn icon icon-file ${fileType ? 'icon-file-' + fileType : 'icon-file'} ${active ? 'active' : 'non-active'}`}
                href           = {hrefbim360}
                title          = {fileName + (item.version >0 ? " - v"+item.version.toString() : "")}
                data-toggle    = 'tooltip'
                data-placement = 'right'
                target         = '_blank'
                rel            = 'noopener noreferrer'
                onClick        = {(e) => this.clickedItem(e, item, false)}
                style          = {{transform: depth > 0 ? 'translateX('+(depth+1)*22+'px)': 'translateX(21px)'}}
              >
                {this.nameShortening(fileName + (item.version >0 ? " - v"+item.version.toString() : ""), true)}
              </a>
            </li>
          );
        }
      }
      else {

        return (
          <li
            key           = {i}
            aria-expanded = 'false'
            className     = 'data-not-loading'
          >
            <button
              type           = 'button'
              className      = 'btn icon icon-folder has-no-children'
              title          = {text + (item.version >0 ? " - v"+item.version.toString() : "")}
              data-toggle    = 'tooltip'
              data-placement = 'right'
            >
              {this.nameShortening(text + (item.version >0 ? " - v"+item.version.toString() : ""), true)}
            </button>
          </li>
        );
      }

      return null;
    }
  );

  nameShortening = ( text, C3Project ) => {
    let maxLength    = this.props.settings.maxProjectBrowserNameLength + 30;
    let shortendName = text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    return <span style={{color: !C3Project? 'grey': null}}> {shortendName} </span>;
  };

  updateState = (event, item) => {
    event.stopPropagation()
    if (item.type === "bim360projects") {
      this.handleChange(item)
    }

    if ((item.childArray || item.versions) && item.expanded) {

      item.expanded = !item.expanded;
      this.setState(this.state);
    }
    else if (item.versions) {
      item.childArray = item.versions;
      item.expanded   = !item.expanded;
      this.setState(this.state);
    }
    else {

      item.loading = true;
      this.setState(this.state);

      axios.all([getHubChildStruct(item.id, { withCredentials: true, })])
        .then(axios.spread(items => {
          
          item.loading = false;

          if (items.data.length > 0) {

            item.childArray = items.data;
            item.expanded   = !item.expanded;
            this.setState(this.state);
          }
          else {

            alert('No data in ' + item.text);
            item.loading = false;
            this.setState({});
          }
        }));
    }
  };

  clickedItem = (event, item, noLink) => {
    event.stopPropagation()
    this.props.handleClickOutside('hide')

    if (item.text === 'demo') {
      this.props.selectModel(item)
    } else {
      if (noLink) {
        store.dispatch(properties({ id: item.id, data: [], }));
      }   
      this.props.selectModel(item);
      var projectName = this.props.selected.text;
      var modelName = item.fileName;
    }
  };

  componentDidMount() {

    this.props.redirectCheck();

    if (window.screen.width >= this.props.settings.mobileWidth) {
      this.setState({ isMobile: true, maxViewers: 1, });
    }
    else if (window.screen.width <= this.props.settings.tabletWidth) {
      this.setState({ isTablet: true });
    }
    else if (this.props.settings.maxViewers !== undefined) {
      this.setState({ maxViewers: this.props.settings.maxViewers, });
    }
  };

  handleChange = selectedOption => {
    store.dispatch(properties(null));
    store.dispatch(getSelectedModelArray([]));

    this.setState(
      { selectedOption },
      () => {
        this.props.onrequestSelectedProjectDataReducer(this.state.selectedOption.id, { withCredentials: true, });
        this.props.getSelectedProject(this.state.selectedOption);
      });
  }

  render() {
    return (
      <nav id = 'browser'>
        <ul>
          {this.renderMenuItems(this.props.showValues === false ? this.state.models : this.props.projects, null, -4)}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.requestProjectsDataReducer.data,
  data:          state.requestSelectedProjectDataReducer.data,
  selected:      state.getSelectedProjectReducer.data,
  selectedModel: state.getSelectedModelReducer.data,
  whiteList:     state.requestFileWhiteListReducer.data,
  blackList:     state.requestFolderBlackListReducer.data,
  settings:      state.requestGeneralSettingsReducer.data,
  user :         state.requestUserDataReducer.data});

const mapDispatchToProps = (dispatch) => {
  return {
    selectedModel: (selected) => {
      dispatch(getSelectedModel(selected));
    },
    getSelectedProject: (selected) => {
      dispatch(getSelectedProject(selected))
    },
    onrequestSelectedProjectDataReducer: (id, config) => dispatch(requestData(
      `${API_URL}?id=${id}`,
      {
        pending: FETCH_SELECTED_PROJECT_DATA_PENDING,
        success: FETCH_SELECTED_PROJECT_DATA_SUCCESS,
        error: FETCH_SELECTED_PROJECT_DATA_ERROR,
      },
      config,
    )),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBrowser);