import React, { useState, useEffect, Fragment } from 'react';
import Tree from 'rc-tree';
import "rc-tree/assets/index.css";
import ModelFilter from '../modelFiIlter/ModelFilter';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import graphcolors from '../../config/graphColors.json';
/* global Autodesk, THREE */ // eslint-disable-line

const ModelTree = (props) => {
    const { t } = useTranslation();
    const { activeLab, viewer, toolExtension, filterValue, modelData, selectedMap, updateSelectedMap, showValues, C3Q } = props;
    const [idKey, setIdKey] = useState({})
    const [selected, setSelected] = useState(new Set());
    const [tree, setTree] = useState([]);
    const [renderTree, setRenderTree] = useState(null);

    const [singleNode, setSingleNode] = useState('');
    const [checked, setChecked] = useState([]);
    const [array, setArray] = useState(JSON.parse(JSON.stringify(activeLab.properties)));
    const [filterActive, setFilterActive] = useState(false);
    const [treeActive, setTreeActive] = useState(true);
    const [treeExtend, setTreeExtend] = useState(false);
    const [filter, setFilter] = useState(0);
    const [filterText] = useState({
        0: "all",
        1: "calculatedComponents",
        2: "uncalculatedComponents",
        3: "changedComponents",
        4: "unchangedComponents",
        5: "nonCalculableComponents",
        6: "calculableComponents"
    });

    useEffect(() => {
        if (activeLab.properties.length === array.length) setArray(JSON.parse(JSON.stringify(activeLab.properties)));
        props.updateParent();
    }, [JSON.stringify(activeLab.properties)]);

    useEffect(() => {
        buildTree();
    }, [array, t, C3Q]);

    useEffect(() => {
        setRenderTree(modelTree());
    }, [tree, selected, checked, C3Q]);

    useEffect(() => {
        if (toolExtension && showValues !== false) toolExtension.updateProps(props);
    }, [toolExtension]);

    // useEffect(() => {
    //     viewer.addEventListener(
    //         Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
    //         selectionInModel
    //     );
    // }, [viewer]);

    useEffect(() => {
        const model = viewer.getAllModels()[0];
        let fragCount = 0;

        const instanceTree = model.getInstanceTree();
        if (instanceTree) {
            instanceTree.enumNodeChildren(instanceTree.getRootId(), (nodeId) => {
                instanceTree.enumNodeFragments(nodeId, (fragId) => {
                    if (model.isFragVisible(fragId)) {
                        fragCount++;
                    }
                }, true);
            }, true);
        }
        if (fragCount === 0 && checked.length !== 0 && !C3Q) {
            setChecked([])
        }

    }, [checked]);


    useEffect(() => {
        const newIDs = new Set()

        selectedMap.forEach((item) => {
            newIDs.add(item)
            if (item && idKey[item.toString()]) {
                idKey[item.toString()].category.forEach((category)=> newIDs.add(category))
                idKey[item.toString()].part.forEach((part)=> newIDs.add(part))
                idKey[item.toString()].element.forEach((element)=> newIDs.add(element))
            }
        })

        if (!areSetsEqual(newIDs,selected)) {
            setSelected(newIDs);
        }
    }, [selectedMap]);

    function areSetsEqual(set1, set2) {
        if (set1.size !== set2.size) {
            return false;
        }
      
        for (const item of set1) {
            if (!set2.has(item)) {
                return false;
            }
        }
      
        return true;
    }

    const buildTree = () => {
        const { t, i18n } = props;

        const elementNameMap = new Map();
        const treePartNameMap = new Map();
        const treeCategoryMap = new Map();

        var idkeys = {}

        array.forEach((item) => {
            var title = item.elementName || item.revitType;
            item.treeCategory = Object.keys(graphcolors).includes(item.family) ? item.family : item.category || item.designPartCategoryName;
            item.treePartName = item.designPartName || "Not set";

            if (!item.calculateElement && item.ExistInDatabase) {
                item.treePartName = "Not calculated";
                title += " ";
            }

            if (item.designPartName === "Windows") {
                item.treePartName = item.elementName;
                title = item.revitType;
            }

            item.title = title;
            item.key = item.instanceID.toString();
            item.checkable = item.C3type === "model" || C3Q ? true : false;
            item.isLeaf = true
            item.className = item.C3type === "model" || C3Q ? "C3-node-model" : "C3-node";

            if (idkeys[item.instanceID]) {
                idkeys[item.instanceID].category.push(item.treeCategory)
                idkeys[item.instanceID].part.push(item.treePartName + "_" + item.treeCategory)
                idkeys[item.instanceID].element.push(title)

            } else {
                idkeys[item.instanceID] = { 
                    category: [item.treeCategory],
                    part: [item.treePartName + "_" + item.treeCategory],
                    element: [title]
                }
            }

            const elements = elementNameMap.get(title) || [];
            elements.push(item);
            elementNameMap.set(title, elements);
        });

        setIdKey(idkeys)

        for (const [elementName, elements] of elementNameMap.entries()) {
            var treePartName = elements[0].designPartCategoryName === "Windows" ?
                elements[0].treePartName :
                t("recipes.designPartName." + elements[0].treePartName);

            const parts = treePartNameMap.get(treePartName) || [];
            parts.push({
                key: elementName,
                title: elementName + " (" + elements.length.toString() + ")",
                children: elements,
                category: elements[0].treeCategory,
                quantity: elements.length,
            });
            treePartNameMap.set(treePartName, parts);
        }

        for (const [treePartName, parts] of treePartNameMap.entries()) {
            const categories = new Map();

            for (const part of parts) {
                var treeCategory = part.children[0].treeCategory;
                const categoryParts = categories.get(treeCategory) || [];
                categoryParts.push(part);
                categories.set(treeCategory, categoryParts);
            }

            for (const [treeCategory, categoryParts] of categories.entries()) {
                const categories = treeCategoryMap.get(treeCategory) || [];
                const uniqQuantity = categoryParts.map(obj => obj.quantity).reduce((a, b) => a + b, 0);
                
                categories.push({
                    key: categoryParts[0].children[0].treePartName + "_" + categoryParts[0].category,
                    title: treePartName + " (" + uniqQuantity.toString() + ")",
                    children: categoryParts,
                    category: categoryParts[0].category,
                    quantity: uniqQuantity,
                });
                treeCategoryMap.set(treeCategory, categories);
            }
        }

        var objs = Array.from(treeCategoryMap);

        objs.sort(function (a, b) {
            var aIndex = Object.keys(graphcolors).indexOf(a[0]);
            var bIndex = Object.keys(graphcolors).indexOf(b[0]);

            if (aIndex === -1) return bIndex === -1 ? 0 : 1;

            if (bIndex === -1) return -1;

            return aIndex - bIndex;
        });

        var groupedObjs = [];
        var other = [];

        objs.forEach(element => {
            if (Object.keys(graphcolors).includes(element[0])) {
                groupedObjs.push(element);
            } else {
                var quantity = element[1].flatMap(obj => obj.quantity).reduce((a, b) => a + b, 0);
                other.push({
                    title: i18n.exists("recipes.designPartCategoryName." + element[0]) ?
                        t("recipes.designPartCategoryName." + element[0]) + " (" + quantity.toString() + ")" :
                        element[0] + "* (" + quantity.toString() + ")",
                    category: element[0],
                    children: element[1],
                    key: element[0].toString(),
                    quantity: element[1].flatMap(obj => obj.quantity).reduce((a, b) => a + b, 0),
                    filter: "0",
                });
            }
        });

        groupedObjs.push(['Other', other]);

        objs = groupedObjs.filter(c => c[1].length !== 0).map((c, index) => ({
            title: i18n.exists("recipes.designPartCategoryName." + c[0]) ?
                t("recipes.designPartCategoryName." + c[0]) + " (" + c[1].flatMap(obj => obj.quantity).reduce((a, b) => a + b, 0).toString() + ")" :
                c[0] + "* (" + c[1].flatMap(obj => obj.quantity).reduce((a, b) => a + b, 0).toString() + ")",
            children: c[1],
            key: c[0].toString(),
            category: c[0],
            qq: c[1].flatMap(obj => obj.quantity).reduce((a, b) => a + b, 0),
            filter: "0",
        }));

        if (objs.length !== 0) {
            if (tree.length === 0 || (JSON.stringify(objs) !== JSON.stringify(tree))) {

                activeLab.name = activeLab.name;
                setTree(objs);
                // viewer.select(viewer.getSelection());
            }
        } else if (tree.length !== 0) {
            setTree([]);
        }
    };

    const handleSelectKey = (e, f) => {
        let selectNodes = new Set();

        function getSelectedNodeKey(obj) {
            var id = parseInt((obj.key === undefined ? obj.instanceID : obj.key).toString());
            id = (isNaN(id) ? (obj.key === undefined ? obj.instanceID : obj.key) : id);
            selectNodes.add(id);
        }

        function selectNodesRecursive(node) {
            if (node.children !== undefined) node.children.forEach(child => selectNodesRecursive(child));
            getSelectedNodeKey(node);
        }

        selectNodesRecursive(f.node);

        if (f.nativeEvent.shiftKey) {
            const diffAB = new Set([...selectNodes].filter(element => !selected.has(element)));
            const diffBA = new Set([...selected].filter(element => !selectNodes.has(element)));
            selectNodes = new Set([...diffAB, ...diffBA]);
        } else {
            if (f.node.key === singleNode) {
                selectNodes = new Set();
                setSingleNode('');
            } else {
                setSingleNode(f.node.key);
            }

        }
        
        updateSelectedMap(selectNodes);
        setSelected(selectNodes);
    };

    const handleCheck = (e, f) => {
        var selection = [];

        function selectNodesRecursive(node) {
            if (node.children !== undefined) node.children.forEach(child => selectNodesRecursive(child));
            selection.push(node.key);
        }

        if (f.checked === true) {
            selection = [...checked];
            selectNodesRecursive(f.node);
        } else {
            selectNodesRecursive(f.node);
            selection = checked.filter(x => !(new Set(selection)).has(x));
        }

        setChecked(selection);
    };

    const filterActivate = (e) => {
        if (e) e.stopPropagation();
        setFilterActive(!filterActive);
    };

    const updateFilterValue = (e) => {
        if (e !== filter) {
            setFilter(e);
            filterValue(e);
        }
    };

    const updateFilter = (filteredData) => {
        setArray(filteredData.map(({title, key, treePartName, treeCategory, ...remainingAttrs}) => remainingAttrs));
    };

    const renderFilter = () => {
        return (
            <div id='model-filter' className={`${filterActive ? 'expanded' : ''}`}>
                <ModelFilter
                    viewer={viewer}
                    activeLab={activeLab}
                    filterValue={(e) => updateFilterValue(e)}
                    update={(e, f) => updateFilter(e)}
                    filterChanged={(e) => filterActivate(e)}
                />
            </div>
        );
    };

    const modelTree = () => {
        const { t } = props;
        const icon = (e) => {
            var col = graphcolors[e.data.category] ? graphcolors[e.data.category] : 'gray';
            return (
                <span className="icon icon-sm icon-nav-toggle"
                    style={{
                        marginBottom: '6px',
                        marginLeft: '3px',
                        paddingLeft: '8px',
                        width: '10px',
                        height: '10px',
                        display: 'inline-block',
                        border: '1px solid gray',
                        verticalAlign: 'middle',
                        borderRadius: '5px',
                        background: `${col}`
                    }}
                />
            );
        };

        const switcherIcon = obj => {
            if (obj.isLeaf) return false;

            return getSvgIcon(
                { cursor: 'pointer' },
                { transform: `rotate(${obj.expanded ? 0 : -90}deg)`, display: 'inline-block' }
            );
        };

        const getSvgIcon = (iStyle = {}, style = {}) => (
            <i style={iStyle}>
                <span className="icon icon-sm icon-arrow" style={{ verticalAlign: '-.125em', ...style }} />
            </i>
        );

        const defaultExpandedKeys = ['0'];

        const motion = {
            motionName: 'node-motion',
            motionAppear: false,
            onAppearStart: () => ({ height: 0 }),
            onAppearActive: node => ({ height: node.scrollHeight }),
            onLeaveStart: node => ({ height: node.offsetHeight }),
            onLeaveActive: () => ({ height: 0 }),
        };

        var newChecked = [];
        const getObjectValues = (obj) => (obj && typeof obj === 'object') ? Object.values(obj).map(getObjectValues).flat() : [obj];
        var values = getObjectValues(tree);
        checked.forEach(id => values.includes(id) ? newChecked.push(id) : null);
        var quantityFilter = document.getElementById('quantityFilter');
        newChecked = newChecked.length !== 0 ? newChecked : Array.from(checked)

        if (viewer) {
            viewer.getAllModels()[0].visibilityManager.setNodeOff(1, true);
            if (quantityFilter && quantityFilter.checked) {
                var nothing;
            } else {
                activeLab.properties.forEach(id => {
                    id.visible = false;
                    // id.filtered = false;
            });
            }
        }
        if (newChecked.length === 0) {
            newChecked = [];
            if (quantityFilter && quantityFilter.checked) {
                array.forEach(id => { if (id.visible) viewer.getAllModels()[0].visibilityManager.setNodeOff(id.instanceID, false); });
                // activeLab.properties.forEach(id => {
                //     id.filtered = false;
                //     if (id.visible) id.filtered = true;
                // });
            } else {
                array.forEach(id => viewer.getAllModels()[0].visibilityManager.setNodeOff(id.instanceID, false));
                activeLab.properties.forEach(id => {
                        id.visible = true;
                        // id.filtered = true;
                });
            }
        } else {
            if (quantityFilter && quantityFilter.checked) {
                array.forEach(id => newChecked.includes(id.instanceID.toString()) && id.visible ? viewer.getAllModels()[0].visibilityManager.setNodeOff(id.instanceID, false) : null);
            } else {
                array.forEach(id => newChecked.includes(id.instanceID.toString()) ? viewer.getAllModels()[0].visibilityManager.setNodeOff(id.instanceID, false) : null);
                activeLab.properties.forEach(id => {
                    if (newChecked.includes(id.instanceID.toString())) {
                        id.visible = true;
                        // id.filtered = true;
                    } else {
                        id.visible = false;
                        // id.filtered = false;
                    }
                });
            }
        }

        return (
            tree.length ?
                <Tree
                    showLine={false}
                    ref={treeRef => treeRef = treeRef}
                    multiple={true}
                    defaultExpandedKeys={defaultExpandedKeys}
                    motion={motion}
                    checkable={true}
                    icon={(e) => icon(e)}
                    checkStrictly={true}
                    onSelect={handleSelectKey}
                    onCheck={handleCheck}
                    switcherIcon={switcherIcon}
                    treeData={tree}
                    selectedKeys={Array.from(selected).map(String)}
                    checkedKeys={checked}
                /> : t("modelTree.noData")
        );
    };

    const treeActivate = (e, activate) => {
        e.stopPropagation();
        if (activate === 'activate') setTreeActive(!treeActive);
        if (activate === 'extend') setTreeExtend(!treeExtend);
    };

    const filterName = filterText[filter];
    var height = ((document.getElementById("viewer-container").offsetHeight - 250) * 0.25) + 150;
    height = window.innerHeight - 425 - height;

    const STYLE = `
        .node-motion {
        transition: all 0.5s;
        overflow: hidden;
        }
        `;

    return (
        <Fragment>
            <div id="model-tree-button" onClick={(e) => { treeActivate(e, 'activate') }} style={{ maxHeight: '53px', zIndex: '9', position: 'relative', cursor: 'pointer', padding: "5px", borderBottom: "0.5px solid #d8d8d8", display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: "flex" }}>
                    <h1 id="model-tree-text" style={{ fontSize: "1.35rem" }}><Trans i18nKey="labArea.modelTree" /></h1>
                    <h1 style={{ fontSize: "17px", paddingLeft: "10px", whiteSpace: 'nowrap' }} onClick={(e) => { filterActivate(e) }}> - <Trans i18nKey={"modelTree." + filterName} /></h1>
                </div>
                <div style={{ display: 'flex' }} >
                    <button
                        type='button'
                        className='btn icon icon-filter'
                        id='FilterButton'
                        onClick={(e) => { filterActivate(e) }}
                        style={{ backgroundPosition: 'center', scale: '0.8' }}
                    >
                    </button>
                    <button
                        type='button'
                        className='btn icon icon-arrow'
                        id='FilterButton'
                        onClick={(e) => { treeActivate(e, 'activate') }}
                        style={{ transform: treeActive ? 'rotate(180deg)' : 'rotate(360deg)', backgroundPosition: 'center' }}
                    >
                    </button>
                </div>
            </div>
            {renderFilter()}
            <div id="model-tree" className={`${treeActive && !treeExtend ? 'expanded' : treeExtend && treeActive ? 'extended' : ''}`}>
                <style dangerouslySetInnerHTML={{ __html: STYLE }} />
                {renderTree}
            </div>
        </Fragment>
    );
};

// const MyComponent = withTranslation()(ModelTree);
export default ModelTree;
