import { GET_SELECTED_MODEL } from '../constants/Constants';

const initalState = { data: '', }

export const getSelectedModelReducer = (state = initalState, action = {}) => {
  
  switch (action.type) {
    case GET_SELECTED_MODEL: return { ...state, data: action.payload, }
    default: return state;
  }
}