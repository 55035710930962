import data from '../../data/localData.json';
import ModelData from '../../components/modelData/ModelData';
import {labVersionHandler} from '../../versionHandlers/LabVersionHandler';

export const GetModelData =(modelname) =>
{
    return new Promise((resolve, reject) => {
        let res = data.filter(x => {
            if (x.model.fileName === modelname) {
                return x;
            }
        });
        let modelData = ModelData.constructFromFile(res[0]);
        let updatedModelData = labVersionHandler(modelData);   
      
        resolve(updatedModelData)
    });
} 


// export const GetModelUrls = () =>
// {   
//         let res = data.map(x=>
//             {
             
//                  x.model.fileName   
                
             
//             }) 

//             console.log(res)
// }


