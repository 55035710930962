import axios from "axios";
import store from "../../helpers/store/Store";
import jsondata from "../../data/jsondatabase.json";

import {
  GETELEMENTSBYVERSIONIDS_URL,
  GETELEMENTSBYDESIGNPARTNAME_URL,
  GETELEMENTSBYDESIGNPARTCATEGORYNAMES_URL,
  REFRESHTACCESSTOKEN_URL,
} from "../../helpers/constants/Constants";

export async function refreshAccessToken() {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: REFRESHTACCESSTOKEN_URL,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    axios(config)
      .then(function (response) {
        resolve(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  });
}
export async function GetElementsByIds(databasIds, packages = ["01"]) {
  function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  if (store.getState().requestUserDataReducer.data.length === 0) {
    var database = jsondata.data.elements.results;
    return database.filter((item) =>
      databasIds.includes(item.elementVersionId)
    );
  } else {
    const batches = chunkArray(databasIds, 50);
    console.log(batches);
    const promiseStack = [];

    const makeRequest = (batch) => {
      const config = {
        method: "post",
        url: GETELEMENTSBYVERSIONIDS_URL,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
        data: {
          versionIds: batch,
          packages: packages,
        },
      };

      return axios(config)
        .then((response) => response.data.data)
        .catch((error) => console.log(error));
    };

    const fetchData = (batches) => {
      for (const batch of batches) {
        promiseStack.push(makeRequest(batch));
      }

      return Promise.all(promiseStack).then((values) => {
        let result = [];
        values.forEach((x) => {
          result = [...new Set([...result, ...x])];
        });
        return result;
      });
    };

    return new Promise((resolve, reject) => {
      fetchData(batches)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  }
}

export async function GetElementsBydesignPartName(designPartName, packages = ["01"]) {
  if (designPartName != null) {
    if (store.getState().requestUserDataReducer.data.length === 0) {
      var database = jsondata.data.elements.results;
      return database.filter((item) =>
        designPartName.includes(item.designPartName)
      );
    } else {
      return new Promise((resolve, reject) => {
        var config = {
          method: "post",
          url: GETELEMENTSBYDESIGNPARTNAME_URL,
          // GETELEMENTSBYDESIGNPARTNAME_URL +
          // "?designPartName=" +
          // designPartName,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            designPartName: designPartName,
            packages: packages,
          },
          withCredentials: true,
        };
        axios(config)
          .then(function (response) {
            let arr = response.data.data;
            let result = arr.filter((e) => {
              return e.isLatestVersion === true;
            });
            resolve(result);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  }
}

export async function GetElementsBydesignPartCategoryName(
  designPartCategoryName,
  packages = ["01"]
) {
  if (designPartCategoryName != null) {
    if (store.getState().requestUserDataReducer.data.length === 0) {
      let Combined = [];
      var database = jsondata.data.elements.results;
      designPartCategoryName.forEach((element) => {
        Combined = Combined.concat(
          database.filter((item) =>
            designPartCategoryName.includes(item.designPartCategoryName)
          )
        );
      });
      return Combined;
    } else {
      return new Promise((resolve, reject) => {
        var config = {
          method: "post",
          url: GETELEMENTSBYDESIGNPARTCATEGORYNAMES_URL,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          data: {
            categoryNames: designPartCategoryName,
            packages: packages,
          },
        };
        axios(config)
          .then(function (response) {
            let arr = response.data.data;
            let result = arr.filter((e) => {
              return e.isLatestVersion === true;
            });
            resolve(result);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  }
}
