import React from 'react';

class Tooltip extends React.Component {
  render() {
    return (
        <span className={`info-tooltip icon-lg ${this.props.icon}`}>
            <span className="tooltiptext">{this.props.text}</span>
        </span>
    )
  }
}

export default Tooltip;