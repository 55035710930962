import React, { useEffect, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';

const RecepieCost = ({ t, selectedInstance, activeLab, showValues, cached, selectedNameOption}) => {
    const [co2RatingBars, setCo2RatingBars] = useState(['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']);
    const [costRatingBars, setCostRatingBars] = useState(['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']);
	let selected = selectedNameOption || selectedInstance;
	let co2Rating = selected && selected.co2Rating ? selected.co2Rating : 0;
	let costRating = selected && selected.costRating ? selected.costRating : 0;
	let array = selected && cached[selected.designPartName] ? cached[selected.designPartName] : null;

	if (selected && array && !selected.costRating) {
		let co2Max = -Infinity;
		let costMax = -Infinity;
		array.forEach((recipe) => {
			co2Max = Math.max(co2Max, Number(recipe.co2));
			costMax = Math.max(costMax, Number(recipe.unitCost));
		});

		array.forEach((recpie) => {
			if (showValues) { 
				recpie.co2Rating = Math.round(10 * (Number(recpie.co2)) / (co2Max));
				recpie.costRating = Math.round(10 * (Number(recpie.unitCost)) / (costMax));
				if (recpie.elementId === selected.elementId) {
					selected.co2Rating = recpie.co2Rating;
					selected.costRating = recpie.costRating;
					co2Rating = recpie.co2Rating;
					costRating = recpie.costRating;
				}
			} else {
				recpie.co2Rating = Math.floor(Math.random()*(10 + 1))
				recpie.costRating = Math.floor(Math.random()*(10 + 1))
				if (recpie.elementId === selected.elementId) {
					selected.co2Rating = recpie.co2Rating;
					selected.costRating = recpie.costRating;
					co2Rating = recpie.co2Rating;
					costRating = recpie.costRating;
				}
			}
		})
	};

	useEffect(() => {
		setCostRatingBars(Array(10).fill("#fff").map((_, i) => (costRating < i + 1 ? "#fff" : "transparent")));
		setCo2RatingBars(Array(10).fill("#fff").map((_, i) => (co2Rating < i + 1 ? "#fff" : "transparent")));
	  }, [selected, co2Rating, costRating]);

	const calcValues = (e, selectedNameOption, selectedInstance) => {
		let np = ' (';
		let cost = -1;
		let value = null;
		let procent = null;

		if (selectedNameOption && selectedInstance && selectedInstance.elementCO2) {
			if (e === 'co2') {
				value = selectedNameOption.co2 - selectedInstance.elementCO2;
				procent = 100 * (value / selectedInstance.elementCO2);
				cost = selectedNameOption.co2;
			} else if (e === 'kr') {
				value = selectedNameOption.unitCost - selectedInstance.elementUnitCost;
				procent = 100 * (value / selectedInstance.elementUnitCost);
				cost = selectedNameOption.unitCost;
			}
		} else if (selectedNameOption) {
			if (e === 'co2') {
				value = 0
				cost = selectedNameOption.co2;
			} else if (e === 'kr') {
				value = 0
				cost = selectedNameOption.unitCost;
			}
		}

		cost = cost < 1 ? (Math.round(cost*100)/100).toString() : Math.round(cost).toString()

		if (value === 0) {
			return cost + ' ' + (e === 'kr' ? t("BuildingMaterialSelector.SEK")+'/': 'kg/')+unit;
		} else if (value === null) {
			return '';
		};

		np = value > 0 ? ' (+' : ' (';

		return cost + np + Math.round(procent).toString() + '%)';
	};

	var unit = "m²";
	if (selected && selected.designPartName && cached[selected.designPartName]) {
		var type = cached[selected.designPartName].find((item)=> item.elementName === selected.elementName).unit
		unit = type === "St" || type === "ST" || type === "st"? t("BuildingMaterialSelector.unit") : unit;
		unit = type === "M" ? "m" : unit;
	}

	return (
		<div id='table kr CO2'>
			<table className={"table table-sm"} style={{ border: '0', marginBottom: '0px' }}>
				<tbody>
					<tr className='rating3' style={{ height: '20px', borderTop: '0', margin: '2px 2px 0px 7px', }}>
						<td style={{ padding: 0, height: '2px', width: '0px' }}>
							<label style={{ paddingTop: 1, paddingLeft: 5, margin: 0, height: '28px', width: '60px', borderLeft: '0.5px solid #d8d8d8' }}>
								<h5>CO2e</h5>
							</label>
						</td>
						{co2RatingBars.map((item, index) => (
							<td key={index + "3"} type="radio" name='rating3' style={{ padding: 0, height: '20px' }}>
								<label style={{ background: item, margin: 0, height: '28px' }}></label>
							</td>
						))}
					</tr>
				</tbody>
			</table>

			{showValues &&
				<table className={"table table-sm"} style={{ borderBottom: '0', marginBottom: '0px' }}>
					<tbody>
						{!activeLab.main &&
							<tr>
								<th style={{ textAlign: 'left', fontFamily: 'SwecoSans-regular', textTransform: 'none', margin: '2px 2px 0px 7px', fontSize: '16px' }}>
									{!selectedNameOption ? t('BuildingMaterialSelector.currentRecipe') : t('BuildingMaterialSelector.previousRecipe')}
								</th>
								<th style={{ textAlign: 'left', fontFamily: 'SwecoSans-regular', textTransform: 'none', margin: '2px 2px 0px 7px', fontSize: '16px' }}>
									{!selectedNameOption ? ' ' : t('BuildingMaterialSelector.selecteRecipe')}
								</th>
							</tr>
						}
						<tr>
							<td>{selectedInstance && Math.floor(selectedInstance.elementCO2).toString() !== calcValues('co2', selectedNameOption, selectedInstance) ? `${Number(selectedInstance.elementCO2) < 1 ? (Math.round(selectedInstance.elementCO2*100)/100).toString() : Math.round(selectedInstance.elementCO2).toString()} kg/${unit}` : '-'}</td>
							<td>{calcValues('co2', selectedNameOption, selectedInstance)}</td>
						</tr>
					</tbody>
				</table>
			}

			<table className={"table table-sm"} style={{ border: '0', marginBottom: '0px' }}>
				<tbody>
					<tr className='rating2' style={{ height: '20px', borderTop: '0', margin: '2px 2px 0px 7px', }}>
						<td style={{ padding: 0, height: '2px', width: '0px' }}>
							<label style={{ paddingTop: 1, paddingLeft: 5, margin: 0, height: '28px', width: '60px', borderLeft: '0.5px solid #d8d8d8'}}>
								<h5><Trans i18nKey="BuildingMaterialSelector.SEKText" /></h5>
							</label>
						</td>
						{costRatingBars.map((item, index) => (
							<td key={index + "4"} type="radio" name='rating2' style={{ padding: 0, height: '20px' }}>
								<label style={{ background: item, margin: 0, height: '28px' }}></label>
							</td>
						))}
					</tr>
				</tbody>
			</table>

			{showValues &&
				<table className={"table table-sm"} style={{ borderBottom: '0', marginBottom: '0px' }}>
					<tbody>
						{!activeLab.main &&
						<tr>
							<th style={{ textAlign: 'left', fontFamily: 'SwecoSans-regular', textTransform: 'none', margin: '2px 2px 0px 7px', fontSize: '16px' }}>
								{!selectedNameOption ? t('BuildingMaterialSelector.currentRecipe') : t('BuildingMaterialSelector.previousRecipe')}
							</th>
							<th style={{ textAlign: 'left', fontFamily: 'SwecoSans-regular', textTransform: 'none', margin: '2px 2px 0px 7px', fontSize: '16px' }}>
								{!selectedNameOption ? ' ' : t('BuildingMaterialSelector.selecteRecipe')}
							</th>
						</tr>
						}
						<tr style={{ 'border': '0' }}>
							<td>{selectedInstance && Math.floor(selectedInstance.elementUnitCost).toString() !== calcValues('kr', selectedNameOption, selectedInstance) ? `${Number(selectedInstance.elementUnitCost) < 1 ? (Math.round(selectedInstance.elementUnitCost*100)/100).toString() : Math.round(selectedInstance.elementUnitCost).toString()} ${t("BuildingMaterialSelector.SEK")}/${unit}` : '-'}</td>
							<td>{calcValues('kr', selectedNameOption, selectedInstance)}</td>
						</tr>
					</tbody>
				</table>
			}
			{showValues ? null : <h5 style={{margin: '0px', paddingTop: '5px'}}>{t('BuildingMaterialSelector.demoGraph')}</h5>}
		</div>
	);
};

const MyComponent = withTranslation()(RecepieCost);
export default MyComponent;