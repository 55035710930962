import parsingSetting from "../../config/parsingSettings.json";
import { GetElementsByIds } from "./DataConnector";
import ModelData from "../modelData/ModelData";

/* global Autodesk, THREE,  userFunction */ // eslint-disable-line

function extractWall(elements, obj) {
  elements.props.forEach((prop) => {
    if (prop.displayName === "NetSideArea") {
      obj.dimensionprop = "m2";
      if (prop.units === "mm^2") {
        let area = prop.displayValue * 0.000001;
        obj.calculatedArea = area;
      }
      if (prop.units === "m^2") {
        obj.calculatedArea = prop.displayValue;
      } else if (prop.units === "ft^2") {
        let area = prop.displayValue * 92903.12990644656 * 0.000001;
        obj.calculatedArea = area;
      }
    }
  });
}
function extractWindow(elements, obj) {
  elements.props.forEach((prop) => {
    if (
      prop.displayName == "Area" &&
      prop.displayCategory == "BaseQuantities"
    ) {
      obj.dimensionprop = "m2";
      if (prop.units === "mm^2") {
        let area = prop.displayValue * 0.000001;
        obj.calculatedArea = area;
      }
      if (prop.units === "m^2") {
        obj.calculatedArea = prop.displayValue;
      } else if (prop.units === "ft^2") {
        let area = prop.displayValue * 92903.12990644656 * 0.000001;
        obj.calculatedArea = area;
      }
    }
  });
}
function extractStair(elements, obj) {
  obj.calculatedArea = 1;
  obj.dimensionprop = "st";
  elements.props.forEach((prop) => {
    if (prop.displayCategory === "Item") {
      if (prop.displayName === "Name") {
        obj.family = prop.displayValue;
      }
    }
  });
}
function extractRailing(elements, obj) {
  elements.props.forEach((prop) => {
    if (
      prop.displayName === "Length" &&
      prop.displayCategory === "BaseQuantities"
    ) {
      obj.dimensionprop = "m";
      if (prop.units === "m") {
        let length = prop.displayValue;
        obj.calculatedArea = length;
      }
      if (prop.units === "mm") {
        let length = prop.displayValue * 0.001;
        obj.calculatedArea = length;
      } else if (prop.units === "ft") {
      }
    }
  });
}
function extractBeam(element, obj) {
  obj.dimensionprop = "m";
  element.props.forEach((prop) => {
    if (
      prop.displayCategory === "Dimensions" &&
      prop.displayName === "Length"
    ) {
      if (prop.units === "m") {
        let area = prop.displayValue;
        obj.calculatedArea = area;
      }
      if (prop.units === "mm") {
        obj.calculatedArea = prop.displayValue * 0.001;
      }
    }
  });
}

function extractColumn(element, obj) {
  obj.calculatedArea = 1;
}
function extractRoof(elements, obj) {
  elements.props.forEach((prop) => {
    if (
      prop.displayCategory === "BaseQuantities" &&
      prop.displayName === "TotalSurfaceArea"
    ) {
      obj.dimensionprop = "m2";
      if (prop.units === "mm^2") {
        let area = prop.displayValue * 0.000001;
        obj.calculatedArea = area;
      }
      if (prop.units === "m^2") {
        obj.calculatedArea = prop.displayValue;
      } else if (prop.units === "ft^2") {
        let area = prop.displayValue * 92903.12990644656 * 0.000001;
        obj.calculatedArea = area;
      }
    }
  });
}
function extractCeiling(elements, obj) {
  elements.props.forEach((prop) => {
    if (
      prop.displayCategory === "BaseQuantities" &&
      prop.displayName === "GrossCeilingArea"
    ) {
      obj.dimensionprop = "m2";
      if (prop.units === "mm^2") {
        let area = prop.displayValue * 0.000001;
        obj.calculatedArea = area;
      }
      if (prop.units === "m^2") {
        obj.calculatedArea = prop.displayValue;
      } else if (prop.units === "ft^2") {
        let area = prop.displayValue * 92903.12990644656 * 0.000001;
        obj.calculatedArea = area;
      }
    }
  });
}
function extractDoor(elements, obj) {
  elements.props.forEach((prop) => {
    if (
      prop.displayName == "Area" &&
      prop.displayCategory == "BaseQuantities"
    ) {
      obj.dimensionprop = "m2";
      if (prop.units === "mm^2") {
        let area = prop.displayValue * 0.000001;
        obj.calculatedArea = area;
      }
      if (prop.units === "m^2") {
        obj.calculatedArea = prop.displayValue;
      } else if (prop.units === "ft^2") {
        let area = prop.displayValue * 92903.12990644656 * 0.000001;
        obj.calculatedArea = area;
      }
    }
  });
}
function extractSlab(elements, obj) {
  elements.props.forEach((prop) => {
    if (prop.displayCategory === "IFC Type") {
      if (prop.displayName === "IfcExportPredefinedtypeType") {
        if (prop.displayValue === "ROOF") {
          obj.category = "Roofs";
          obj.revitCategory = "Roofs";
        }
        if (prop.displayValue === "LANDING") {
          obj.category = "IfcLanding";
        } else if (prop.displayValue === "FLOOR") {
          obj.category = "Floors";
        }
      }
    }

    if (prop.displayName === "sweco_cc_Id") {
      obj.databaseID = prop.displayValue;
    } else if (
      prop.displayName === "NetArea" &&
      prop.displayCategory == "BaseQuantities"
    ) {
      obj.dimensionprop = "m2";
      if (prop.units === "mm^2") {
        let area = prop.displayValue * 0.000001;
        obj.calculatedArea = area;
      }
      if (prop.units === "m^2") {
        obj.calculatedArea = prop.displayValue;
      } else if (prop.units === "ft^2") {
        let area = prop.displayValue * 92903.12990644656 * 0.000001;
        obj.calculatedArea = area;
      }
    }
  });
}

export async function GetIFCModelThree(viewer) {
  async function getPropeties(filter) {
    let res = await viewer.model
      .getPropertyDb()
      .executeUserFunction(userFunction, filter)
      .then(function (retValue) {
        return retValue;
      })
      .catch(function (err) {
        console.log("Something didn't go right...", err);
      });
    return res;
  }

  function CreateElement(data) {
    let obj = {
      C3type: "model",
      category: null,
      revitCategory: null,
      categoryID: null,
      family: null,
      familyID: null,
      revitType: null,
      revitTypeId: null,
      instanceID: data.dbId,
      IfcExportPredefinedtype: null,
      dimensionprop: null,
      calculatedArea: 0,
      CO2: 0,
      Cost: 0,
      DatabaseType: null,
      ExistInDatabase: false,
      Updated: false,
      databaseID: null,
      designPartName: null,
      designPartCategoryName: null,
      elementId: null,
      elementVersionId: null,
      elementName: null,
      elementCO2: 0,
      elementUnitCost: 0,
      calculateElement: true,
      unit: null
    };

    const c3CategoryIFClookup = {
      IfcDoor: "Doors",
      IfcWall: "Walls",
      IfcWindow: "Windows",
      IfcWallStandardCase: "Walls",
      IfcSlab: "Floors",
      IfcRoof: "Roofs",
      IfcColumn: "Structural Columns",
      IfcStair: "Stairs",
      IfcRailing: "Railings",
      IfcCovering: "Ceilings",
      IfcBeam: "Structural Framing",
    };

    data.props.forEach((prop) => {
      if (prop.displayName === "sweco_cc_Id") {
        obj.databaseID = prop.displayValue;
      }

      if (prop.displayCategory === "Item") {
        if (prop.displayName === "Type") {
          obj.family = prop.displayValue;
          obj.revitType = prop.displayValue;
        }
      }

      if (prop.displayCategory === "Element") {
        if (prop.displayName === "IfcExportPredefinedtype") {
          obj.IfcExportPredefinedtype = prop.displayValue;
        }
      }

      if (prop.displayCategory === "Element ID") {
        if (prop.displayName === "Name") {
          obj.revitTypeId = prop.displayValue;
        }
      }
      if (prop.displayCategory === "Element") {
        if (prop.displayName === "IfcClass") {
          if (c3CategoryIFClookup.hasOwnProperty(prop.displayValue)) {
            let objCategory = c3CategoryIFClookup[prop.displayValue];

            let C3Cat = parsingSetting.lookupTable.filter((x) => {
              if (x.category === objCategory) return x.c3Category;
            });

            let cat;
            if (C3Cat.length === 0) {
              cat = objCategory;
            } else {
              cat = C3Cat[0].c3Category;
            }
            obj.category = cat;
            obj.revitCategory = objCategory;
          } else {
            obj.category = prop.displayValue;
          }
        }
      }
      if (prop.displayCategory === "BaseQuantities") {
      }
    });

    let value = obj.revitCategory;

    switch (value) {
      case "Walls":
        extractWall(data, obj);
        break;
      case "Windows":
        extractWindow(data, obj);
        break;
      case "Doors":
        extractDoor(data, obj);
        break;
      case "Floors":
        extractSlab(data, obj);
        break;
      case "Stairs":
        extractStair(data, obj);
        break;
      case "Railings":
        extractRailing(data, obj);
        break;
      case "Structural Framing":
        extractBeam(data, obj);
        break;
      case "Structural Columns":
        extractColumn(data, obj);
        break;
      case "Roofs":
        extractRoof(data, obj);
        break;
      case "Ceilings":
        extractCeiling(data, obj);
        break;
    }

    return obj;
  }

  async function go() {
    let categoriesNames = [];
    const filter = {
      name: "Type",
      categories: ["Category"],
    };
    let categories = await getPropeties(filter);

    categories.forEach((properties) => {
      properties.props.forEach((prop) => {
        if (prop.displayCategory === "Item") {
          if (prop.displayName === "Name") {
            categoriesNames.push(prop.displayValue);
          }
        }
      });
    });

    var a = ["IfcSlab", "IfcColumn", "IfcCovering"];
    categoriesNames.push(...a);

    const distinctArray = [...new Set(categoriesNames)];
    console.log(distinctArray);

    const filter1 = {
      name: "IfcClass",
      categories: distinctArray,
    };
    let elements = await getPropeties(filter1);
    let objects = [];

    elements.forEach((properties) => {
      let res = CreateElement(properties);
      objects.push(res);
    });

    let BTA = 0;
    const modelData = new ModelData(objects, BTA);
    return modelData;
  }
  return await go();
}
