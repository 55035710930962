import {
  FETCH_TOKEN_DATA_PENDING,
  FETCH_TOKEN_DATA_SUCCESS,
  FETCH_TOKEN_DATA_ERROR
} from '../constants/Constants';

const initialState = {
  pending: false,
  data:    [],
  error:   ''
}

export const requestModelDataReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case FETCH_TOKEN_DATA_PENDING: return { ...state, pending: true, };
    case FETCH_TOKEN_DATA_SUCCESS: return { ...state, pending: false, data: action.payload, };
    case FETCH_TOKEN_DATA_ERROR:   return { ...state, pending: false, data: action.payload, };
    default: return state;
  }
}
