import {
  createStore,
  combineReducers,
  applyMiddleware
} from 'redux';
import thunkMiddleware from 'redux-thunk';

import { getModelArrayReducer }              from '../reducers/getModelArrayReducer';
import { getSelectedModelReducer }           from '../reducers/getSelectedModelReducer';
import { getSelectedProjectReducer }         from '../reducers/getSelectedProjectReducer';
import { requestGeneralSettingsReducer }     from '../reducers/requestGeneralSettingsReducer';
import { requestFileWhiteListReducer }       from '../reducers/requestFileWhiteListReducer';
import { requestFilterCategoryReducer }      from '../reducers/requestFilterCategoryReducer';
import { requestFolderBlackListReducer }     from '../reducers/requestFolderBlackListReducer';
import { requestModelDataReducer }           from '../reducers/requestModelDataReducer';
import { requestProjectsDataReducer }        from '../reducers/requestProjectsDataReducer';
import { requestSelectedProjectDataReducer } from '../reducers/requestSelectedProjectDataReducer';
import { requestUserDataReducer }            from '../reducers/requestUserDataReducer';

const reducers = combineReducers({
  getModelArrayReducer,
  getSelectedModelReducer,
  getSelectedProjectReducer,
  requestGeneralSettingsReducer,
  requestFileWhiteListReducer,
  requestFilterCategoryReducer,
  requestFolderBlackListReducer,
  requestModelDataReducer,
  requestProjectsDataReducer,
  requestSelectedProjectDataReducer,
  requestUserDataReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return reducers(state, action);
}

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
