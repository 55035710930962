import React, { Component } from 'react';
import { BarChart, Cell, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { nrFormatter } from '../../helpers/helpers';
import graphcolors  from '../../config/graphColors.json';
import { Trans, withTranslation } from 'react-i18next';

class ResultCharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryToOpen: null,
            data: null,
            GroupedByCategory: null,
            GroupedByDesignPartName: null,
            ShowGoBackButton: false,
            isInDesignPartNameMode: true,
            toggleChart: true,
            viewProps: {
                baseline: "baselineCO2",
                valueToView: "CO2",
                name: "kg CO2e",
                unit: "kg CO2e"
            },
            categories: [],
            subCategories: [],
            sub: false
        }
    };

    reloadChart = () => {
        this.setState({
            data: this.state.GroupedByCategory,
            isInDesignPartNameMode: true,
            categoryToOpen: null,
            sub: false,
        });
    }

    updateChart = (id) => {
        if (this.state.sub) {
            if (this.state.subCategories.includes(id)) {
                const index = this.state.subCategories.indexOf(id);
                this.state.subCategories.splice(index, 1);
            } else {
                this.state.subCategories.push(id)
            }
            this.setState({
                subCategories: this.state.subCategories
            });
        } else {
            if (this.state.categories.includes(id)) {
                const index = this.state.categories.indexOf(id);
                this.state.categories.splice(index, 1);
            } else {
                this.state.categories.push(id)
            }
            this.setState({
                categories: this.state.categories
            });
        }
    }

    toggleCOST_CO2_view = () => {
        if (!this.state.toggleChart) {
            this.setState({
                viewProps: {
                    baseline: 'baselineCO2',
                    valueToView: 'CO2',
                    name: 'Kg CO2e',
                    unit: 'Kg CO2e'
                }
            })
        } else {
            this.setState({
                viewProps: {
                    baseline: "baselineCost",
                    valueToView: "cost",
                    name: <Trans i18nKey="resultCharts.SEKs"/>,
                    unit: 'kr'
                }
            })
        }
        this.setState({
            toggleChart: !this.state.toggleChart
        })
    }

    selectDataSet = (props) => {
        if (!this.state.isInDesignPartNameMode) {
            return props.groupedByDesignPartName.filter(x => {
                if (x.category === this.state.categoryToOpen) return x
            })
        }
        return props.groupedByCategory
    }

    handleBarClick = (e) => {
        if (e && e.activePayload) { 
            var subCategories = []
            if (this.state.isInDesignPartNameMode) {
                this.props.data.groupedByDesignPartName.map(x => {
                    if (x.category === e.activePayload[0].payload.category) subCategories.push(x.name)
                })
            }
            this.setState({
                categoryToOpen: e.activePayload[0].payload.category,
                isInDesignPartNameMode: !this.state.isInDesignPartNameMode,
                sub: !this.state.sub,
                subCategories: subCategories
            })
        }
    }
    
    componentDidMount = () => {
        const chartData = this.selectDataSet(this.props.data);
        this.state.categories = chartData.map(a => a.name).slice(0, 6);
    }

    // TO DO: Add method for calculating height and width

    render() {

        const { t } = this.props;
        const chartData = this.selectDataSet(this.props.data);
        chartData.forEach((ele, index) => {
            ele.displayName =  t(this.state.isInDesignPartNameMode ? 'recipes.designPartCategoryName.'+ ele.name : 'recipes.designPartName.'+ ele.name);
            ele.saturate = 'saturate(' + ((chartData.length-Number(index))/chartData.length*100).toString() + '%)'
            ele.brightness = 'brightness(' + (1+((Number(index))/(chartData.length*3))).toString() + ')'
        })

        const stremlinedData = chartData.filter(a => this.state.sub ? this.state.subCategories.includes(a.name) : this.state.categories.includes(a.name));

        let height = ((document.getElementById("viewer-container").offsetHeight - 118) * 0.25) - 16;
        const activeLab = this.props.activeLab.name;
        const activeLabMain = this.props.activeLab.main;

        const customTooltip = ({ active, payload, label }) => {
            if (active && payload) {
                return (
                    <div className="custom-tooltip">
                        <p><strong>{label}</strong></p>
                        <table className="table table-sm table-borderless">
                            <tbody>
                                
                                <tr><th>Original</th><td>{nrFormatter(Math.round(payload[0].value))} {this.state.viewProps.name}</td></tr>
                                {
                                    activeLabMain !== true ? <tr><th>{activeLab}</th><td>{nrFormatter(Math.round(payload[1].value))} {this.state.viewProps.name}</td></tr> : null
                                }
                            </tbody>
                        </table>
                    </div>
                );
            }
            return null
        }

        return (
            <>
                {chartData ?
                    <>
                        <div className='legend-container' >
                            <div className='legend dark-gray' /><p><Trans i18nKey="resultCharts.originalValues"/></p>
                        {/* </div>
                        <div id="chart-buttons"> */}
                            <button style={{marginLeft: '10px'}} onClick={this.reloadChart} className={"btn btn-xs btn-primary-green"} disabled={this.state.categoryToOpen ? false : true}><Trans i18nKey="resultCharts.back"/></button>
                            <button style={{marginLeft: '10px'}} onClick={this.toggleCOST_CO2_view} className={"btn btn-xs btn-primary-green"}> <Trans i18nKey="resultCharts.show"/> {this.state.viewProps.valueToView !== 'cost' ? <Trans i18nKey="resultCharts.SEK"/> : ' CO2e'}</button>
                        </div>
                        <div className="chart-info-container">
                            {
                                this.state.viewProps.valueToView !== 'cost' ? <p className='y-axis-label'><span className="icon-sm icon-cloud" />kg CO2e</p> : <p className='y-axis-label'><span className="icon-sm icon-coins" /><Trans i18nKey="resultCharts.SEKc"/></p>
                            }
                            <p><Trans i18nKey="resultCharts.clickInfo"/></p>

                        </div>
                        <BarChart width={368} height={height*0.95} data={stremlinedData}   onClick={this.handleBarClick}
                            margin={{ top: 5, right: 5, left: 0, bottom: 5 }}>
                            <XAxis dataKey="displayName" xAxisId={0} hide />
                            <XAxis dataKey="displayName" xAxisId={1} hide />
                            <YAxis tickFormatter={nrFormatter} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip content={customTooltip} />
                            <Bar dataKey={this.state.viewProps.baseline} barSize={40 * (5/stremlinedData.length)} name="baseline" xAxisId={0} fill="#3f3f42" />
                            <Bar dataKey={this.state.viewProps.valueToView} name={this.state.viewProps.name}  barSize={20 * (5/stremlinedData.length)} xAxisId={1}   >
                                {
                                    stremlinedData.map((entry, index) => (
                                        <Cell key={`cellBar-${index}`} fill={graphcolors[entry.category] } filter={this.state.sub?entry.saturate + '' + entry.brightness: 'saturate(100%)'}/>
                                    ))}
                            </Bar>
                        </BarChart>
                        <div className='legend-container' style={{maxWidth: '360px', maxHeight: '70px', paddingLeft: '50px', overflow: 'auto'}}>
                        {
                                    chartData.map((entry, index) => (
                                        <button key={'resultButton__'+index.toString()}style={{margin: '5px', background: (this.state.sub ? this.state.subCategories.includes(entry.name) : this.state.categories.includes(entry.name)) ? graphcolors[entry.category] : '#3F3F42', color: (this.state.sub ? this.state.subCategories.includes(entry.name) : this.state.categories.includes(entry.name)) ? 'black' : 'white', filter:this.state.sub?entry.saturate + '' + entry.brightness: 'saturate(100%)'}} onClick={ (e) => this.updateChart(entry.name)} className={"btn btn-xs btn-primary-green"} >{entry.displayName}</button>
                                        //<Cell key={`cell-${index}`} fill={graphcolors[entry.category] }/>
                                    ))}
                        </div>
                    </> : null}
            </>
        );
    }
}

//export default ResultCharts;

const MyComponent = withTranslation()(ResultCharts)
export default MyComponent;