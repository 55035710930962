import React, { Component, Fragment } from 'react';
import "rc-tree/assets/index.css"
import ModelPropertiesC3 from '../ModellTree/ModelC3Properties';
import { Trans, withTranslation } from 'react-i18next';
/* global Autodesk, THREE */ // eslint-disable-line

class InfoTool extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.viewer.addEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.update,);
    }

    componentWillUnmount() {
        this.props.viewer.removeEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.update,);
	}

    update = () => {
        var button = document.getElementById('info-tool-button')
        if (button && button.classList.contains('inactive')) {
            this.componentWillUnmount()
        }
        this.setState({})
    }

    setElementToNotCalculate = (value) => {
        var properties = this.props.modelData.getActiveLabProperties()
        var updated = false

        properties.forEach(prop => {
            if (this.props.viewer.getSelection().includes(prop.instanceID) && prop.ExistInDatabase){//} && prop.calculateElement !== value) {
                prop.calculateElement = !value.target.checked;
                updated = true
            }
        });
    
        if (updated === true ) {
            this.props.modelData.updateActivLab(properties);
        }

        this.props.options.updateLabState()
    }

    setElementToNotCalculateStatus = () => {
        var properties = this.props.modelData.getActiveLabProperties()
        var status = false

        properties.forEach(prop => {
            if (this.props.viewer.getSelection().includes(prop.instanceID) && prop.calculateElement === false){
                status = true
            }
        });
        return status
    }

    elementToNotCalculate = () => {
        var status = this.setElementToNotCalculateStatus()
        return (
            <div style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px -3px 5px 0px', borderTop: '1px solid rgba(0,0,0,.29)', height: '40px', background: 'white', marginTop: '15px', bottom: '0px', position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {this.props.modelData.getActiveLab().main ? null : 
                    <>
                        <h3 style={{marginBottom: '5px', paddingRight: '10px'}}> <Trans i18nKey="modelTree.notCalculateComponents"/></h3 >
                        <div className={"custom-control custom-radio"}>
                            <input
                                type="checkbox"
                                id="calulateElemenet"
                                value="0"
                                className={"custom-control-input"}
                                checked={status}
                                onChange={(e) => {this.setElementToNotCalculate(e)}}
                                disabled={this.props.modelData.getActiveLab().main || this.props.viewer.getSelection().length === 0}
                            />
                            <label htmlFor="calulateElemenet" className={"custom-control-label"}></label>
                        </div>
                    </>
                }
            </div>
        )
    }

    renderModelPropertiesC3 = () => {
        const { showCompareLabs, selected } = this.state
        return (
            <ModelPropertiesC3
                activeLab={this.props.modelData.getActiveLab()}
                changeArea={this.state.displayAreaProperties}
                viewer={this.props.viewer}
                modelData={this.props.modelData}
                selected={selected}
                setModelData={(modelData) => this.props.setModelData(modelData)}
                updateLabState={() => this.props.updateLabState()}
                visible={!showCompareLabs}
                showValues={this.props.showValues}
            />
        );
    }

    render() {
        var modelPropertiesC3 = this.renderModelPropertiesC3();
        var selected = this.props.viewer.getSelection()
        return (
            <Fragment>    
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', borderBottom: '1px solid rgba(0,0,0,.29)', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 5px 0px', height: '50px', position: 'absolute', width: '100%', zIndex: '10', background: 'white'}}>
                    <h1><Trans i18nKey="modelTree.values"/></h1>
                    <div className="docking-panel-close" onClick={(e) => document.getElementById('info-tool-button') ? document.getElementById('info-tool-button').click() : null}></div>
                </div>
                <div id="selectedPropertiesC3" style={{ overflow: 'auto', marginBottom: '20px', padding: '10px', top: '50px', position: 'relative', paddingBottom: '45px'}}>
                    {selected.length === 1 ? modelPropertiesC3 : <p><Trans i18nKey="modelTree.selectionNotValid"/></p>}
                </div>
                {this.elementToNotCalculate()}
            </Fragment>
        )
    }
}

const MyComponent = withTranslation()(InfoTool)
export default MyComponent;