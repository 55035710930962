import { 
  FETCH_GENERAL_SETTINGS_SUCCESS,
  FETCH_GENERAL_SETTINGS_PENDING,
  FETCH_GENERAL_SETTINGS_ERROR,
} from '../constants/Constants';

const initialState = {
  pending: false,
  data:    [],
  error:   '',
}

export const requestGeneralSettingsReducer = (state = initialState, action) => {
  
  switch (action.type) {
      case FETCH_GENERAL_SETTINGS_PENDING: return { ...state, pending: true, };
      case FETCH_GENERAL_SETTINGS_SUCCESS: return { ...state, pending: false, data: action.payload, };
      case FETCH_GENERAL_SETTINGS_ERROR:   return { ...state, pending: false, error: action.payload, };
      default: return state;
  }
}
