import React from "react";
import { connect } from "react-redux";
import { getForgeToken } from "../../helpers/axios/Get";
import { BIM360_LOCATION } from "../../helpers/constants/Constants";
import { withTranslation } from "react-i18next";
/* global Autodesk, THREE */ // eslint-disable-line

class Viewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewerApp: null,
      _viewer: null,
      viewState: null,
      listener: false,
    };
  }

  launchViewer = (urn, view) => {
    this.props.redirectCheck();

    if (this.state.viewerApp != null) {
      this.state.viewerApp.tearDown();
      this.state.viewerApp.finish();
      this.setState({ viewerApp: null });
    }

    let options = {
      document: urn,
      env: "AutodeskProduction2",
      api: BIM360_LOCATION,
      //env: "AutodeskProduction",
      //api: "derivativeV2_EU",
      getAccessToken: getForgeToken,
      refreshToken: getForgeToken,
      PushPinExtensionHandle: null,
    };
    const customProfileSettings = {
      settings: {
        selectionMode: 0, //enum value, 0 is default 'First Object' is 1
      },
    };
    const customProfile = new Autodesk.Viewing.Profile(customProfileSettings);

    const _load = Autodesk.Viewing.Private.PropDbLoader.prototype.load;
    Autodesk.Viewing.Private.PropDbLoader.prototype.load = function () {
      this.needsDbIdRemap = true;
      _load.call(this);
    };

    const _processLoadResult =
      Autodesk.Viewing.Private.PropDbLoader.prototype.processLoadResult;
    Autodesk.Viewing.Private.PropDbLoader.prototype.processLoadResult =
      function (result) {
        _processLoadResult.call(this, result);
        this.model.idRemap = result.dbidOldToNew;
      };

    let component = this;
    Autodesk.Viewing.Initializer(options, function onInitialized() {
      let viewerDiv = document.getElementById("Viewer");
      let viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv, []);
      component.state.viewerApp = viewer;
      component.state.viewerApp.start();
      component.state.viewerApp.setProfile(customProfile);
      Autodesk.Viewing.Document.load(
        urn,
        component.onDocumentLoadSuccess,
        component.onDocumentLoadFailure
      );
    });
  };

  onDocumentLoadSuccess = (doc) => {
    let viewables = doc.getRoot().search({ type: "geometry" });
    let _self = this;
    if (!viewables.length) {
      console.error("Document contains no viewables.");
      return;
    }

    let initialViewable = viewables[0];

    // this.state.viewerApp.loadDocumentNode(doc);

    this.state.viewerApp
      .loadDocumentNode(doc, initialViewable, {})
      .then(async function (model) {
        await _self.afterViewerEvents(_self.state.viewerApp, [
          // Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
        ]);
        _self.state.viewerApp.setReverseZoomDirection(true);
        _self.state.viewerApp.setTheme("light-theme");

        _self.props.onModelLoaded(
          _self.state.viewerApp,
          _self.props.onLoadingComplete
        );
        _self.selectAllSame();
        // do something
      });

    // let svfUrl = doc.getViewablePath(initialViewable);
    // let modelOptions = {
    //   sharedPropertyDbPath: doc.getFullPath(doc.getRoot().findPropertyDbPath()),
    // };

    //   svfUrl,
    //   modelOptions,
    //   this.onLoadModelSuccess,
    //   this.onLoadModelError
    // );
  };

  afterViewerEvents = (viewer, events) => {
    let promises = [];
    events.forEach(function (event) {
      promises.push(
        new Promise(function (resolve, reject) {
          let handler = function () {
            viewer.removeEventListener(event, handler);
            console.log(`Removed event listener for ${event}`);
            resolve();
          };
          viewer.addEventListener(event, handler);
          console.log(`Added event listener for ${event}`);
        })
      );
    });

    return Promise.all(promises);
  };

  selectAllSame = () => {
    const { t } = this.props;
    this.state.viewerApp.registerContextMenuCallback(
      "selectAllSameMenuItem",
      (menu, status) => {
        if (status.hasSelected) {
          var marked = this.props.activeLab.properties.find(
            (item) => item.instanceID === this.state.viewerApp.getSelection()[0]
          );
          if (marked && marked.elementName) {
            menu.push({
              title: t("viewer.selectAllSame"),
              target: () => {
                var same = [];
                this.props.activeLab.properties.forEach((property) => {
                  if (
                    property.elementName &&
                    property.elementName === marked.elementName
                  )
                    same.push(property.instanceID);
                });
                this.state.viewerApp.select(same);
              },
            });
          }
        }
      }
    );
  };

  onDocumentLoadFailure = (viewerErrorCode) => {
    const { t } = this.props;
    console.error("onDocumentLoadFailure() - errorCode:" + viewerErrorCode);
    this.state.viewerApp.start();
    if (viewerErrorCode === 4) {
      window.alert(t("viewer.authorizationError"));
    }
    //this.state.viewerApp.setBackgroundColor(243,243,240,243,243,240);
  };

  onSelect = (event) => {
    this.setState({ viewState: this.state.viewerApp.viewerState.getState() });
  };

  onItemLoadFail = (errorCode) => {
    console.error("onItemLoadFail() - errorCode:" + errorCode);
  };

  componentDidMount() {
    this.launchViewer(this.props.urn, null);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.urn !== this.props.urn) {
      this.launchViewer(this.props.urn, null);
    }
  }

  render() {
    return (
      <>
        <div
          id="Viewer"
          className={`${this.props.visible ? "d-block" : "d-none"}`}
        ></div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const MyComponent = withTranslation()(connect(mapStateToProps)(Viewer));
export default MyComponent;
