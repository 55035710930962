import React, { Fragment } from "react";
import LabTabs from "./LabTabs";
import Viewer from "./Viewer.js";
import PublicViewer from "./PublicViewer";
import CompareLabsPanel from "./CompareLabsPanel";
import BuildingMaterialSelector from "./BuildingMaterialSelector";
import BTACharts from "./BTACharts";
import SelectInModel from "./SelectInModel";
import ModelTree from "../ModellTree/ModelTree";
import C3QTable from "../C3Q/C3QTable";
import { Redirect } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import ValidatorView from "./ValidatorView";
import Validator from "./Validator";
import FileMenue from "./FileMenue";
import ToolExtension from "../toolExtension/ToolExtension";
import ComponentEditor from "../componentEditor/ComponentEditor";
/* global Autodesk, THREE */

class LabArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      modelData: this.props.modelData,
      viewer: null,
      viewerLoaded: false,
      labsLoaded: false,
      activeLab: null,
      showCompareLabs: false,
      showFilters: false,
      showC3Q: false,
      showBuildingMaterialSelector: true,
      showViewer: true,
      displayCharts: true,
      displayModelTree: false,
      triggerBTAChartCalculation: false,
      filterValue: 0,
      show: false,
      show2: false,
      sessionTime: Date.now(),
      loadLab: true,
      validator: false,
      toolExtension: null,
      treeContainer: false,
      selectedMap: new Set(),
    };

    this.myRef = React.createRef();
  }

  time = () => {
    console.log(
      "Session time: " +
        (
          Math.round((Date.now() - this.state.sessionTime) / 600) / 100
        ).toString() +
        " minutes"
    );
  };

  componentDidMount() {
    console.log("Session time: 0 minutes");
    this.timer = setInterval(() => this.time(), 300000);
    this.props.redirectCheck();
    if (this.state.loadLab === true) {
      window.addEventListener("beforeunload", (e) =>
        this.state.activeLab ? (e.returnValue = "/") : null
      );
    }
    if (!this.props.isPublic) {
      window.addEventListener("unload", (e) => this.props.saveToLocalStorage());
      this.props.loadFromLocalStorage(this.state.loadLab);
      if (this.state.loadLab === false) {
        this.setState({
          loadLab: true,
        });
      }
    }
    this.activateTreeContainer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.model &&
      this.props.model &&
      prevProps.model.id !== this.props.model.id
    ) {
      this.state.showC3Q = false;
    }
    var toolbar = document.getElementById("guiviewer3d-toolbar");
    let viewcubeWrapper = document.getElementsByClassName("viewcubeWrapper");

    if(this.props.showValues !== false) {
      if (
        (this.state.viewer &&
          this.state.toolExtension === null &&
          this.state.showBuildingMaterialSelector &&
          toolbar) ||
        (this.state.viewer &&
          this.state.viewer.toolbar &&
          this.state.viewer.toolbar._controls.length !== 5 &&
          this.state.showBuildingMaterialSelector &&
          toolbar)
      ) {
        this.state.toolExtension = new ToolExtension(this.state.viewer, {
          props: this,
        });
        this.state.toolExtension.load();
      } else if (
        this.state.toolExtension &&
        !this.state.showBuildingMaterialSelector
      ) {
        this.state.toolExtension.unload();
        this.setState({
          toolExtension: null,
        });
      }
  
      if (this.state.toolExtension && this.state.showBuildingMaterialSelector) {
        this.state.toolExtension.updateProps(this.props);
      }
    }

    if (prevProps.model && this.props.model) {
      if (prevProps.model.urn !== this.props.model.urn) {
        this.setState(
          {
            viewer: null,
            modelData: this.props.modelData,
          },
          this.setStateToReloadComponents()
        );
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    if (this.state.toolExtension) {
      this.state.toolExtension.unload();
    }
  }

  changeTools = (show, e) => {
    this.state.viewer.clearSelection();
    for (let key in this.state) {
      if (key.indexOf("show") === 0) {
        this.setState({
          [key]: false,
        });
      }
    }

    this.setState({
      [show]: true,
    });

    if (show === "showC3Q") {
      this.updateSelectedMap(new Set());
      this.setState({ treeContainer: true });
    }

    if (show === "showBuildingMaterialSelector" || show === "showFilters") {
      this.setState({ showViewer: true });
    } else {
      this.setState({
        showViewer: false,
      });
    }
  };

  changeViewerTools = (e) => {
    if (this.state.show === true) {
      this.setState({
        show: false,
      });
      this.props.resizeEvent();
    } else {
      this.setState({
        show: true,
      });
      this.props.showHideModelNav(false);
    }
  };

  upGradeLabIds = (viewer) => {
    this.props.modelData.Labs.forEach((lab) => {
      lab.properties.forEach((element) => {
        element.instanceID = viewer.model.remapDbId(element.instanceID);
      });
    });
    this.props.modelData.needUpdateIds = false;
  };

  onLoadingComplete = (viewer) => {
    if (this.props.modelData.needUpdateIds) {
      this.upGradeLabIds(viewer);
    }

    this.setState(
      {
        viewer: viewer,
        viewerLoaded: true,
        labsLoaded: this.props.modelData.Labs.length > 0,
        activeLab: this.props.modelData.getActiveLab(),
      },
      this.props.resizeEvent()
    );
  };

  updateLabState = () => {
    this.setState(
      { activeLab: this.props.modelData.getActiveLab() },
      this.afterActiveLabStateUpdated()
    );
  };

  onSideBarToolEvent = (selected) => {
    switch (selected) {
      case "ComparePanel":
        this.setState({ showCompareLabs: !this.state.showCompareLabs });
        break;
      case "FilterPanel":
        this.setState({ showFilters: !this.state.showFilters });
        break;
    }
  };

  afterActiveLabStateUpdated = () => {
    this.setState({ triggerBTAChartCalculation: true });
  };

  resetBTAChartTrigger = () => {
    this.setState({ triggerBTAChartCalculation: false });
  };
  // Render //

  renderLabTabs = () => {
    if (
      this.state.viewerLoaded &&
      this.state.labsLoaded &&
      this.props.modelData
    ) {
      return (
        <LabTabs
          modelData={this.props.modelData}
          setModelData={(modelData) => this.props.setModelData(modelData)}
          updateLabState={() => this.updateLabState()}
          visible={!this.state.showCompareLabs}
          showValues={this.props.showValues}
          activateValidator={(e) => this.activateValidator(e)}
        />
      );
    }
    return null;
  };

  renderFileMenue = () => {
    return (
      <FileMenue
        modelData={this.props.modelData}
        setModelData={(modelData) => this.props.setModelData(modelData)}
        updateLabState={() => this.updateLabState()}
        visible={!this.state.showCompareLabs}
        showValues={this.props.showValues}
        activateValidator={(e) => this.activateValidator(e)}
        model={this.props.model}
        viewer={this.state.viewer}
      />
    );
  };

  renderViewer = () => {
    this.props.redirectCheck();
    if (this.props.model && !this.props.viewer) {
      if (this.props.isPublic) {
        return (
          <PublicViewer
            urn={this.props.model.urn}
            onModelLoaded={(viewer) =>
              this.props.onModelLoaded(viewer, this.onLoadingComplete)
            }
            activeLab={this.state.activeLab}
            redirectCheck={() => this.props.redirectCheck()}
            visible={this.state.showViewer}
          />
        );
      } else {
        return (
          <Viewer
            clearModelData={() => this.clearModelData()}
            urn={`urn:${this.props.model.urn}`}
            onModelLoaded={(viewer) =>
              this.props.onModelLoaded(viewer, this.onLoadingComplete)
            }
            activeLab={this.state.activeLab}
            isPublic={this.props.isPublic}
            redirectCheck={() => this.props.redirectCheck()}
            visible={this.state.showViewer}
          />
        );
      }
    } else {
      return (
        <div className={"loading"} style={{ whiteSpace: "pre-line" }}>
          <h2>
            <Trans i18nKey="labArea.startPageHeader" />
          </h2>
          {!this.props.isPublic ? (
            <>
              <Trans
                i18nKey="labArea.startPageText"
                components={{
                  1: (
                    <a
                      href="https://www.sweco.se/transforming-society-together/digitalisering/carbon-cost-compass/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      1
                    </a>
                  ),
                  2: (
                    <a href="/demo" target="_blank">
                      2
                    </a>
                  ),
                }}
              />
            </>
          ) : (
            <Trans
              i18nKey="labArea.demoStartPageText"
              components={{
                1: (
                  <a
                    href="https://www.sweco.se/transforming-society-together/digitalisering/carbon-cost-compass/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    1
                  </a>
                ),
              }}
            />
          )}
        </div>
      );
    }
  };

  handleCallback = (event) => {
    this.setState({ filterValue: event });
  };

  renderCompareLabsPanel = () => {
    if (this.state.viewerLoaded && this.state.labsLoaded) {
      return (
        <CompareLabsPanel
          modelData={this.props.modelData}
          viewer={this.state.viewer}
          visible={this.state.showCompareLabs}
          isPublic={this.props.isPublic}
          showValues={this.props.showValues}
        />
      );
    }
    return null;
  };

  renderMaterialSelector = () => {
    if (
      this.state.viewerLoaded &&
      this.props.modelData &&
      this.state.activeLab
    ) {
      return (
        <BuildingMaterialSelector
          toolExtension={this.state.toolExtension}
          displayModelTree={this.state.show}
          modelData={this.props.modelData}
          viewer={this.state.viewer}
          activeLab={this.state.activeLab}
          updateLabState={() => this.updateLabState()}
          visible={this.state.showBuildingMaterialSelector}
          showValues={this.props.showValues}
          filterValue={this.state.filterValue}
        />
      );
    }
    return null;
  };

  renderBTACharts = () => {
    if (
      this.state.viewerLoaded &&
      this.props.modelData &&
      this.state.labsLoaded &&
      this.state.activeLab &&
      this.props.modelData.getActiveLab()
    ) {
      return (
        <BTACharts
          modelData={this.props.modelData}
          activeLab={this.state.activeLab}
          visible={this.state.displayCharts}
          triggerCalculation={this.state.triggerBTAChartCalculation}
          triggerReset={this.resetBTAChartTrigger}
        />
      );
    }
    return null;
  };

  renderModelTree = () => {
    if (
      this.state.viewerLoaded &&
      this.props.modelData &&
      this.state.labsLoaded &&
      this.state.activeLab
    ) {
      return (
        <ModelTree
          {...this.props}
          updateParent={() => this.updateParent()}
          toolExtension={this.state.toolExtension}
          viewer={this.state.viewer}
          modelData={this.props.modelData}
          activeLab={this.state.activeLab}
          updateLabState={() => this.updateLabState()}
          visible={this.state.displayModelTree}
          triggerReset={this.resetBTAChartTrigger}
          filterValue={this.handleCallback}
          activateTreeContainer={(e) => {
            this.activateTreeContainer(e);
          }}
          selectedMap={this.state.selectedMap}
          updateSelectedMap={(e) => this.updateSelectedMap(e)}
          C3Q={this.state.showC3Q}
        />
      );
    }
    return null;
  };

  setStateToReloadComponents = () => {
    this.setState({
      viewerLoaded: false,
      labsLoaded: false,
      showCompareLabs: false,
      showFilters: false,
      showBuildingMaterialSelector: true,
      showViewer: true,
      displayModelTree: false,
      displayCharts: true,
    });
  };

  showHideModelNav = () => {
    this.props.showHideModelNav(true);
    if (this.state.show) {
      this.setState({
        show: false,
      });
    }
  };

  hideModellTree = () => {
    this.setState({
      show: false,
    });
    this.props.showHideModelNav(true);
    this.props.showHideModelNav(false);
  };

  closeModal = () => {
    this.setState({
      show2: false,
    });
  };

  activateValidator = (activate) => {
    this.setState({
      validator: activate,
    });
  };

  activateTreeContainer = (activate) => {
    this.setState({
      treeContainer: this.state.showC3Q ? true : !this.state.treeContainer,
    });
  };

  updateParent = () => {
    this.forceUpdate();
  };

  updateSelectedMap = (data) => {
    this.setState({
      selectedMap: data,
    });
  };

  renderValidator = () => {
    return (
      <Validator
        modelData={this.props.modelData}
        setModelData={(modelData) => this.props.setModelData(modelData)}
        updateLabState={() => this.updateLabState()}
        visible={!this.state.showCompareLabs}
        showValues={this.props.showValues}
        viewer={this.state.viewer}
        activateValidator={(e) => this.activateValidator(e)}
        display={this.state.validator}
      />
    );
  };

  renderC3Q = () => {
    return (
      <C3QTable
        activeLab={this.state.activeLab}
        showValues={this.props.showValues}
        modelData={this.props.modelData}
        selectedMap={this.state.selectedMap}
        updateSelectedMap={(e) => this.updateSelectedMap(e)}
        // filterValue={(e) => updateFilterValue(e)}
        // update={(e, f) => updateFilter(e)}
        // filterChanged={(e) => filterActivate(e)}
      />
    );
  };

  renderComponentEditor = () => {
    if (
      this.state.viewerLoaded &&
      this.props.modelData &&
      this.state.activeLab
    ) {
      return (
        <ComponentEditor
          activeLab={this.state.activeLab}
          updateParent={() => this.updateParent()}
          modelData={this.props.modelData}
          showValues={this.props.showValues}
          selectedMap={this.state.selectedMap}
          updateSelectedMap={(e) => this.updateSelectedMap(e)}
        />
      );
    } else {
      return null;
    }
  };

  selectInModel = () => {
    if (this.state.viewer) {
      return (
        <SelectInModel
          activeLab={this.state.activeLab}
          viewer={this.state.viewer}
          updateParent={() => this.updateParent()}
          modelData={this.props.modelData}
          showValues={this.props.showValues}
          selectedMap={this.state.selectedMap}
          updateSelectedMap={(e) => this.updateSelectedMap(e)}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    const viewer = this.renderViewer();
    const labTabs = this.renderLabTabs();
    const compareLabsPanel = this.renderCompareLabsPanel();
    const materialSelector = this.renderMaterialSelector();
    const componentEditor = this.renderComponentEditor();
    const vaildator = this.renderValidator();
    const btaCharts = this.renderBTACharts();
    const modelTree = this.renderModelTree();
    const C3Q = this.renderC3Q();
    const fileMenue = this.renderFileMenue();
    const selectInModel = this.selectInModel();

    let { showBuildingMaterialSelector, showCompareLabs, showC3Q } = this.state;

    return (
      <section id="lab-area">
        {this.state.redirect ? <Redirect push to="/reconnect" /> : null}
        <div
          id="lab-header"
          className={`${this.state.showCompareLabs ? "flex-justify-end" : ""}`}
          style={{ zIndex: 11, postion: "relative" }}
        >
          {/* <button id="nav-toggle" type="button" className={`btn nav-toggle ${this.props.showModelNav && this.state.show === false ? 'd-none' : 'd-block'}`} onClick={this.showHideModelNav} data-keyboard="true">
              <span className="sr-only">Show navigation</span>
            </button> */}
          {this.state.viewerLoaded && this.props.modelData ? fileMenue : null}
          {labTabs}
          <div id="nav-viewer-tools">
            {this.state.viewerLoaded && this.props.modelData ? (
              <nav className="btn-group d-none d-lg-block">
                <button
                  type="button"
                  className={`btn btn-secondary btn-sm ${
                    showBuildingMaterialSelector ? "active" : ""
                  }`}
                  onClick={(e) =>
                    this.changeTools("showBuildingMaterialSelector", e)
                  }
                >
                  C3View
                </button>
                <button
                  type="button"
                  className={`btn btn-secondary btn-sm ${
                    showC3Q ? "active" : ""
                  }`}
                  onClick={(e) => this.changeTools("showC3Q", e)}
                >
                  C3Q
                </button>
                <button
                  type="button"
                  className={`btn btn-secondary btn-sm ${
                    showCompareLabs ? "active" : ""
                  }`}
                  onClick={(e) => this.changeTools("showCompareLabs", e)}
                >
                  <Trans i18nKey="labArea.compareLabs" />
                </button>
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
        <div id="viewer-container">
          <div
            id="viewer-tree-container"
            className={`${
              this.state.treeContainer &&
              this.state.activeLab &&
              !this.state.showCompareLabs
                ? "expanded"
                : ""
            }`}
          >
            <div id="tree-container">
              {modelTree}
              {btaCharts}
            </div>
            <div
              id="viewer-tree-container-button"
              onClick={(e) => {
                this.activateTreeContainer();
              }}
            >
              {this.state.activeLab &&
              !this.state.showCompareLabs &&
              !this.state.showC3Q ? (
                <button
                  type="button"
                  className="btn icon icon-arrow"
                  id="containerButton"
                  onClick={(e) => {
                    this.activateTreeContainer();
                  }}
                  style={{
                    transform: this.state.treeContainer
                      ? "rotate(90deg)"
                      : "rotate(-90deg)",
                    width: "5px",
                    height: "5px",
                    backgroundPosition: "center",
                  }}
                ></button>
              ) : null}
            </div>
          </div>
          {this.state.show2 &&
          this.state.viewerLoaded &&
          this.props.modelData ? (
            <div className="modal" style={{ display: "block" }}>
              <div className="modal-content">
                <button className="close-button" onClick={this.closeModal}>
                  &times;
                </button>
                <ValidatorView modelData={this.props.modelData}></ValidatorView>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div id='viewerSize' style={{width: '0px', postion: 'realtive'}}></div> */}
          {viewer}
          {selectInModel}
          {showC3Q ? C3Q : null}
          {this.state.validator &&
          !this.props.modelData.getActiveLab().main &&
          this.props.showValues === true ? (
            <div
              id="viewer-tools"
              style={{
                flexDirection: "column",
                alignItems: "stretch",
                background: "white",
              }}
              className={`${
                this.state.viewerLoaded &&
                this.state.activeLab &&
                !this.state.showCompareLabs 
                // !this.state.showC3Q
                  ? "d-flex"
                  : "d-none"
              }`}
            >
              {vaildator}
            </div>
          ) : showCompareLabs ? null : (
            componentEditor
          )}
          {compareLabsPanel}
        </div>
      </section>
    );
  }
}

const MyComponent = withTranslation()(LabArea);
export default MyComponent;
