import React, { Component, Fragment } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { GetElementsBydesignPartName } from '../labArea/DataConnector';
import { calculationMarkUp } from '../../helpers/helpers'
import ParserSettings from '../../config/parsingSettings.json'
/* global Autodesk, THREE */ // eslint-disable-line

class Validator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            labs: [],
            selected: this.props.modelData.getActiveLab().name,
            outOfDateShow: false,
            lab: '',
            outOfdateElements: 0
        }
    }

    componentDidMount() {
        if (!this.props.modelData.getActiveLab().main && (!this.state.outOfDateShow || this.state.lab !==this.props.modelData.getActiveLab().name)) this.outOfdateFilter(true);
    }

    componentDidUpdate() {
        if (!this.props.modelData.getActiveLab().main && (!this.state.outOfDateShow || this.state.lab !==this.props.modelData.getActiveLab().name)) this.outOfdateFilter(true);
    }

    componentWillUnmount() {
        if (this.state.outOfDateShow) this.outOfdateFilter(false);
    }

    async updateAllOutOfDate() {
        const { t } = this.props;
        const modelData = this.props.modelData.getActiveLab()
        const designPartNames = new Set();
        const newRecpies = [];
        const recpiesMap = {};

        this.loadingWheel(true)
    
        for await (const element of modelData.properties) {
            if (element.isLatest === false) {
                try {
                    if (!designPartNames.has(element.designPartName)) {
                        const packages = this.props.modelData.model && this.props.modelData.model.Packages ? this.props.modelData.model.Packages.flatMap((p)=> p.active ? [p.PackageID] : []) : null;
                        const recpies = await new Promise((resolve) => {
                            setTimeout(async () => {
                                const result = await GetElementsBydesignPartName(element.designPartName, packages);
                                resolve(result);
                            }, 200);
                        });
                        recpiesMap[element.designPartName] = recpies;
                        if(recpies) {
                            newRecpies.push(recpies.find(el => el.elementId === element.elementId));
                            designPartNames.add(element.designPartName);
                        }
                    } else {
                        newRecpies.push(recpiesMap[element.designPartName].find(el => el.elementId === element.elementId));
                    }
                } catch (e) {
                    console.log(e);
                    console.log("error fetching recpie of: " + element.designPartName)

                }

                if (newRecpies[0] === undefined) {
                    alert(t('validator.missingRecipeAccess'))
                    break

                } else {
                    const recpie = newRecpies.find(el => el.elementId === element.elementId);             
                    if (recpie) {
                        Object.assign(element, {
                            elementName: recpie.elementName,
                            elementId: recpie.elementId,
                            databaseID: recpie.elementVersionId,
                            elementVersionId: recpie.elementVersionId,
                            isLatest: recpie.isLatestVersion,
                            CO2: element.calculatedArea * recpie.co2,
                            Cost: element.calculatedArea * recpie.unitCost * calculationMarkUp(recpie.elementVersionId, recpie.elementId),
                            elementCO2: recpie.co2,
                            elementUnitCost: recpie.unitCost,
                            designPartName: recpie.designPartName,
                            designPartCategoryName: recpie.designPartCategoryName,
                            ExistInDatabase: true,
                            Updated: false
                        });
                    }
                }
            }
        }
        this.loadingWheel(false)
        this.props.updateLabState()
        this.props.activateValidator(false);
    }

    outOfdateFilter = (con) => {
        if (this.props.viewer && this.props.modelData.getActiveLab()) {
            var properties = this.props.modelData.getActiveLab().properties
            if (con === false) {
                properties.forEach(element => {
                    this.changeColor(element.instanceID, false);
                });
            } else {
                var found = false
                const categories = ParserSettings.lookupTable.map(item => item.c3Category);
                properties.forEach(element => {
                    if (element.isLatest === false && element.elementName) {
                        this.changeColor(element.instanceID, true);
                        found = true
                    } else {
                        this.changeColor(element.instanceID, false);
                    }
                    if (!found && element.ExistInDatabase) {
                        var number = Number(element.calculatedArea)
                        if ((((!number || number == 0) && element.visible !== false) || categories.includes(element.category) === true)) {
                            found = true
                        }
                    }
                });
                if (!found) this.props.activateValidator(false);
            }
            this.setState({
                outOfDateShow: con,
                lab: this.props.modelData.getActiveLab().name
            })         
        }
    }

    changeColor(outOfDate, color) {
        this.props.viewer.setThemingColor(
            outOfDate,
            color ? this.getColor('#d36e6e') : null,
            this.props.viewer.getAllModels()[0]
        );
    }

    getColor = (hex) => {
		const colorHexStr = hex.replace('#', '0x');
		const colorInt = parseInt(colorHexStr, 16);
		const clr = new THREE.Color(colorInt);
		return new THREE.Vector4(clr.r, clr.g, clr.b, clr.a);
	};

    renderOutOfDate = () => {
        const { t } = this.props;
        const modelData = this.props.modelData.getActiveLab()
        var outOfdateElements = []

        modelData.properties.forEach(element => {
            if (element.isLatest === false && element.elementName) outOfdateElements.push(element);
        });
        this.state.outOfdateElements = outOfdateElements.length

        // if (!this.state.outOfDateShow) this.outOfdateFilter(true);

        if (outOfdateElements.length !== 0) {
            return(
                <div id="validator-outOfDate" style={{padding: '0.75rem 1rem', border: "1px solid #d8d8d8", margin: "2rem", background: 'white'}}>
                    <div>
                        <h1 style={{fontSize: '1.49rem'}}>{t('validator.updateDataHeader')}</h1>
                        <p>{t('validator.updateDataMessage', { count: outOfdateElements.length.toString() })}</p>
                        <p>{t('validator.keepInMind')}</p>
                    </div>
                    <button
                    id="update"
                    type='button'
                    className='btn btn-primary-green btn-sm'
                    onClick={() => { this.updateAllOutOfDate(); this.outOfdateFilter(false)}}
                    >
                    {t('validator.yes')}
                </button>
                <button
                    id="keep"
                    type='button'
                    className='btn btn-sm'
                    onClick={() => {this.props.activateValidator(false); this.outOfdateFilter(false)}}
                    >
                    {t('validator.keep')}
                </button>
            </div>
            )
        } else {
            return null
        }
    }

    loadingWheel = (bool) => {
        var container = document.getElementById('circle-validator');
        var validator = document.getElementById('validator-outOfDate');
        var labHeader = document.getElementById('lab-header');
        if (container) {
            container.style.display = bool? 'block' : 'none';
            validator.style['pointer-events'] = bool? 'none' : '';
            labHeader.style['pointer-events'] = bool? 'none' : '';
        }
    }

    missingRecpie = () => {
        var properties = this.props.modelData.getActiveLab().properties
        const categories = ParserSettings.lookupTable.map(item => item.c3Category);
        var filteredArray = [...new Set(properties
            .filter(item => { return item.ExistInDatabase === false && categories.includes(item.category) === true? item : null; })
            .map(item => { return item; }))]
        return filteredArray.length      
    }

    filterMissingQuantity = () => {
        const { t } = this.props;
        var missingRecepie = this.missingRecpie();
        const categories = ParserSettings.lookupTable.map(item => item.c3Category);
        var count = 0
        var properties = this.props.modelData.getActiveLab().properties
        properties.forEach(element => {
            var number = Number(element.calculatedArea)
            if ((!number || number == 0) && element.visible !== false && categories.includes(element.category) === true) {
                count = count +1
            }
        });

        if (count === 0 && this.state.outOfdateElements === 0 && missingRecepie === 0) this.props.activateValidator(false);
        if (count !== 0 || missingRecepie !== 0 ) {
            return(
                <>
                    <div id="validator-missingQuantity" style={{padding: '0.75rem 1rem', border: "1px solid #d8d8d8", margin: "2rem", marginTop: this.state.outOfdateElements === 0 ? '2rem':'0px', background: 'white'}}>
                        <div>
                            <h1 style={{fontSize: '1.49rem'}}>{t('validator.missingDataHeader')}</h1>
                            <p>{t('validator.missingDataMessage')}</p>
                            { count !== 0 ?  
                                <>
                                    <p>{t('validator.missingQuantityTool', { count: count.toString() })} <span className="icon icon-cube" style={{height: '24px', display: 'inline-block', backgroundSize: 'contain', position: 'absolute', margin: '1px'}}></span></p>
                                </> : null 
                            }
                            { missingRecepie !== 0 ? 
                                <>
                                    <p>{t('validator.missingRecepieMessage', { count: missingRecepie.toString() })} <Trans i18nKey="modelTree.uncalculatedComponents"/></p>
                                </> : null
                            }
                            { this.state.outOfdateElements === 0 ? 
                                <button
                                    id="missingQuantityOK"
                                    type='button'
                                    className='btn btn-primary-green btn-sm'
                                    onClick={() => {this.props.activateValidator(false)}}
                                    >
                                    {t('validator.ok')}
                                </button> 
                                : null
                            }
                        </div>
                    </div>
                </> 
            )
        } else { 
            return null
        }; 
    }

  render() {
    const { t } = this.props;
    const outOfDate = this.renderOutOfDate()
    const missingQuantity = this.filterMissingQuantity()

    return (
        <Fragment>
            <div id="circle-validator" className='sk-circle-validator'>
                <div className = 'sk-circle1 sk-child'/>
                <div className = 'sk-circle2 sk-child'/>
                <div className = 'sk-circle3 sk-child'/>
                <div className = 'sk-circle4 sk-child'/>
                <div className = 'sk-circle5 sk-child'/>
                <div className = 'sk-circle6 sk-child'/>
                <div className = 'sk-circle7 sk-child'/>
                <div className = 'sk-circle8 sk-child'/>
                <div className = 'sk-circle9 sk-child'/>
                <div className = 'sk-circle10 sk-child'/>
                <div className = 'sk-circle11 sk-child'/>
                <div className = 'sk-circle12 sk-child'/>
            </div>
            {outOfDate}
            {missingQuantity}
        </Fragment>
    );
  }
}

//export default LabTabs;
const MyComponent = withTranslation()(Validator)
export default MyComponent;