import React, { Component, Fragment } from 'react';
import "rc-tree/assets/index.css"
import ModelProperties from '../ModellTree/ModelProperties';
import { Trans, withTranslation } from 'react-i18next';
import ParserSettings from '../../config/parsingSettings.json'
/* global Autodesk, THREE */ // eslint-disable-line

class QuantityTool extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayQuantityProperties: false,
            displayChangeProperties: true,
            quantityFilter: false,
        };
    }

    componentDidMount() {
        this.props.viewer.addEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,//SELECTION_CHANGED_EVENT,
            this.update,);
    }

    componentWillUnmount() {
        this.props.viewer.removeEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,//SELECTION_CHANGED_EVENT,
            this.update,);
	}

    update = () => {
        var button = document.getElementById('quantity-tool-button')
        if (button && button.classList.contains('inactive')) {
            this.componentWillUnmount()
        }
        this.setState({})
    }

    changeViewerTools = (show, e) => {
        this.setState({ 
            displayChangeProperties: show !== "displayChangeProperties" ? false : true,
            displayQuantityProperties: show !== "displayQuantityProperties" ? false : true,
        });
    }

    setQuantity = () => {
        const { t } = this.props;
        if (this.inputRef) {
            var selected = this.props.viewer.getSelection()
            var properties = this.props.modelData.getActiveLabProperties()
            var updated = false
            var value = Number(this.inputRef.value.toString().replace(',','.'))
            if (isNaN(value)) {
                alert(this.inputRef.value.toString() + t("modelTree.validNumber"))
            } else {
                properties.forEach(prop => {
                    if (Array.from(selected).includes(prop.instanceID)) {
                        prop.calculatedArea = value;
                        prop.CO2 = prop.elementCO2 * value;
                        prop.Cost = prop.elementUnitCost * value;
                        prop.ExistInDatabase = prop.designPartName ? true : false;
                        prop.visible = value === 0 ? true : false;
                        updated = true
                    }
                });
            
                if (updated === true ) {
                    this.props.modelData.updateActivLab(properties);
                    this.props.options.updateLabState()
                }
            };
        }
    }

    changeQuantity = () => {
        const { t } = this.props;
        return (
            <Fragment>
                <h3 style={{marginBottom: '10px', marginTop: '10px', textAlign: 'center'}}><Trans i18nKey='quantityTool.info' components={[<sup />]}/></h3>
                <input 
                    ref={inputRef => this.inputRef = inputRef}
                    type="text" 
                    placeholder="1234.123"
                    disabled={this.props.modelData.getActiveLab().main === true || this.props.viewer.getSelection().length === 0 ? true : false}
                    style={{width: '58%' , height: '35px'}}
                />
                <input 
                    type='submit'
                    style={{ marginTop: '-5px', marginLeft: '10px' , height: '40px', width: '37%'}}
                    className="btn btn-primary-green" 
                    id="byt-Quantity"
                    onClick={() => this.setQuantity()}
                    value={t('quantityTool.quantityButton')}
                    disabled={this.props.modelData.getActiveLab().main === true || this.props.viewer.getSelection().length === 0 ? true : false}
                />
            </Fragment>
        );
    }

    renderModelProperties = () => {
        const { showCompareLabs } = this.state
        return (
            <ModelProperties
                changeQuantity={this.state.displayQuantityProperties}
                viewer={this.props.viewer}
                modelData={this.props.modelData}
                selected={this.props.viewer.getSelection()}
                updateFilter={(e) => {this.filterMissingQuantity(e)}}
                setModelData={(modelData) => this.props.setModelData(modelData)}
                updateLabState={() => this.props.options.updateLabState()}
                visible={!showCompareLabs}
                showValues={this.props.showValues}
                goBack={ () => this.changeViewerTools('displayChangeProperties')}
            />
        );
    }

    filterMissingQuantity = (con) => {
        var modelVisibilityManager = this.props.viewer.getAllModels()[0].visibilityManager
        const categories = ParserSettings.lookupTable.map(item => item.c3Category)
        var properties = this.props.modelData.getActiveLab().properties
        if (con === true) {
            this.setState({ quantityfilter: !this.state.quantityFilter})
            this.state.quantityFilter = !this.state.quantityFilter
        }
        if (this.state.quantityFilter) {
            modelVisibilityManager.setNodeOff(1, true)
            properties.forEach(element => {
                var number = Number(element.calculatedArea)
                if ((!number || number == 0) && element.visible === true && categories.includes(element.category) === true) {
                    modelVisibilityManager.setNodeOff(element.instanceID, false)
                } else {
                    element.visible = false
                    modelVisibilityManager.setNodeOff(element.instanceID, true)
                }
            });
        } else if (!this.state.quantityFilter){
            this.props.options.updateLabState()
        }
    }

    quantityFilter = () => {
        return (
            <div style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px -3px 5px 0px', borderTop: '1px solid rgba(0,0,0,.29)', height: '40px', background: 'white', marginTop: '15px', bottom: '0px', position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <h3 style={{marginBottom: '5px', paddingRight: '10px'}}> <Trans i18nKey="modelTree.missingQuantity"/></h3 >
                <div className={"custom-control custom-radio"}>
                    <input
                        type="checkbox"
                        id="quantityFilter"
                        value="0"
                        className={"custom-control-input"}
                        checked={this.state.quantityFilter}
                        onChange={() => {this.filterMissingQuantity(true)}}
                    />
                    <label htmlFor="quantityFilter" className={"custom-control-label"}></label>
                </div>
            </div>
        )
    }


    render() {
        const { t } = this.props;
        var modelProperties = this.renderModelProperties();
        var changeQuantity = this.changeQuantity()
        var selected = this.props.viewer.getSelection()
        let {displayChangeProperties, displayQuantityProperties } = this.state;

        return (
            <Fragment>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', borderBottom: '1px solid rgba(0,0,0,.29)', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 5px 0px', height: '50px', position: 'absolute', width: '100%', zIndex: '10', background: 'white'}}>
                        {displayQuantityProperties === true?  
                            <div onClick={(e) => {this.changeViewerTools('displayChangeProperties')}} style={{cursor: 'pointer', position: 'absolute', left: '15px' }}>
                                <span className="icon icon-long-arrow" style={{height: '70px', display: 'block', backgroundSize: 'contain', rotate: '90deg'}}></span>
                            </div> 
                        : null }
                        <h1>Quantity Tool</h1>
                    <div className="docking-panel-close" onClick={(e) => document.getElementById('quantity-tool-button') ? document.getElementById('quantity-tool-button').click() : null}></div>
                </div>
                {displayChangeProperties === true?   
                    <>
                        <div id="selectedPropertiesTools" style={{ padding: '10px', marginTop: 'auto', display: 'flex',  top: '40px', position: 'relative', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{width: '100%'}}>
                                {changeQuantity}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                                <h3 style={{marginBottom: '10px', marginTop: '10px'}}>{t('quantityTool.or')}</h3> 
                                <button type="button" disabled={this.props.modelData.getActiveLab().main === true || selected.length === 0 ? true : false} style={{height: 'max-content', width: '100%', border: '1px solid gray', height: '35px'}} className={`btn btn-secondary btn-sm ${displayQuantityProperties && (selected.length > 0) ? "active" : ""}`}  onClick={(e) => this.changeViewerTools('displayQuantityProperties', e)}><Trans i18nKey="modelTree.chooseQuantity"/></button>
                            </div>
                        </div>
                    </>
                    : null
                }

                {displayQuantityProperties === true?  
                    <>            
                        <div id="selectedProperties" style={{ height: 'auto', marginTop: 'auto', paddingBottom: '40px', top: '50px', position: 'relative', overflow: 'hidden'}}>
                            {selected.length === 1 ? modelProperties : <p><Trans i18nKey="modelTree.selectionNotValid"/></p> }
                        </div> 
                    </>
                    : null
                }
                {this.quantityFilter()}
            </Fragment>
        )
    }
}

const MyComponent = withTranslation()(QuantityTool)
export default MyComponent;