import { GET_SELECTED_PROJECT } from '../constants/Constants';

const initalState = {data: ''}

export const getSelectedProjectReducer = (state = initalState, action = {}) => {

  switch (action.type) {
    case GET_SELECTED_PROJECT: return { ...state, data: action.payload, };
    default: return state;
  }
}