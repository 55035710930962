import React, { Component } from "react";
import CompareLabStackedBarChart from "./CompareLabStackedBarChart";
import DataViewerContainer from "./DataViewerContainer";
import { nrFormatter } from "../../helpers/helpers";
import parsingSetting from "../../config/parsingSettings.json";
import ExcelExporter from "./ExcelExporter";
import Tooltip from "../tooltip/Tooltip";
import { withTranslation } from "react-i18next";

const indicationValues = [
  {
    name: "Flerbostadshus indikationsintervall CO2e",
    key: "apartment",
    max: 460,
    min: 260,
  },
  {
    name: "Småhus indikationsintervall CO2e",
    key: "small",
    max: 320,
    min: 180,
  },
  {
    name: "Lokal indikationsintervall CO2e",
    key: "local",
    max: 840,
    min: 230,
  },
];

class CompareLabsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: "All",
      selectOptions: [],
      selectedIndicationValue: { name: "none" },
      selectedIndicationName: null,
      showIndicationControl: true,
    };
  }

  GetDataForTickLabels = () => {
    let myData = [];
    let BTA = this.props.modelData.BTA;
    this.props.modelData.Labs.forEach((lab) => {
      let onlyObjectsInDatabase = lab.properties.filter((x) => {
        if (x.ExistInDatabase === true && x.calculateElement === true) {
          return x;
        }
      });

      let sumOfCO2 = onlyObjectsInDatabase.reduce((a, b) => +a + +b.CO2, 0);
      let sumOfCost = onlyObjectsInDatabase.reduce((a, b) => +a + +b.Cost, 0);
      let sumOfCO2perBTA = sumOfCO2 / BTA;
      let sumOfCostperBTA = sumOfCost / BTA;

      myData.push({
        name: lab.name,
        sumOfCO2: nrFormatter(Math.round(sumOfCO2 / 1000)),
        sumOfCost: nrFormatter(Math.round(sumOfCost / 1000)),
        sumOfCO2perBTA: nrFormatter(Math.round(sumOfCO2perBTA)),
        sumOfCostperBTA: nrFormatter(Math.round(sumOfCostperBTA)),
      });
    });
    return myData;
  };

  GetOrganizedDataForCompareLabs = (labs) => {
    let BTA = this.props.modelData.BTA;
    let compareLabsData = [];
    let _self = this;
    labs.forEach((lab) => {
      let collection = OrderProperties(lab);

      compareLabsData.push(collection);
    });
    return compareLabsData;

    function OrderProperties(lab) {
      var result = [];
      let onlyObjectsInDatabase = lab.properties.filter((x) => {
        if (x.ExistInDatabase === true && x.calculateElement === true) {
          return x;
        }
      });

      if (_self.state.selectedCategory !== "All") {
        onlyObjectsInDatabase = onlyObjectsInDatabase.filter((x) => {
          if (x.category === _self.state.selectedCategory) {
            return x;
          }
        });
      }

      let sumOfCO2 =
        onlyObjectsInDatabase.reduce((a, b) => +a + +b.CO2, 0) / BTA;
      let sumOfCost =
        onlyObjectsInDatabase.reduce((a, b) => +a + +b.Cost, 0) / BTA;

      onlyObjectsInDatabase.reduce(function (res, value) {
        if (!res[value.designPartName]) {
          res[value.designPartName] = {
            name: value.designPartName,
            cost: 0,
            category: value.category,
            CO2: 0,
            CO2Percent: 0,
            CostPercent: 0,
          };
          result.push(res[value.designPartName]);
        }
        res[value.designPartName].CO2 += value.CO2 / BTA;
        res[value.designPartName].cost += value.Cost / BTA;

        return res;
      }, {});

      result.forEach((designPartName) => {
        designPartName.CO2Percent = (100 * designPartName.CO2) / sumOfCO2;
        designPartName.CostPercent = (100 * designPartName.cost) / sumOfCost;
      });
      let reordedInCategory = result.reduce(function (res, value) {
        if (!res[value.category]) {
          res[value.category] = {
            // color: graphcolors[value.category],
            category: value.category,
            CO2: 0,
            cost: 0,
            designPartNames: [],
            CO2Percent: 0,
            CostPercent: 0,
          };
        }
        res[value.category].designPartNames.push(value);
        res[value.category].CO2 += value.CO2;
        res[value.category].cost += value.cost;
        res[value.category].CO2Percent += value.CO2Percent;
        res[value.category].CostPercent += value.CostPercent;
        return res;
      }, {});

      reordedInCategory.name = lab.name;
      reordedInCategory.main = lab.main;

      return reordedInCategory;
    }
  };

  organizeBarData = () => {
    let myData = [];
    let BTA = this.props.modelData.BTA;
    this.props.modelData.Labs.forEach((lab) => {
      let onlyObjectsInDatabase = lab.properties.filter((x) => {
        if (x.ExistInDatabase === true && x.calculateElement === true) {
          return x;
        }
      });

      if (this.state.selectedCategory !== "All") {
        onlyObjectsInDatabase = onlyObjectsInDatabase.filter((x) => {
          if (x.category === this.state.selectedCategory) {
            return x;
          }
        });
      }

      let collection = onlyObjectsInDatabase.reduce(function (res, value) {
        if (!res[value.category]) {
          res[value.category] = {
            name: value.category,
            cost: 0,
            category: value.category,
            CO2: 0,
            CO2Percent: 0,
            CostPercent: 0,
          };
        }
        res[value.category].CO2 += value.CO2 / BTA;
        res[value.category].cost += value.Cost / BTA;

        return res;
      }, {});

      myData.push({
        name: lab.name,
        categories: collection,
      });
    });
    return myData;
  };

  handleChange = (select) => {
    if (select.target.value === "All") {
      this.setState({
        selectedCategory: select.target.value,
        showIndicationControl: true,
      });
    } else {
      this.setState({
        selectedCategory: select.target.value,
        showIndicationControl: false,
        selectedIndicationName: null,
      });
    }
    this.setState({
      selectedCategory: select.target.value,
    });
  };

  handleSelectedIndicationValueChange = (select) => {
    let res = indicationValues.filter((x) => {
      if (x.name === select.target.value) return x;
    });
    if (res.length > 0) {
      this.setState({
        selectedIndicationValue: res[0].name,
        selectedIndicationName: res[0],
      });
    } else {
      this.setState({
        selectedIndicationValue: "Välj indikationsintervall för CO2e",
        selectedIndicationName: null,
      });
    }
  };

  getCategories = () => {
    let onlyObjectsInDatabase = this.props.modelData.Labs[0].properties.filter(
      (x) => {
        if (x.ExistInDatabase === true && x.calculateElement === true) {
          return x;
        }
      }
    );
    return onlyObjectsInDatabase
      .map((item) => item.category)
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  render() {
    const { t } = this.props;
    const data = this.GetOrganizedDataForCompareLabs(this.props.modelData.Labs);
    const barData = this.organizeBarData();
    const tickData = this.GetDataForTickLabels();
    const viewerContainer = document.getElementById("viewer-container");
    const chartHeight = viewerContainer.offsetHeight / 2 - 56 - 20 - 70;
    const chartWidth = viewerContainer.offsetWidth - 400;
    const uniqueCategories = [
      ...new Set(
        parsingSetting.lookupTable.map(
          (categoryName) => categoryName.c3Category
        )
      ),
    ];
    return (
      <>
        {this.props.visible ? (
          <div id="compare-labs-panel">
            <section id="filter-and-export">
              <div>
                <span className="icon icon-lg icon-filter mr-2 ml-2" />
                <select
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.selectedCategory}
                >
                  <option value="All">
                    {t("compareLabsPanel.selectComponent")}
                  </option>

                  {uniqueCategories.map((category) => {
                    return (
                      <option key={category} value={category}>
                        {t("recipes.designPartCategoryName." + category)}
                      </option>
                    );
                  })}
                </select>
                {this.state.showIndicationControl ? (
                  <>
                    <select
                      className="form-control"
                      onChange={this.handleSelectedIndicationValueChange}
                      value={this.state.selectedIndicationValue.name}
                    >
                      <option
                        value={{ name: "Välj indikationsintervall för CO2e" }}
                      >
                        {t("compareLabsPanel.selectIndicationIntervalCO2e")}
                      </option>
                      {indicationValues.map((indicationValue, i) => {
                        return (
                          <option key={i} value={indicationValue.name}>
                            {t(
                              "compareLabsPanel.indicationInterval." +
                                indicationValue.key
                            )}
                          </option>
                        );
                      })}
                    </select>
                    <Tooltip
                      icon="icon-information"
                      text={t("compareLabsPanel.indicationIntervalInfo")}
                    />
                  </>
                ) : null}
              </div>
              {this.props.isPublic ? null : (
                <ExcelExporter modelData={this.props.modelData}></ExcelExporter>
              )}
            </section>
            <section id="chart-data">
              <div id="chart-table-container">
                <CompareLabStackedBarChart
                  indicationValues={this.state.selectedIndicationName}
                  tickData={tickData}
                  data={barData}
                  height={chartHeight}
                  width={chartWidth}
                />
              </div>
              <DataViewerContainer
                data={data}
                showValues={this.props.showValues}
              ></DataViewerContainer>
            </section>
          </div>
        ) : null}
      </>
    );
  }
}

//export default CompareLabsPanel;

const MyComponent = withTranslation()(CompareLabsPanel);
export default MyComponent;
