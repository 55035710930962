import React, { Component, Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const FileMenue = (props) => {
  // const { t, i18n } = useTranslation();
  const { model, viewer } = props;
  const [hover, setHover] = useState(false)
  const [menue, setMenue] = useState(false)
  const [change, setChange] = useState(false)
  const renderPackages = () => {
    return model.Packages.map((packages) => {
      return (
        <div key={packages.PackageID} id={packages.PackageID} className="custom-control custom-checkbox m-1" style={{paddingTop: '5px', paddingBottom: '5px', paddingLeft: '40px'}}>
          <input type="checkbox" className="custom-control-input" id={"toggle-"+packages.PackageID} checked={packages.active} onChange={() => packagesChange(packages)}/>
          <label className="custom-control-label" htmlFor={"toggle-"+packages.PackageID}> 
            {packages.PackageName}
          </label>
        </div>
      );
    });
  };
  const [packages, setPackages] = useState(model.Packages ? renderPackages() : null)
  const packagesChange = (packages) => {
    packages.active = !packages.active
    setChange(!change)
    viewer.select([]);
  }

  useEffect(() => {
    if (model.Packages) {
      setPackages(renderPackages())
    }
  }, [change]);

  // const [recipe, setRecipe] = useState(false)

	return (
    <>
      <div 
        onMouseLeave={() => {setMenue(false); setHover(false)}} 
        onMouseEnter={() => setHover(true)}
        style={{display: 'contents'}}>
        <div 
          id="arkiv-button" 
          className="btn" 
          style={{backgroundColor: hover || menue ? '#7A9B62' : '', padding: '14px', borderRight: '0.5px solid #d8d8d8'}}
          onClick={(e) => setMenue(!menue)}
          >
          <h4 style={{marginBottom: '0px', fontSize: '18px'}}>Arkiv</h4>
        </div>
        {menue ?
          <>
            <div               
              style={{
                width: '200px', 
                height: '10px', 
                position: 'absolute', 
                top: '56px', 
                left: '5px', 
                zIndex: '10000', 
              }}>
            </div>
            <div 
              id="arkiv-menue" 
              style={{backgroundColor:'white', 
                      width: '200px', 
                      position: 'absolute', 
                      top: '60px', 
                      // marginTop: '60px',
                      left: '5px', 
                      zIndex: '10000', 
                      border: '0.5px solid #d8d8d8',
                      boxShadow: 'rgba(0, 0, 0, 0.1) -2px 2px 6px 2px',
                      padding: '0.5rem'
                    }}
              >              
              <div style={{padding: '0.25rem'}}>
                <div style={{borderBottom: '0.5px solid #d8d8d8', display: 'flex', justifyContent: 'space-between'}}>
                  <p style={{marginBottom: '0px', fontSize: '18px'}}><Trans i18nKey="fileMenue.myRecipe"/></p>
                  {/* <button
                    type='button'
                    className='btn icon icon-arrow'
                    id='myRecipeButton'
                    onClick={(e) => setRecipe(!recipe)}
                    style={{ transform: recipe ? 'rotate(0deg)' : 'rotate(180deg)', backgroundPosition: 'center' }}
                >
                  </button> */}
                </div>
                {packages}
              </div>  
            </div> 
          </>: null
        }
      </div>
    </>
	);
};

export default FileMenue;