import React,  { Component } from 'react';
import i18next from 'i18next';
import Select from 'react-select';

class LanguageSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "en",
      clicked: false,
      lan: [ "en", "sv"],
    };
    this.selectRef = React.createRef();
    this.onClick = this.onClick.bind(this);
  }


  componentDidMount() {
    if (this.state.text !== i18next.language  && i18next.language !== undefined) {
      if (this.state.lan.includes(i18next.language)) {
        this.setState({ text: i18next.language })
      } else {
        this.setState({ text: "en" })
      }
    }
  }

  componentDidUpdate () {
    if (this.state.text !== i18next.language  && i18next.language !== undefined) {
      if (this.state.lan.includes(i18next.language)) {
        this.setState({ text: i18next.language })
      } else if ( this.state.text !== "en") {
        this.setState({ text: "en" })
      }
    }
  }

  onClick() {
    if (this.selectRef.current) {
      var click = true
      if (this.state.clicked) {
        this.selectRef.current.blur();
        click = false
      } else {
        this.selectRef.current.focus();
        click = true
      }
      this.setState({
        clicked: click
      })
    }
  };

  changeLangtext(e) {
    this.setState({ text: e.value });
  };

  render() {
    const languages = [
      { value: 'sv', label: 'sv' },
      { value: 'en', label: 'en' }
    ];
    
    const dropDownStyle = {
      control: (base) => ({
        ...base,
        border: 0,
        position: 'fixed',
        zIndex: '-1000',
        backgroundColor: 'transparant',
        boxShadow: 'transparant',
        right: "-1000px",
      }),
      menu: (base) => ({
        ...base,
        width: "max-content",
        minWidth: "40px",
        left: "-12px",
        marginTop: "10px",
        zIndex: 10000,
        color: "black"
      }),
		};

    return ( 
      <button
        //style={{display: 'inline-flex'}} , minWidth: '45px'
        style={{minWidth: '45px'}}
        onClick={this.onClick}
        className='btn btn-secondary btn-sm'
      >
      <p style={{margin: 'auto'}}>{this.state.text}</p>
      <Select
        ref={this.selectRef}
        components={{ IndicatorsContainer:() => null}}
        onChange={(e) => {i18next.changeLanguage(e.value); this.changeLangtext(e)}}
        options={languages}
        getOptionLabel={option => option.label}
        getOptionValue={option => option.value}
        styles={dropDownStyle}
        openMenuOnFocus={true}
        placeholder={''}
      />
      </button>   
    );
  }
}

export default LanguageSelector;
