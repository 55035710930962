import React, { Component } from 'react';
import {EXCEL_URL} from '../../helpers/constants/Constants';
import { Trans,  withTranslation } from 'react-i18next';


class ExcelExporter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lab: null
        }
    }

    NewOrderProperties = () => {
        const { t } = this.props;

        let data = [];

        this.props.modelData.Labs.forEach(lab => {
            var result = [];
            let onlyObjectsInDatabase = lab.properties.filter(x => {
                if (x.ExistInDatabase === true && x.calculateElement === true) {
                    return x;
                }
            });

            let sumOfCO2 = onlyObjectsInDatabase.reduce((a, b) => +a + +b.CO2, 0);
            let sumOfCost = onlyObjectsInDatabase.reduce((a, b) => +a + +b.Cost, 0);

            onlyObjectsInDatabase.reduce(function (res, value) {
                if (!res[value.designPartName]) {
                    res[value.designPartName] = {
                        name: value.designPartName,
                        designPartNameDisplayName: t('recipes.designPartName.'+ value.designPartName),
                        cost: 0,
                        category: value.category,
                        designElement :[],
                        CO2: 0,
                        CO2Percent: 0,
                        CostPercent: 0
                    };
                    result.push(res[value.designPartName])
                }
                res[value.designPartName].CO2 += value.CO2;
                res[value.designPartName].cost += value.Cost;
                res[value.designPartName].designElement.push(                    
                    {
                        elementName :  value.elementName,
                        updated : value.Updated,
                        instanceID: value.instanceID,
                        calculatedArea: value.calculatedArea
                    }
                    
                   )

                return res;
            }, {});

            result.forEach(designPartName => {
                designPartName.CO2Percent = 100 * designPartName.CO2 / sumOfCO2
                designPartName.CostPercent = 100 * designPartName.cost / sumOfCost
            
           //let tempArray = this.TestData(designPartName.designElement)
           //designPartName.designElement = [];
           //designPartName.designElement = tempArray;
            })       

            let dataSet = []
            result.reduce(function (res, value) {
                if (!res[value.category]) {
                    res[value.category] = {                       
                        category: value.category,
                        categoryDisplayname : t('recipes.designPartName.'+ value.category),
                        CO2: 0,
                        cost: 0,
                        designPartNames: [],
                        CO2Percent: 0,
                        CostPercent: 0

                    }
                    dataSet.push(res[value.category])
                }
                res[value.category].designPartNames.push(value);
                res[value.category].CO2 += value.CO2;
                res[value.category].cost += value.cost;
                res[value.category].CO2Percent += value.CO2Percent;
                res[value.category].CostPercent += value.CostPercent;
                return res;
            }, {});

            data.push({
                LabName: lab.name,
                data: dataSet,
                sumOfCO2: sumOfCO2,
                sumOfCost: sumOfCost
            })
        });
        return data;
    }
    
    onExport = () => {
        const { t } = this.props;
        if (this.props.modelData.BTA === '' || this.props.modelData.BTA === undefined || !this.props.modelData.BTA) {
            alert(t('excelExporter.0GFA'))
        } else {
            var exceldata = {
                "modellname": this.props.modelData.model.fileName,
                "bta": parseFloat(this.props.modelData.BTA),
                "a5": 18.55,
                "labs": this.NewOrderProperties()
            }
    
            console.log(exceldata)
            fetch(EXCEL_URL, {
                    credentials: 'include',
                    method: 'POST',
                    body: JSON.stringify(exceldata),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = this.props.modelData.model.fileName + '.xlsx';
                        a.click();
                    });
                }).catch((error) => {
                    console.error('Error:', error);
                });

        }
     }

    render() {
        return (
            <div  >
                <button className="btn btn-secondary btn-sm"  onClick={this.onExport} ><span className="icon icon-download"/> <Trans i18nKey="excelExporter.exportText"/></button>           
                {/* <button className="btn btn-sm btn-primary-green" onClick={this.onExport}>Exportera till Excel</button> */}
            </div>
        );
    }
}

//export default ExcelExporter;
const MyComponent = withTranslation()(ExcelExporter)
export default MyComponent;