import React, { Component } from 'react';
import { nrFormatter } from '../../helpers/helpers';
import { Trans,  withTranslation } from 'react-i18next';

class DataViewer extends Component {   

    // constructor(props) {
    //     super(props);      
    // }

    createList = () => {      
       let selectedLab = this.props.selectedLab.selectedLab;
       let sum = this.props.selectedLab.sum;
       let datatype = this.props.datatype;
       let unit = this.props.unit;
       let percentType = this.props.percentType;
       const selectedLabKeys = this.props.selectedLab.keys;

        const listItems = selectedLabKeys.map((key, index) => 

            selectedLab[key] ? //!!importent to handle undefined categories           

               <table key={index} className="table table-sm table-borderless table-striped">
                    <thead>
                        <tr className={`${key}`}>
                            <th><Trans i18nKey={"recipes.designPartCategoryName."+key}/> {unit === 'kg' ? 'CO2e' : <Trans i18nKey={"dataViewer.cost"}/>}</th>
                            {this.props.showValues === false ? null : 
                            <td>{nrFormatter(Math.round(selectedLab[key][datatype]))} {unit}/m<sup>2</sup></td>}                            
                            <td>{Math.round(selectedLab[key][percentType])} %</td> 
                        </tr>
                    </thead>
                    <tbody>
                        {
                            selectedLab[key].designPartNames.map((obj, index) =>
                                <tr key={'trTable_'+index.toString()}>
                                    <th><Trans i18nKey={"recipes.designPartName."+obj.name}/></th>
                                    {this.props.showValues === false ? null : 
                                    <td>{nrFormatter(Math.round(obj[datatype]))} {unit}</td>}                                 
                                    <td>{Math.round(obj[percentType])} %</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                : null
                    
                );
        return (
            <div>               
                <table className="table table-sm table-borderless table-striped">
                    <thead>
                        <tr>
                            {unit === 'kg' ? <th><span className="icon-md icon-cloud" /> <Trans i18nKey="dataViewer.totCO2e" components={[<sup />]}/></th> : <th><span className="icon-md icon-coins" /> <Trans i18nKey="dataViewer.totCost" components={[<sup />]}/></th>}
                            {this.props.showValues === false ? null: 
                            <td> {nrFormatter(Math.round(sum[datatype]))} {unit}/m<sup>2</sup></td>}   
                            <td>100%</td>
                        </tr>
                    </thead>
                </table>
                {listItems}
            </div>)
            ;
    }
    render() {  
        return (
            <>
                {this.createList()}
            </>
        );
    }
}

//export default DataViewer;

const MyComponent = withTranslation()(DataViewer)
export default MyComponent;