import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import "rc-tabs/assets/index.css";
/* global Autodesk, THREE */ // eslint-disable-line

const defaultTabKey = "1";

class ModelC3Properties extends Component {

  constructor(props) {
      super(props);
      this.state = {
          selected: {},
          tabKey: defaultTabKey,
          start: 0,
          properties: [],
          prevSelected: this.props.viewer.getSelection()[0],
          table: ''
      };

      this.propertiesToTable = this.propertiesToTable.bind(this);
  }


  propertiesToTable() {
    const selection = this.props.viewer.getSelection()[0].toString();
    var id = this.props.activeLab.properties.find(p => p.instanceID === this.props.viewer.getSelection()[0]);
    if (id) {
    return (
      <table
        id='C3selectedProperty'
        style={{ width: '100%', height: '100%', tableLayout: 'fixed' }}
        className='propertyTable'
      >
      <tbody>
        {Object.entries(id).map((value, idx) => (
          <tr
            id={`${selection}tr`}
            key={`${idx.toString()}`}
            style={{ width: '100%', height: '21px' }}
          >
            <td style={{ width: '28%', overflow: 'hidden' }} id='name'>
              {value[0]}
            </td>
            <td style={{ width: '46%', overflow: 'hidden' }} id='value'>
              <div className='cellValue' style={{ height: '21px' }}>
                {value[1] !== null && value[1] !== undefined? value[1].toString() : "null"}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
        );
      } else {
        return null
      }
  }
  
  render() {
    return (
      <div >
          {this.props.viewer.getSelection()[0] !== undefined ? this.propertiesToTable(): null}
      </div>
    );
  }
}
  
export default ModelC3Properties;

