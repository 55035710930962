// Axios GET

export const FETCH_FOLDER_BLACKLIST_PENDING = "FETCH_FOLDER_BLACKLIST_PENDING";
export const FETCH_FOLDER_BLACKLIST_SUCCESS = "FETCH_FOLDER_BLACKLIST_SUCCESS";
export const FETCH_FOLDER_BLACKLIST_ERROR = "FETCH_FOLDER_BLACKLIST_ERROR";

export const FETCH_FILTER_CATEGORY_SET_PENDING =
  "FETCH_FILTER_CATEGORY_SET_PENDING";
export const FETCH_FILTER_CATEGORY_SET_SUCCESS =
  "FETCH_FILTER_CATEGORY_SET_SUCCESS";
export const FETCH_FILTER_CATEGORY_SET_ERROR =
  "FETCH_FILTER_CATEGORY_SET_ERROR";

export const FETCH_FILE_WHITELIST_PENDING = "FETCH_FILE_WHITELIST_PENDING";
export const FETCH_FILE_WHITELIST_SUCCESS = "FETCH_FILE_WHITELIST_SUCCESS";
export const FETCH_FILE_WHITELIST_ERROR = "FETCH_FILE_WHITELIST_ERROR";

export const FETCH_GENERAL_SETTINGS_PENDING = "FETCH_GENERAL_SETTINGS_PENDING";
export const FETCH_GENERAL_SETTINGS_SUCCESS = "FETCH_GENERAL_SETTINGS_SUCCESS";
export const FETCH_GENERAL_SETTINGS_ERROR = "FETCH_GENERAL_SETTINGS_ERROR";

export const FETCH_PROJECTS_DATA_PENDING = "FETCH_PROJECTS_DATA_PENDING";
export const FETCH_PROJECTS_DATA_SUCCESS = "FETCH_PROJECTS_DATA_SUCCESS";
export const FETCH_PROJECTS_DATA_ERROR = "FETCH_PROJECTS_DATA_ERROR";

export const FETCH_REPORTS_CONFIG_PENDING = "FETCH_REPORTS_CONFIG_PENDING";
export const FETCH_REPORTS_CONFIG_SUCCESS = "FETCH_REPORTS_CONFIG_SUCCESS";
export const FETCH_REPORTS_CONFIG_ERROR = "FETCH_REPORTS_CONFIG_ERROR";

export const FETCH_SELECTED_PROJECT_DATA_PENDING =
  "FETCH_SELECTED_PROJECT_DATA_PENDING";
export const FETCH_SELECTED_PROJECT_DATA_SUCCESS =
  "FETCH_SELECTED_PROJECT_DATA_SUCCESS";
export const FETCH_SELECTED_PROJECT_DATA_ERROR =
  "FETCH_SELECTED_PROJECT_DATA_ERROR";

export const FETCH_SELECTED_PROJECT_ISSUES_DATA_PENDING =
  "FETCH_SELECTED_PROJECT_ISSUES_DATA_PENDING";
export const FETCH_SELECTED_PROJECT_ISSUES_DATA_SUCCESS =
  "FETCH_SELECTED_PROJECT_ISSUES_DATA_SUCCESS";
export const FETCH_SELECTED_PROJECT_ISSUES_DATA_ERROR =
  "FETCH_SELECTED_PROJECT_ISSUES_DATA_ERROR";

export const FETCH_TOKEN_DATA_PENDING = "FETCH_TOKEN_DATA_PENDING";
export const FETCH_TOKEN_DATA_SUCCESS = "FETCH_TOKEN_DATA_SUCCESS";
export const FETCH_TOKEN_DATA_ERROR = "FETCH_TOKEN_DATA_ERROR";

export const FETCH_USER_DATA_PENDING = "FETCH_USER_DATA_PENDING";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_ERROR = "FETCH_USER_DATA_ERROR";

// Axios POST

export const POST_PROJECT_DATA = "POST_PROJECT_DATA";
export const POST_PROJECT_DATA_SUCCESS = "POST_PROJECT_DATA_SUCCESS";
export const POST_PROJECT_DATA_ERROR = "POST_PROJECT_DATA_ERROR";

export const POST_CALCULATIONS = "POST_CALCULATIONS";
export const POST_CALCULATIONS_ERROR = "POST_CALCULATIONS_ERROR";
export const POST_CALCULATIONS_SUCCESS = "POST_CALCULATIONS_SUCCESS";

// Actions

export const GET_AGGREGATE_PROPERTIES = "GET_AGGREGATE_PROPERTIES";
export const GET_SELECTED_PROJECT = "GET_SELECTED_PROJECT";
export const GET_SELECTED_MODEL = "GET_SELECTED_MODEL";
export const GET_STATE_OBJ_PROPERTIES = "GET_STATE_OBJ_PROPERTIES";
export const SELECTED_MODEL_ARRAY = "SELECTED_MODEL_ARRAY";

// Read from .env
export const HUB_URL = process.env.REACT_APP_WEB_HUB_URL;
export const API_URL = process.env.REACT_APP_WEB_API_URL;
export const USER_URL = process.env.REACT_APP_USER_URL;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const ISSUE_URL = process.env.REACT_APP_ISSUE_URL;
export const HOST_URL = process.env.REACT_APP_HOST_URL;
export const REFRESHTACCESSTOKEN_URL =
  process.env.REACT_APP_REFRESHACCESSTOKEN_URL;

// Viewer
export const TOKEN_URL = process.env.REACT_APP_TOKEN_URL;

//RouteFinder

export const EXCEL_URL = process.env.REACT_APP_EXCEL_URL;
export const BIM360_LOCATION = process.env.REACT_APP_BIM360_LOCATION;
export const GETELEMENTSBYVERSIONIDS_URL =
  process.env.REACT_APP_GETELEMENTSBYVERSIONIDS;
export const GETELEMENTSBYDESIGNPARTNAME_URL =
  process.env.REACT_APP_GETELEMENTSBYDESIGNPARTNAME;
export const GETELEMENTSBYDESIGNPARTCATEGORYNAMES_URL =
  process.env.REACT_APP_GETELEMENTSBYDESIGNPARTCATEGORYNAMES;

//AZURE

export const REACT_APP_AZURESTORAGE = process.env.REACT_APP_AZURESTORAGE;

//Log

export const API_LOG = process.env.REACT_APP_LOG;
