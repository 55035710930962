import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import ParserSettings from '../../config/parsingSettings.json';
import {
	GetElementsBydesignPartName,
	GetElementsBydesignPartCategoryName,
} from '../labArea/DataConnector';
import { components } from 'react-select';
import { calculationMarkUp } from '../../helpers/helpers'
import RecipeCost from './RecipeCost';
import LayerGroup from './LayerGroup';
import RecipeSorting from './RecipeSorting';
import chroma from "chroma-js";

const CustomDropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <span className="btn icon icon-arrow" style={{ backgroundPosition: 'center center', padding: '10px', filter: props.isDisabled ? 'opacity(0.3)' : 'opacity(1)'}}/>
        </components.DropdownIndicator>
      )
    );
  };

function ComponentEditor(props) {
    const { t, i18n } = useTranslation();
    const { activeLab, updateParent, modelData, showValues, selectedMap, updateSelectedMap } = props;
    const [edit, setEdit] = useState(true);
    const [create, setCreate] = useState(false);
    const [add, setAdd] = useState(false);
    const [remove, setRemove] = useState(false);
    const categories = ParserSettings.parser.map(item => {return {category: item.category}});
    const [subCategories, setSubCategories] = useState([]);
    const [components, setComponents] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [cached, setCached ] = useState({});
    const [categoryValue, setCategoryValue] = useState(null);
    const [designPartNameValue, setDesignPartNameValue] = useState(null);
    const [elementNameValue, setElementNameValue] = useState(null);
    const [amount, setAmount] = useState(null);
    const [calculatedArea, setCalculatedArea] = useState(null);
    const [packages, setPackages] = useState([]);
    const [recipeSorting, setRecipeSorting] = useState(null);
    const [unit, setUnit] = useState(null);

    useEffect(() => {
        setPackages(modelData.model.Packages ? modelData.model.Packages.flatMap((p)=> p.active ? [p.PackageID] : []) : ['01'])
        setCached({})
        setComponents([])
    }, [JSON.stringify(modelData.model.Packages)]);

    useEffect(() => {
        setRecipeSorting(renderRecipeSorting())
    }, [components]);

    useEffect(() => {
        var parser = [...new Set(ParserSettings.lookupTable.map(x => {
            if (x.category === categoryValue) return x.designPartCategoryName
        }))].filter( Boolean );

        if (categoryValue !== null && showValues) {
            if (cached[parser[0]]) {
                if (JSON.stringify(subCategories) !== JSON.stringify(cached[parser[0]])) {
                    setSubCategories([...cached[parser[0]+'_parts']]);
                }
            } else {
                GetElementsBydesignPartCategoryName(parser, packages).then(dataBaseOptions => {
                    let designPartNames = [...new Set(dataBaseOptions.map(item => item.designPartName ))].map(item => ({ designPartName: item }));
                    if (JSON.stringify(subCategories) !== JSON.stringify(designPartNames)) {
                        setSubCategories(designPartNames);
                        setCached(Object.assign(cached, {[parser[0]+'_parts']: designPartNames}))
                    }
                })
            }
        } 
    }, [categoryValue]);

    useEffect(() => {
        if (designPartNameValue !== null && showValues) {
            if (cached[elementNameValue]) {
                if (JSON.stringify(components) !== JSON.stringify(cached[designPartNameValue])) {
                    var options = [...cached[designPartNameValue]]
                    var currentRecipe = selectedItems[0] ? options.find((item)=> item.elementVersionId === selectedItems[0].elementVersionId): null;
            
                    const groupBy = (arr, property) => {
                        return arr.reduce((acc, cur) => {
                          acc[cur[property]] = [...acc[cur[property]] || [], cur];
                          return acc;
                        }, {});
                    }
            
                    options = currentRecipe && currentRecipe.role ? groupBy(options, 'role')[currentRecipe.role] : options
                    options = currentRecipe && !currentRecipe.role ? groupBy(options, 'role')['null'] : options
                    setComponents(options);
                }
            } else {
                GetElementsBydesignPartName(designPartNameValue, packages).then(dataBaseOptions => {
                    if (JSON.stringify(components) !== JSON.stringify(dataBaseOptions)) {
                        var options = dataBaseOptions
                        var currentRecipe = selectedItems[0] ? options.find((item)=> item.elementVersionId === selectedItems[0].elementVersionId): null;
                
                        const groupBy = (arr, property) => {
                            return arr.reduce((acc, cur) => {
                              acc[cur[property]] = [...acc[cur[property]] || [], cur];
                              return acc;
                            }, {});
                        }
                
                        options = currentRecipe && currentRecipe.role ? groupBy(options, 'role')[currentRecipe.role] : options
                        options = currentRecipe && !currentRecipe.role ? groupBy(options, 'role')['null'] : options
                        setComponents(options);
                        setCached(Object.assign(cached, {[designPartNameValue]: dataBaseOptions}))
                    }
                })
            }
        } 
    }, [designPartNameValue]);

    useEffect(() => {

        const selection = selectedMap.map((id) => {
            if (id) return activeLab.properties.find((item)=> item.instanceID === id)
            }).filter( Boolean )

        setSelectedItems(selectedMap)
        setSelectedRecipe(null)
        if(selection.length === 0) {
            setSubCategories([])
            setComponents([])
        }

        const category = Array.from(new Set(selection.map((item) => item.revitCategory))).filter( Boolean )
        const designPartName = Array.from(new Set(selection.map((item) => item.designPartName))).filter( Boolean );
        const elementName = Array.from(new Set(selection.map((item) => item.elementName))).filter( Boolean )

        if (category.length === 1) {
            if (category[0] !== categoryValue) {
                setCategoryValue(category[0])
            }
        } else {
            setCategoryValue(null)
            if (category.length > 1) {
                setSubCategories([])
            }
        }

        if (designPartName.length === 1) {
            if (designPartName[0] !== designPartNameValue) {
                setDesignPartNameValue(designPartName[0])
            } 
        } else {
            setDesignPartNameValue(null)
            if (designPartName.length > 1) {
                setComponents([])
            }
        }

        if (elementName.length === 1) {
            if (elementName[0] !== elementNameValue) {
                setElementNameValue(elementName[0])                    
            }
        } else {
            setElementNameValue(null)
        }

        setUnit(Array.from(selection.map((item) => item.unit))[0])
    }, [selectedMap]);

    const handleConfirm = () => {
        const ids = activeLab.properties.map(a => a.instanceID);
        if (edit) {
            for (const element of activeLab.properties) {
                if (selectedMap.has(element.instanceID)) {
                    Object.assign(element, {
                        category: [...new Set(ParserSettings.lookupTable.map(x => {
                            if (x.category === categoryValue) return x.c3Category
                        }))].filter( Boolean )[0],
                        revitCategory: categoryValue,
                        elementName: selectedRecipe.elementName,
                        elementId: selectedRecipe.elementId,
                        databaseID: selectedRecipe.elementVersionId,
                        elementVersionId: selectedRecipe.elementVersionId,
                        isLatest: selectedRecipe.isLatestVersion,
                        co2Rating: null,
                        costRating: null,
                        CO2: element.calculatedArea * selectedRecipe.co2,
                        Cost: element.calculatedArea * selectedRecipe.unitCost * calculationMarkUp(selectedRecipe.elementVersionId, selectedRecipe.elementId),
                        elementCO2: selectedRecipe.co2,
                        elementUnitCost: selectedRecipe.unitCost,
                        designPartName: selectedRecipe.designPartName,
                        designPartCategoryName: selectedRecipe.designPartCategoryName,
                        unit: selectedRecipe.unit,
                        ExistInDatabase: true,
                        Updated: true
                    });
                }
            }
            updateSelectedMap(selectedMap)
        } else if (add) {
            const recipeMap = modelData.getActiveLab().recipeMap
            console.log('add')
            var newComponent = []
            for (const element of activeLab.properties) {
                if (element.databaseID !== selectedRecipe.elementId && selectedMap.has(element.instanceID)) {

                    var newId;
                    do {
                        newId = Math.floor(Math.random() * ids.length*100) + 1;
                    } while (ids.includes(newId));
                    if (recipeMap[element.instanceID]) {
                        var parentID = element.C3type.includes('layer') ? Number(element.C3type.split(':')[1]) : element.instanceID;
                        recipeMap[parentID].forEach((id) => {
                            recipeMap[id].push(newId)
                        })

                        recipeMap[newId] = [...recipeMap[parentID]]
                        recipeMap[newId].push(parentID)
                        recipeMap[parentID].push(newId)
                    } else {
                        recipeMap[element.instanceID] = [newId]
                        recipeMap[newId] = [element.instanceID]
                    }

                    newComponent.push({
                        ...element,
                        category: [...new Set(ParserSettings.lookupTable.map(x => {
                            if (x.category === categoryValue) return x.c3Category
                        }))].filter( Boolean )[0],
                        instanceID: newId,
                        revitType: selectedRecipe.elementName,
                        revitCategory: categoryValue,
                        elementName: selectedRecipe.elementName,
                        elementId: selectedRecipe.elementId,
                        databaseID: selectedRecipe.elementVersionId,
                        elementVersionId: selectedRecipe.elementVersionId,
                        isLatest: selectedRecipe.isLatestVersion,
                        unit: selectedRecipe.unit,
                        CO2: element.calculatedArea * selectedRecipe.co2,
                        co2Rating: null,
                        costRating: null,
                        Cost: element.calculatedArea * selectedRecipe.unitCost * calculationMarkUp(selectedRecipe.elementVersionId, selectedRecipe.elementId),
                        elementCO2: selectedRecipe.co2,
                        elementUnitCost: selectedRecipe.unitCost,
                        designPartName: selectedRecipe.designPartName,
                        designPartCategoryName: selectedRecipe.designPartCategoryName,
                        ExistInDatabase: true,
                        C3type: "layer:"+element.instanceID.toString(),
                        Updated: true
                    });
                    // selectedMap.add(newId)
                }
            }
            modelData.updateActivLab([...activeLab.properties,...newComponent]);
            // updateSelectedMap(new Set([newId]))
        } else if (create) {
            console.log('new')
            var newComponent = []

            for (let step = 0; step < amount; step++) {
                var newId;
                do {
                    newId = Math.floor(Math.random() * ids.length*100) + 999999;
                } while (ids.includes(newId));

                newComponent.push({
                    category: [...new Set(ParserSettings.lookupTable.map(x => {
                        if (x.category === categoryValue) return x.c3Category
                    }))].filter( Boolean )[0],
                    instanceID: newId,
                    revitCategory: categoryValue,
                    elementName: selectedRecipe.elementName,
                    elementId: selectedRecipe.elementId,
                    databaseID: selectedRecipe.elementVersionId,
                    elementVersionId: selectedRecipe.elementVersionId,
                    isLatest: selectedRecipe.isLatestVersion,
                    calculatedArea: calculatedArea,
                    CO2: calculatedArea * selectedRecipe.co2,
                    Cost: calculatedArea * selectedRecipe.unitCost * calculationMarkUp(selectedRecipe.elementVersionId, selectedRecipe.elementId),
                    elementCO2: selectedRecipe.co2,
                    elementUnitCost: selectedRecipe.unitCost,
                    designPartName: selectedRecipe.designPartName,
                    designPartCategoryName: selectedRecipe.designPartCategoryName,
                    unit: selectedRecipe.unit,
                    ExistInDatabase: true,
                    C3type: "CustomObject",
                    Updated: true,
                    calculateElement: true,
                });
                selectedMap.add(newId)
            }
            modelData.updateActivLab([...activeLab.properties,...newComponent]);
            updateSelectedMap(selectedMap)
        } else if (remove) {
            console.log('remove')
            activeLab.properties = activeLab.properties.filter(element => selectedMap.has(element.instanceID) && element.C3type !== "model" ? false : true);

            for (const element of activeLab.properties) {
                if (selectedMap.has(element.instanceID) && element.C3type === "model") {
                    Object.assign(element, {
                        elementName: t('C3Q.empty') + element.category,
                        elementId: null,
                        databaseID: null,
                        elementVersionId: null,
                        isLatest: null,
                        CO2: null,
                        Cost: null,
                        elementCO2: null,
                        elementUnitCost: null,
                        designPartName: null,
                        ExistInDatabase: null,
                        Updated: null,
                        unit: null
                    });
                }
            }
            updateSelectedMap(new Set())
        }

        updateParent()
        document.querySelectorAll('#C3Q-editor input').forEach((inputElement) => inputElement.value = '');
        setSelectedRecipe(null)
    };

    const setElementToNotCalculate = (value) => {
        activeLab.properties.forEach(element => selectedMap.has(element.instanceID) ? element.calculateElement = value : element.calculateElement);
        updateSelectedMap(selectedMap)
    }

    const dropDownStyle = (disabled) => {
        return {
            control: (provided) => ({
                ...provided,
                border: 0,
                borderTop: '0px !important',
                bottom: '7px',
                boxShadow: 0,
                background: disabled? '#F3F3F3' : 'white',
                whiteSpace: 'nowrap',
                overflow: 'auto'
            }),
            menu: (base) => ({
                ...base,
                width: "100%",
                minWidth: "100px",
                borderRadius: "0px",
                marginTop: "5px",
                marginLeft: "-11px",
                boxShadow: "rgba(0, 0, 0, 0.1) -2px 2px 6px 2px",
                border: '0.5px solid rgb(216, 216, 216)',
                'z-index': 2,
            }),
            container: (provided) => ({
                ...provided,
                background: disabled? '#F3F3F3' : 'white',
                height: '41px',
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                if (data.color === undefined) {
                    return {
                        ...styles,
                        backgroundColor: undefined,
                        color: undefined,
                    };
                } else {
                    const color = chroma(data.color);
                    return {
                        ...styles,
                        backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                            ? data.color
                            : isFocused
                            ? color.alpha(0.1).css()
                            : undefined,
                        color: isDisabled
                            ? "#ccc"
                            : isSelected
                            ? chroma.contrast(color, "white") > 2
                            ? "white"
                            : "black"
                            : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",
                
                        ":active": {
                            ...styles[":active"],
                            backgroundColor: !isDisabled
                            ? isSelected
                                ? data.color
                                : color.alpha(0.3).css()
                            : undefined
                            }
                    };
                }
            }
        }
    }

    const renderC3QTableCost = () => {
        return (
            <RecipeCost
				selectedInstance={selectedItems}
				activeLab={activeLab}
                showValues={showValues}
				cached={cached}
				selectedNameOption={selectedRecipe}
            />
        );
    }

    const renderLayerGroup = () => {
        return (
            <LayerGroup
				selectedInstance={selectedItems}
				activeLab={activeLab}
                showValues={showValues}
				modelData={modelData}
				selectedNameOption={selectedRecipe}
                updateSelectedMap={(e) => updateSelectedMap(e)}
            />
        );
    }

    const renderRecipeSorting = () => {
        return (
            <RecipeSorting
                selectedElementOptions={components}
            />
        );
    }

    return activeLab ? (
        <div style={{position: 'absolute', display: 'flex', flexFlow: 'column', right: '0px', height: '100%'}}>
            <nav className="btn-group d-none d-lg-flex" style={{height: '54px', width: '100%'}}>
                <button disabled={activeLab.main} title="Edit componenet" style={{zIndex: '1', borderBottom: '0.5px solid #d8d8d8', borderRight: '0.5px solid #d8d8d8', borderLeft: '0.5px solid #d8d8d8', borderTop: '0px', width: '100%'}} type="button" className={`btn btn-secondary btn-sm ${edit && !activeLab.main? "active" : ""}`} onClick={() => {setEdit(true); setCreate(false); setAdd(false); setRemove(false);}} >
                    <span className="icon icon-md icon-edit" style={{backgroundSize: 'contain', backgroundPosition: 'center', filter: edit && !activeLab.main ? 'invert(1)' : null}}></span>
                    <p>{t('C3Q.edit')}</p>
                </button>
                <button disabled={activeLab.main} title="Add to componenet" style={{zIndex: '1', borderBottom: '0.5px solid #d8d8d8', borderRight: '0.5px solid #d8d8d8', borderLeft: '0px', borderTop: '0px', width: '100%'}} type="button" className={`btn btn-secondary btn-sm ${add? "active" : ""}`} onClick={() => {setEdit(false); setCreate(false); setAdd(true); setRemove(false);}} >
                    <span className="icon icon-ms icon-add" style={{backgroundSize: 'contain', backgroundPosition: 'center', filter: add ? 'invert(1)' : null}}></span>
                    <p>{t('C3Q.add')}</p>
                </button>
                <button disabled={activeLab.main} title="Create componenet" style={{zIndex: '1', borderBottom: '0.5px solid #d8d8d8', borderRight: '0.5px solid #d8d8d8', borderLeft: '0px', borderTop: '0px', width: '100%'}} type="button" className={`btn btn-secondary btn-sm ${create ? "active" : ""}`} onClick={() => {setEdit(false); setCreate(true); setAdd(false); setRemove(false);}} >
                    <span className="icon icon-md icon-plus" style={{backgroundSize: 'contain', backgroundPosition: 'center', filter: create ? 'invert(1)' : null}}></span>
                    <p>{t('C3Q.create')}</p>
                </button>
                <button disabled={activeLab.main} title="Remove componenet" style={{zIndex: '1', borderBottom: '0.5px solid #d8d8d8', borderRight: '0px', borderLeft: '0px', borderTop: '0px', width: '100%'}} type="button" className={`btn btn-secondary btn-sm ${remove? "active" : ""}`} onClick={() => {setEdit(false); setCreate(false); setAdd(false); setRemove(true);}} >
                    <span className="icon icon-sm icon-trash" style={{backgroundSize: '26px', backgroundPosition: 'center', filter: remove ? 'invert(1)' : null}}></span>
                    <p>{t('C3Q.remove')}</p>
                </button>
            </nav>
            <div id="C3Q-editor">
            {!remove && !activeLab.main?
                <>
                    {recipeSorting}
                    <Select
                        name="category"
                        id="category-C3Q-selector"
                        className="form-control"
                        placeholder={
                            categoryValue ? categoryValue : 
                            (selectedItems.size === 0 ? t('C3Q.category') : t('BuildingMaterialSelector.severalCategoriesSelected')) }
                        onChange={(selectedOption) => {
                            setCategoryValue(selectedOption.category);
                            setDesignPartNameValue(null);
                            setElementNameValue(null);
                        }}
                        isDisabled={!showValues || remove || (!create && selectedItems.size === 0)}
                        components={{ DropdownIndicator: CustomDropdownIndicator }}
                        options={categories}
                        getOptionLabel={option => i18n.exists('recipes.category.'+ option.category) ? t('recipes.category.'+ option.category) : option.category }
                        getOptionValue={(option) => option.category}
                        value={categoryValue ? {category: categoryValue} : null}
                        styles={dropDownStyle(!showValues || remove || (!create && selectedItems.size === 0))}
                    />
                    <Select
                        name="designPartName"
                        id="designPartName-C3Q-selector"
                        className="form-control"
                        placeholder={
                            designPartNameValue ? designPartNameValue : 
                                (categoryValue ? t('BuildingMaterialSelector.severalPartsSelected') : t('C3Q.designPartName')) }
                        onChange={(selectedOption) => {
                            setDesignPartNameValue(selectedOption.designPartName);
                            setElementNameValue(null);
                        }}
                        isDisabled={!showValues || remove || subCategories.length === 0 || (!create && selectedItems.size === 0) ? true : false}
                        components={{ DropdownIndicator: CustomDropdownIndicator }}
                        options={subCategories}
                        value={designPartNameValue ? {designPartName: designPartNameValue} : null}
                        getOptionLabel={option => t('recipes.designPartName.'+ option.designPartName)}
                        getOptionValue={(option) => option.designPartName}
                        styles={dropDownStyle(!showValues || remove || subCategories.length === 0 || (!create && selectedItems.size === 0) ? true : false)}
                    />
                    <Select
                        name="elementName"
                        id="elementName-C3Q-selector"
                        className="form-control"
                        placeholder={
                            elementNameValue ? elementNameValue : 
                            (designPartNameValue ? t('BuildingMaterialSelector.severalComponentsSelected') : t('C3Q.elementName')) }
                        onChange={(selectedOption) => {
                            setElementNameValue(selectedOption.elementName);
                            setSelectedRecipe(selectedOption);
                            }}
                        isDisabled={!showValues || remove || components.length === 0 || (!create && selectedItems.size === 0)? true : false}
                        components={{ DropdownIndicator: CustomDropdownIndicator }}
                        options={components}
                        value={elementNameValue ? {elementName: elementNameValue} : null}
                        getOptionLabel={(option) => option.elementName}
                        getOptionValue={(option) => option.elementName}
                        styles={dropDownStyle(!showValues || remove || components.length === 0 || (!create && selectedItems.size === 0)? true : false)}
                        style={{borderTop: '0px', padding: '0px'}}
                    />
                </>
                    : null
                }
                {create  ?
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <div className="form-control" style={{display: 'flex', flexDirection: 'row', width: '100%', padding: "0px", margin: "0px"}}>
                            <input
                                type="text"
                                name="calculatedArea"
                                className="form-control"
                                placeholder={selectedRecipe? t('C3Q.writeHere') : (selectedItems.size === 1 && unit? t('C3Q.writeHere') : t('C3Q.defaultUnitPlaceholder'))}
                                onChange={(e) => setCalculatedArea(e.target.value)}
                                style={{minWidth: '210px', height: '39px', border: '0px', background: "transparent", paddingLeft: '23px'}}
                                onInput={(e) => {
                                    e.target.value = e.target.value
                                        .replace(/[^0-9.]/g, '')
                                        .replace(/(\..*?)\..*/g, '$1')
                                        .replace(/^0[^.]/, '0');
                                        setCalculatedArea(e.target.value);
                                }}
                            />
                            {selectedRecipe && selectedRecipe.unit ? <span style={{color: "gray", padding: "8px"}}>{ t('unit.'+selectedRecipe.unit) }</span> : null }
                            {!selectedRecipe && selectedItems.size === 1 && unit ? <span style={{color: "gray", padding: "8px"}}>{t('unit.'+unit)}</span> : null }
                        </div>
                        <input
                            type="text"
                            name="amount"
                            className="form-control"
                            placeholder={t('C3Q.nOfComponents')}
                            onChange={(e) => setAmount(e.target.value)}
                            style={{minWidth: '250px', height: '36px', paddingLeft: '23px'}}
                            onInput={(e) => {
                                e.target.value = e.target.value
                                    .replace(/[^0-9.]/g, '')
                                    .replace(/(\..*?)\..*/g, '$1')
                                    .replace(/^0/, "");
                                    setAmount(e.target.value);
                            }}
                        />
                    </div>
                : null
            }
            {!remove ? renderC3QTableCost() : null}
            {(edit || remove) &&  selectedMap.size === 1 &&  modelData.getActiveLab().recipeMap && modelData.getActiveLab().recipeMap[Array.from(selectedMap)[0]]? renderLayerGroup() : null}
            {add && modelData.getActiveLab().recipeMap? renderLayerGroup() : null}
            {activeLab.main ? null : remove ?
                <>
                    <div style={{marginTop: '1rem', paddingBottom: '1rem',}}>
                        <button style={{width: '50%'}} className="btn btn-secondary" onClick={()=>setElementToNotCalculate(true)} disabled={!showValues || activeLab.main || (selectedMap.size === 0 && remove) || (!selectedRecipe && !remove)}>{t('C3Q.calculate')}</button>
                        <button style={{width: '50%'}} className="btn btn-secondary" onClick={()=>setElementToNotCalculate(false)} disabled={!showValues || activeLab.main || (selectedMap.size === 0 && remove) || (!selectedRecipe && !remove)}>{t('C3Q.notCalculate')}</button>
                    </div>
                    <div style={{paddingBottom: '1rem',}}>
                        <button style={{width: '100%', background: selectedMap.size !== 0 ? "rgb(197 71 71)" : null}} className="btn btn-primary-green " onClick={handleConfirm} disabled={!showValues || activeLab.main || (selectedMap.size === 0 && remove) || (!selectedRecipe && !remove)}>{t('C3Q.remove')}</button>
                    </div>
                </>
                :
                <div style={{marginTop: '1rem', paddingBottom: '1rem',}}>
                    <button style={{width: '100%'}} className="btn btn-primary-green" onClick={handleConfirm} disabled={ activeLab.main || (selectedMap.size === 0 && remove) || (!selectedRecipe && !remove)}>{edit ? t('C3Q.edit') : (create ? t('C3Q.createComponent') :t('C3Q.addlayer') )}</button>
                </div>
            }
            {add ? renderLayerGroup : null}
        </div>
        </div>
    ) : null;
}

export default ComponentEditor;