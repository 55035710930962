import React, { useState, useEffect } from 'react';
import ParserSettings from '../../config/parsingSettings.json';
import { Trans, withTranslation } from 'react-i18next';

const FILTERS = {
  ALL: '0',
  IN_DB: '1',
  NOT_IN_DB: '2',
  UPDATED: '3',
  NOT_UPDATED: '4',
  INCALCULABLE: '5',
  CALCULABLE: '6',
};

function ModelFilter(props) {
  const { activeLab, filterChanged, filterValue, t, update, viewer } = props;
  const [selectedFilter, setSelectedFilter] = useState(FILTERS.ALL);
  const parsedProperties = activeLab.properties;

  useEffect(() => { filterValue(selectedFilter); updateModel(selectedFilter); }, [selectedFilter, JSON.stringify(activeLab.properties)]);
  useEffect(() => { setSelectedFilter(FILTERS.ALL) }, [activeLab]);

  function filterItems(array, filterKey, filterValue) {
    const categories = ParserSettings.parser.map(item => item.category);
    return [...new Set(array
      .filter(item => {
        item.filtered = false
        if (filterKey === 'ALL') {
          item.filtered = true
          return true
        } else if (filterKey === 'revitCategory') {
          if (categories.includes(item[filterKey]) === filterValue) item.filtered = true
          return categories.includes(item[filterKey]) === filterValue;
        } else {
          if (item[filterKey] === filterValue && categories.includes(item.revitCategory)) item.filtered = true
          return item[filterKey] === filterValue && categories.includes(item.revitCategory);
        }
      })
      .map(item => item)
    )];
  }

  function updateModel(value) {
    if (value === FILTERS.IN_DB || value === FILTERS.NOT_IN_DB) {
      update(filterItems(parsedProperties, 'ExistInDatabase', value === FILTERS.IN_DB));
    } else if (value === FILTERS.UPDATED || value === FILTERS.NOT_UPDATED) {
      update(filterItems(parsedProperties, 'Updated', value === FILTERS.UPDATED));
    } else if (value === FILTERS.INCALCULABLE || value === FILTERS.CALCULABLE) {
      update(filterItems(parsedProperties, 'revitCategory', value === FILTERS.CALCULABLE));
    } else {
      update(filterItems(parsedProperties, 'ALL', value === FILTERS.ALL));
    }
  }

  function onFilterChange(event) {
    let value = event || selectedFilter;
    if (isNaN(event) && event) value = event.target.value;
    viewer.clearSelection();
    setSelectedFilter(value);
    updateModel(value);
    filterChanged();
  }

  function createRadioInput(id, value, labelKey) {
    return (
      <div className={"custom-control custom-radio"} key={id}>
        <input
          type="radio"
          name="calculationFilter"
          id={id}
          value={value}
          className={"custom-control-input"}
          checked={selectedFilter === value}
          onChange={onFilterChange}
        />
        <label htmlFor={id} className={"custom-control-label"}><Trans i18nKey={labelKey} /></label>
      </div>
    );
  }

  return (
    <>
      {createRadioInput('everything', FILTERS.ALL, 'modelTree.all')}
      {createRadioInput('calculated', FILTERS.IN_DB, 'modelTree.calculatedComponents')}
      {createRadioInput('not-possible', FILTERS.NOT_IN_DB, 'modelTree.uncalculatedComponents')}
      {createRadioInput('changed', FILTERS.UPDATED, 'modelTree.changedComponents')}
      {createRadioInput('not_changed', FILTERS.NOT_UPDATED, 'modelTree.unchangedComponents')}
      {createRadioInput('incalculable', FILTERS.INCALCULABLE, 'modelTree.nonCalculableComponents')}
      {createRadioInput('calculable', FILTERS.CALCULABLE, 'modelTree.calculableComponents')}
    </>
  );
}

const MyComponent = withTranslation()(ModelFilter);
export default MyComponent;