import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const LayerGroup = ({selectedInstance, activeLab, showValues, modelData, selectedNameOption, updateSelectedMap}) => {
	const { t, i18n} = useTranslation();
    const [info, setInfo] = useState("");
	const [items, setItems] = useState([])

	useEffect(() => {
		if (selectedInstance.size === 0) {
			setInfo(t("recipes.other.noComponentSelected"))
			setItems([])
		} else if (selectedInstance.size === 1) {
			const recipeMap = modelData.getActiveLab().recipeMap
			setItems(recipeMap[Array.from(selectedInstance)[0]] ? [Array.from(selectedInstance)[0],...recipeMap[Array.from(selectedInstance)[0]]] : [])
			setInfo("")
		} else {
			setInfo(t("BuildingMaterialSelector.severalComponentsSelected"))
			setItems([])
		}

	}, [selectedInstance, JSON.stringify(modelData.getActiveLab().recipeMap)]);

	return (
		<div style={{
			border: '1px solid #d8d8d8',
			marginTop: '1rem',
			padding: '1rem',
			background: 'white',
		}}>
			<div>
				<h2>{t("layerGroup.layerGroup")}</h2>
				{info !== "" ? info : null}
				{items.length === 0 ? (info === "" ? t("layerGroup.noLayers") : ""):
					<>{items.map((item, index)=> {
						const matchingElement = activeLab.properties.find(
							(el) => el.instanceID === item
						);
						const newSelection = new Set();
						newSelection.add(item)
						if (matchingElement && matchingElement.elementName) {
							return <p key={index} style={{cursor: 'pointer', textDecoration: index === 0 ? 'underline' : null }} onClick={(e)=> updateSelectedMap(newSelection)}>{matchingElement.elementName + (matchingElement.C3type.includes('model') ? ' *' : '')}</p>
						}
					})}
					<p style={{fontStyle: 'italic', fontSize: '12px'}}>{"* " + t("layerGroup.viewInModel")}</p>
					</>
				}
			</div>
		</div>
	);
};

export default LayerGroup;