import chroma from "chroma-js";
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function RecipeSorting(props) {
    const { t, i18n} = useTranslation();
    const [sortingOptionsBy, setSortingOptionsBy] = useState('co2e_cost');
    const { selectedElementOptions } = props;

    useEffect(() => {
		switch (sortingOptionsBy) {
			case 'co2e':
				selectedElementOptions.sort(
					(elm1, elm2) => elm1.co2 - elm2.co2
				);
				break;
			case 'cost':
				selectedElementOptions.sort(
					(elm1, elm2) => elm1.unitCost - elm2.unitCost
				);
				break;
			case 'co2e_cost':
				selectedElementOptions.sort(
					(elm1, elm2) =>
						Math.sqrt(elm1.co2 * elm1.unitCost) -
						Math.sqrt(elm2.co2 * elm2.unitCost)
				);
				break;
		}
		let grades = Math.floor(131/selectedElementOptions.length)
		let grades2 = Math.floor(112/selectedElementOptions.length)
		selectedElementOptions.forEach((element, index) => {
			element.color = chroma(37 + grades * index, 143 - grades2 * index, 51).darken(0).brighten(0.3).hex();
		});
    }, [selectedElementOptions, sortingOptionsBy]);

	const sortingInformation = () => {
		if (sortingOptionsBy === 'co2e') {
			return t('BuildingMaterialSelector.sort.CO2')
		} else if (sortingOptionsBy === 'cost') {
			return t('BuildingMaterialSelector.sort.cost')
		} else {
			return t('BuildingMaterialSelector.sort.combo')
		}
	};
	
	return (
        <>
            <div id="material-selector-sorting" className="btn-group d-none d-block" >
                <p style={{ margin: '2px 0px 0px 0px' }}>{sortingInformation()}</p>
                <div style={{ margin: '5px 0px 10px 0px' }}>
                    <button
                        style={{ width: '33%' }}
                        id="co2e"
                        type="button"
                        className={`btn btn-secondary btn-sm ${ sortingOptionsBy === 'co2e' ? 'active' : '' }`}
                        onClick={(e) => setSortingOptionsBy(e.target.id)}
                    >
                        CO2e
                    </button>
                    <button
                        style={{ width: '34%' }}
                        id="co2e_cost"
                        type="button"
                        className={`btn btn-secondary btn-sm ${ sortingOptionsBy === 'co2e_cost' ? 'active' : '' }`}
                        onClick={(e) => setSortingOptionsBy(e.target.id)}
                    >
                        {t('BuildingMaterialSelector.combo')}
                    </button>
                    <button
                        style={{ width: '33%' }}
                        id="cost"
                        type="button"
                        className={`btn btn-secondary btn-sm ${ sortingOptionsBy === 'cost' ? 'active' : '' }`}
                        onClick={(e) => setSortingOptionsBy(e.target.id)}
                    >
                        {t('BuildingMaterialSelector.cost')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default RecipeSorting;