import React from 'react';
import { LOGIN_URL } from '../../helpers/constants/Constants';
import { Trans, withTranslation } from 'react-i18next';

class ReconnectSession extends React.Component {

  forgeSignIn = () => {
    fetch(LOGIN_URL)
      .then(res => res.text())
      .then((rootUrl) => {
        window.location.href = (rootUrl);//rootUrl; <-???
      })
      .catch(console.log);
  }

  render() {
    return (
      <div className = "login-page">
        <div className={"container login-page-bg-img"}>
          <div className={"card"}>
            <h1 className={'noneMobile'}> <Trans i18nKey="reconnectSession.header"/> </h1>
            <p><Trans i18nKey="reconnectSession.text"/> </p>
            <button

              onClick={this.forgeSignIn}
              className={"btn btn-primary-green"}
            ><Trans i18nKey="reconnectSession.buttonText"/></button>
          </div>
        </div>
      </div>
    );
  }
}

//export default ReconnectSession;

const MyComponent = withTranslation()(ReconnectSession)
export default MyComponent;