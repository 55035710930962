import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const RecipeCost = ({selectedInstance, activeLab, showValues, cached, selectedNameOption}) => {
	const { t, i18n} = useTranslation();
    const [co2RatingBars, setCo2RatingBars] = useState(['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']);
    const [costRatingBars, setCostRatingBars] = useState(['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']);
	const [co2RatingBarsRecipe, setCo2RatingBarsRecipe] = useState(['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']);
    const [costRatingBarsRecipe, setCostRatingBarsRecipe] = useState(['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']);
	const [first, setFirst] = useState(['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']);

	useEffect(() => {
		const selection = selectedInstance.map((id) => {
			if (id) return activeLab.properties.find((item)=> item.instanceID === id)
			}).filter( Boolean )
	
		const [firstSelecetion] = selection;
		setFirst(firstSelecetion)
	}, [selectedInstance]);

	const calcCost = (selected) => {
		let co2Rating = selected && selected.co2Rating ? selected.co2Rating : 0;
		let costRating = selected && selected.costRating ? selected.costRating : 0;
	
		if (selected && selected.designPartName && !selected.costRating) {
			let array = cached[selected.designPartName] || [];
			let co2Max = -Infinity;
			let costMax = -Infinity;
			array.forEach((recipe) => {
				co2Max = Math.max(co2Max, Number(recipe.co2));
				costMax = Math.max(costMax, Number(recipe.unitCost));
			});
	
			array.forEach((recpie) => {
				if (showValues) {
					recpie.co2Rating = Math.round(10 * (Number(recpie.co2)) / (co2Max));
					recpie.costRating = Math.round(10 * (Number(recpie.unitCost)) / (costMax));
					if (recpie.elementId === selected.elementId) {
						selected.co2Rating = recpie.co2Rating;
						selected.costRating = recpie.costRating;
						co2Rating = recpie.co2Rating;
						costRating = recpie.costRating;
					}
				} else {
					recpie.co2Rating = Math.floor(Math.random()*(10 + 1))
					recpie.costRating = Math.floor(Math.random()*(10 + 1))
					if (recpie.elementId === selected.elementId) {
						selected.co2Rating = recpie.co2Rating;
						selected.costRating = recpie.costRating;
						co2Rating = recpie.co2Rating;
						costRating = recpie.costRating;
					}
				}
			})
		};

		return { co2Rating: co2Rating, costRating: costRating}
	}

	useEffect(() => {
		const { costRating, co2Rating } = calcCost(first);
		setCostRatingBars(Array(10).fill("#fff").map((_, i) => (costRating < i + 1 ? "#fff" : "transparent")));
		setCo2RatingBars(Array(10).fill("#fff").map((_, i) => (co2Rating < i + 1 ? "#fff" : "transparent")));
	}, [first, JSON.stringify(cached), JSON.stringify(activeLab.properties)]);

	useEffect(() => {
		if (selectedNameOption && selectedNameOption !== null) {
			const { costRating, co2Rating } = calcCost(selectedNameOption)
			setCostRatingBarsRecipe(Array(10).fill("#fff").map((_, i) => (costRating < i + 1 ? "#fff" : "transparent")));
			setCo2RatingBarsRecipe(Array(10).fill("#fff").map((_, i) => (co2Rating < i + 1 ? "#fff" : "transparent")));
		}
	}, [selectedNameOption]);

	const calcValues = (e, selectedNameOption, first) => {
		let np = ' (';
		let value = null;
		let procent = null;

		if (selectedNameOption && first && first.elementCO2) {
			if (e === 'co2') {
				value = selectedNameOption.co2 - first.elementCO2;
				procent = 100 * (value / first.elementCO2);
			} else if (e === 'kr') {
				value = selectedNameOption.unitCost - first.elementUnitCost;
				procent = 100 * (value / first.elementUnitCost);
			}
		} else if (selectedNameOption) {
			value = e === 'co2' ? 0 : value
			value = e === 'kr' ? 0 : value
		}

		if (value === 0) {
			return ' ' + (e === 'kr' ? t("BuildingMaterialSelector.SEK")+'/': 'kg/')+unit;
		} else if (value === null) {
			return '';
		};

		np = value > 0 ? ' (+' : ' (';

		return np + Math.round(procent).toString() + '%)';
	};

	const costRound = (value) => {
		const cost = value < 1 ? (Math.round(value*100)/100).toString() : Math.round(value).toString()
		return cost
	}

	const unit = first && first.category === 'Stairs' ? t("BuildingMaterialSelector.unit") : "m²";

	const renderCurrentBuildingPartRecipe = (value) => {
		if (value) {
		  if (value.elementName === null) {
			return null;
		  } else {
			return value.elementName
		  }
		} else {
		  return t("recipes.other.noComponentSelected");
		}
	  };

	return (
		<>
			<div style={{
				border: '1px solid #d8d8d8',
				marginTop: '1rem',
				padding: '1rem',
				background: 'white',
			}}>
				<div id='table kr CO22'>
					{activeLab.main ?   <h1>{t("BuildingMaterialSelector.selectedComponent")}</h1> : null}
					{activeLab.main ?  renderCurrentBuildingPartRecipe(first) : t('BuildingMaterialSelector.currentRecipe') }
					<table className={"table table-sm"} style={{ border: '0', marginBottom: '0px' }}>
						<tbody id='test' className='test2'>
							<tr className='rating3' style={{ height: '20px', borderTop: '0', margin: '2px 2px 0px 7px',}}>
								{co2RatingBars.map((item, index) => (
									<td key={index + "3"} type="radio" name='rating3' style={{width: (100/costRatingBars.length).toString()+'%', padding: 0, height: '20px', borderLeft: index === 0 ? '0.5px solid #d8d8d8' : null, borderRight: '0.5px solid #d8d8d8', borderBottom: '0.5px solid #d8d8d8'}}>
										<label style={{ border: 'initial', background: item, margin: 0, height: '28px' }}></label>
									</td>
								))}
							</tr>
						</tbody>
					</table>

					{showValues &&
						<table className={"table table-sm"} style={{ borderBottom: '0', marginBottom: '0px' }}>
							<tbody id='test' className='test2'>
								<tr style={{ border:  'initial', background: 'initial'}}>
									<td style={{background: 'initial', border: 'initial', paddingLeft: '5px'}}>
										CO2e
									</td>
									<td style={{background: 'initial', border: 'initial', paddingLeft: '10px'}}>
										{first && Math.floor(first.elementCO2).toString() !== calcValues('co2', selectedNameOption, first) ? `${Number(first.elementCO2) < 1 ? (Math.round(first.elementCO2*100)/100).toString() : Math.round(first.elementCO2).toString()} kg/${unit}` : '-'}
									</td>
									<td style={{background: 'initial', border: 'initial', }}>
									</td>
								</tr>
							</tbody>
						</table>
					}

					<table className={"table table-sm"} style={{ border: '0', marginBottom: '0px' }}>
						<tbody id='test' className='test2'>
							<tr className='rating2' style={{ height: '20px', borderTop: '0', margin: '2px 2px 0px 7px', }}>
								{costRatingBars.map((item, index) => (
									<td key={index + "4"} type="radio" name='rating2' style={{ width: (100/costRatingBars.length).toString()+'%', padding: 0, height: '20px', borderLeft: index === 0 ? '0.5px solid #d8d8d8' : null, borderRight: '0.5px solid #d8d8d8', borderBottom: '0.5px solid #d8d8d8' }}>
										<label style={{ border: 'initial', background: item, margin: 0, height: '28px'}}></label>
									</td>
								))}
							</tr>
						</tbody>
					</table>

					{showValues &&
						<table className={"table table-sm"} style={{ borderBottom: '0', marginBottom: '0px' }}>
							<tbody id='test' className='test2'>
								<tr style={{background: 'initial', border: 'initial', }}>
									<td style={{background: 'initial', border: 'initial', paddingLeft: '5px'}}>
										<Trans i18nKey="BuildingMaterialSelector.SEKText" />
									</td>
									<td style={{background: 'initial', border: 'initial', paddingLeft: '10px' }}>
										{first && Math.floor(first.elementUnitCost).toString() !== calcValues('kr', selectedNameOption, first) ? `${Number(first.elementUnitCost) < 1 ? (Math.round(first.elementUnitCost*100)/100).toString() : Math.round(first.elementUnitCost).toString()} ${t("BuildingMaterialSelector.SEK")}/${unit}` : '-'}
									</td>
									<td style={{background: 'initial', border: 'initial', }}>
									</td>
								</tr>
							</tbody>
						</table>
					}
					{showValues ? null : <h5 style={{margin: '0px', paddingTop: '5px'}}>{t('BuildingMaterialSelector.demoGraph')}</h5>}
				</div>

				{selectedNameOption ?
					<div id='table kr CO22' style={{paddingTop: '0.5rem', borderTop: '1px solid #e2e0da'}}>
						{t('BuildingMaterialSelector.selecteRecipe')}
						<table className={"table table-sm"} style={{ border: '0', marginBottom: '0px' }}>
							<tbody id='test' className='test2'>
								<tr className='rating3' style={{ height: '20px', borderTop: '0', margin: '2px 2px 0px 7px',}}>
									{co2RatingBarsRecipe.map((item, index) => (
										<td key={index + "3"} type="radio" name='rating3' style={{ width: (100/costRatingBars.length).toString()+'%', padding: 0, height: '20px', borderLeft: index === 0 ? '0.5px solid #d8d8d8' : null, borderRight: '0.5px solid #d8d8d8', borderBottom: '0.5px solid #d8d8d8' }}>
											<label style={{ border: 'initial', background: item, margin: 0, height: '28px' }}></label>
										</td>
									))}
								</tr>
							</tbody>
						</table>

						{showValues &&
							<table className={"table table-sm"} style={{ borderBottom: '0', marginBottom: '0px' }}>
								<tbody id='test' className='test2'>
									<tr style={{ border:  'initial', background: 'initial'}}>
										<td style={{background: 'initial', border: 'initial', paddingLeft: '5px'}}>
											CO2e
										</td>
										<td style={{background: 'initial', border: 'initial', textAlign: 'center' }}>
											{costRound(selectedNameOption.co2)+` kg/${unit}`}
										</td>
										<td style={{background: 'initial', border: 'initial', textAlign: 'end', paddingRight: '10px'}}>
											{calcValues('co2', selectedNameOption, first)}
										</td>
									</tr>
								</tbody>
							</table>
						}

						<table className={"table table-sm"} style={{ border: '0', marginBottom: '0px' }}>
							<tbody id='test' className='test2'>
								<tr className='rating2' style={{ height: '20px', borderTop: '0', margin: '2px 2px 0px 7px', }}>
									{costRatingBarsRecipe.map((item, index) => (
										<td key={index + "4"} type="radio" name='rating2' style={{ width: (100/costRatingBars.length).toString()+'%', padding: 0, height: '20px', borderLeft: index === 0 ? '0.5px solid #d8d8d8' : null, borderRight: '0.5px solid #d8d8d8', borderBottom: '0.5px solid #d8d8d8' }}>
											<label style={{ border: 'initial', background: item, margin: 0, height: '28px' }}></label>
										</td>
									))}
								</tr>
							</tbody>
						</table>

						{showValues &&
							<table className={"table table-sm"} style={{ borderBottom: '0', marginBottom: '0px' }}>
								<tbody id='test' className='test2'>
									<tr style={{background: 'initial', border: 'initial', }}>
										<td style={{background: 'initial', border: 'initial', paddingLeft: '5px' }}>
											<Trans i18nKey="BuildingMaterialSelector.SEKText" />
										</td>
										<td style={{background: 'initial', border: 'initial', textAlign: 'center'}}>
											{costRound(selectedNameOption.unitCost)+` ${t("BuildingMaterialSelector.SEK")}/${unit}`}
										</td>
										<td style={{background: 'initial', border: 'initial', textAlign: 'end', paddingRight: '10px'}}>
											{calcValues('kr', selectedNameOption, first)}
										</td>
									</tr>
								</tbody>
							</table>
						}
						{showValues ? null : <h5 style={{margin: '0px', paddingTop: '5px'}}>{t('BuildingMaterialSelector.demoGraph')}</h5>}
					</div> : null
				}
			</div>
		</>
	);
};

export default RecipeCost;