import React from 'react';
import Table from 'react-bootstrap/Table';
import { nrFormatter } from '../../helpers/helpers';
import {GetElementsByIds} from './DataConnector'



class ValidatorView extends React.Component {

  constructor(props) {
    super(props);
    this.state =
    {
      IsnotLatestID : null
    }
  }


 componentDidMount = () =>
 {   
   
     this.groupIds();
 }

  async groupIds() {
    let allIDS = []
    this.props.modelData.Labs.forEach(lab => {
      allIDS.push(...lab.properties);
    });

  
     let distinctDatabaseIDs =  allIDS.map(item => item.databaseID)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(x => x !== null).filter(y => y !== '');
      
      

    // var distinctDatabaseIDs = this.props.modelData.Labs[0].properties.map(item => item.databaseID)
    //   .filter((value, index, self) => self.indexOf(value) === index)
    //   .filter(x => x !== null).filter(y => y !== '');


let result = [];

     await GetElementsByIds(distinctDatabaseIDs).then(data=>
      {

      

    let ids= data.reduce((acc, item) => {
      const { elementVersionId } = item;
      if (!acc[elementVersionId]) {
        acc[elementVersionId] = { 
          elementVersionId: elementVersionId,        
          isLatest : item.isLatestVersion
        };   
      }
    //  acc[category].count++;
  
      return acc;
    }, {}); 
 

       this.props.modelData.Labs.forEach(lab => {
         let a = lab.properties.reduce((acc, item) => {
           const { databaseID } = item;
           if (!acc[databaseID]) {
             acc[databaseID] = {
               databaseID: databaseID,
               labName: lab.name,
               isLatest: databaseID ? ids[databaseID].isLatest : null
             };
             result.push(acc[databaseID] );
           }
          
           return acc;
         }, {});
      
       });

 
 let test =    result.reduce((acc, item) => {
  const { labName } = item;
  if (!acc[labName] ) {
    acc[labName ] = {
      labName : labName ,      
      old: 0,
      new : 0
    };   
 
  }
  acc[labName ].new +=  item.isLatest ? 1 : 0
  acc[labName ].old +=  item.isLatest ? 0 : 1
  return acc;
}, {});

    this.setState({ IsnotLatestID: test })
  });
  }

 groupProps ()
 {

  const groups = this.props.modelData.Labs[0].properties.reduce((acc, item) => {
    const { category } = item;
    if (!acc[category]) {
      acc[category] = { 
        category: category,
        TotalArea : 0,
        count: 0,
        MissingAreaProp : 0,
        MissingRecipe : 0
      };   
    }
    acc[category].count++;
    acc[category].TotalArea += item.calculatedArea;
    acc[category].MissingAreaProp += item.calculatedArea === 0 ? 1 : 0;   
    acc[category].MissingRecipe +=  !item.ExistInDatabase ? 1 : 0;
    return acc;
  }, {}); 
  return groups;
 }



    render() 
    {
      const data = this.groupProps();
      const keys = Object.keys(data);
    
      return  (
        <div>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>Kategori</th>
            <th>Antal</th>
            <th>Summa Area</th>
            <th>Saknar Area</th>
            <th>Saknar Recept</th>
          </tr>
        </thead>
        <tbody>
       {keys.map((item, index) => (
      <tr key={index}>
        <td>{item}</td>
        <td>{data[item].count}</td>
        <td>{nrFormatter(Math.round(data[item].TotalArea)) }</td>
        <td>{data[item].MissingAreaProp}</td>
        <td>{data[item].MissingRecipe}</td>
      </tr>
    ))}
  </tbody>
 </Table>
 {this.state.IsnotLatestID  ? 
        <div> 
           {   Object.keys(this.state.IsnotLatestID).map((item, index) =>  (<p> {this.state.IsnotLatestID[item].labName} innehåller  {this.state.IsnotLatestID[item].old} gamla recept </p> )   )}
         </div> :<>''</>}
 </div>
  );
    

     
    }
  } export default ValidatorView