import React, { Component } from "react";
import {
  GetElementsBydesignPartName,
  GetElementsBydesignPartCategoryName,
} from "./DataConnector";
import parsingSetting from "../../config/parsingSettings.json";
import chroma from "chroma-js";
import { Trans, withTranslation } from "react-i18next";
import { calculationMarkUp } from "../../helpers/helpers";
import RecepieCost from "./RecepieCost";

import Select from "react-select";

/* global Autodesk, THREE */ // eslint-disable-line

class MaterialSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInstance: null,
      selectedInstances: [],
      data: [],
      selectedElementOptions: [],
      selectedDesignPartOption: [],
      selectedCategoryOption: null,
      selectedNameOption: null,
      showNotPossibleNoCalculate: false,
      showCurrentmaterialSection: false,
      showNewMaterialSection: false,
      sortingOptionsBy: "co2e_cost",
      changeCategory: false,
      cached: {},
      subCat: null,
      selectValue: null,
      hidePop: false,
      packages: [],
    };

    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.changePop = this.changePop.bind(this);
  }

  componentDidMount() {
    this.props.viewer.addEventListener(
      Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
      this.onAggregateSelectionChangedEvent
    );
  }

  componentWillUnmount() {
    this.props.viewer.removeEventListener(
      Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
      this.onAggregateSelectionChangedEvent
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const packages =
      this.props.modelData.model && this.props.modelData.model.Packages
        ? this.props.modelData.model.Packages.flatMap((p) =>
            p.active ? [p.PackageID] : []
          )
        : null;
    if (packages && this.state.packages.toString() !== packages.toString()) {
      this.setState({
        packages: packages,
        cached: {},
      });
    }
    if (prevProps !== null) {
      if (this.props.toolExtension && this.props.showValues !== false) {
        this.props.toolExtension.updateProps(this.props);
      }
      if (prevProps.activeLab.name !== this.props.activeLab.name) {
        this.handleEmptySelection();
        this.restoreDropDowns();
        this.props.viewer.clearSelection();
      }
    }
  }

  // getColor = (hex) => {
  // 	const colorHexStr = hex.replace('#', '0x');
  // 	const colorInt = parseInt(colorHexStr, 16);
  // 	const clr = new THREE.Color(colorInt);
  // 	return new THREE.Vector4(clr.r, clr.g, clr.b, clr.a);
  // };

  getElementOfCategory = (designPartCategoryName) => {
    var parser = parsingSetting.lookupTable.filter((x) => {
      return x.category === designPartCategoryName;
    });
    let designPartCategoryNames = [];
    parser.forEach((obj) => {
      if (!designPartCategoryNames.includes(obj.designPartCategoryName)) {
        designPartCategoryNames.push(obj.designPartCategoryName);
      }
    });
    GetElementsBydesignPartCategoryName(
      designPartCategoryNames,
      this.state.packages
    ).then((dataBaseOptions) => {
      let designPartNames = dataBaseOptions
        .map((item) => item.designPartName)
        .filter((value, index, self) => self.indexOf(value) === index);
      let res = [];
      designPartNames.forEach((designPartName) => {
        res.push({
          designPartName: designPartName,
        });
      });
      if (this.state.selectedDesignPartOption !== res) {
        this.setState({
          selectedDesignPartOption: res,
          data: dataBaseOptions,
        });
      }
    });
  };

  checkIfCategoryIsCalculable = (category) => {
    var parser = parsingSetting.parser.filter((x) => {
      return x.category === category;
    });
    if (parser.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  handleEmptySelection = () => {
    this.props.viewer.clearThemingColors();
    this.setState(
      {
        showNotPossibleNoCalculate: false,
        showCurrentmaterialSection: false,
        showNewMaterialSection: false,
        selectedInstance: null,
        selectedInstances: [],
        selectedElementOptions: [],
      },
      this.restoreDropDowns()
    );
  };

  handleCalculatedSelection = (selectedInstance) => {
    if (selectedInstance && !this.state.cached[selectedInstance.designPartName])
      this.addToCache(selectedInstance);
    this.setState({
      selectedInstance: selectedInstance,
      showNotPossibleNoCalculate: false,
      showCurrentmaterialSection: true,
      showNewMaterialSection: true,
    });
  };

  handleCalculableSelection = (selectedInstance) => {
    this.getElementOfCategory(selectedInstance.revitCategory);
    this.setState({
      showNotPossibleNoCalculate: false,
      selectedInstance: selectedInstance,
      showCurrentmaterialSection: false,
      showNewMaterialSection: true,
    });
  };

  handleNotPossibleToCalculate = () => {
    this.setState({
      showCurrentmaterialSection: false,
      showNewMaterialSection: false,
      showNotPossibleNoCalculate: true,
      selectedInstance: null,
    });
  };

  addToCache = async (selected) => {
    if (this.state.cached[selected.designPartName] === undefined) {
      // const dataBaseOptions = await GetElementsBydesignPartName(designPartName);
      await GetElementsBydesignPartName(
        selected.designPartName,
        this.state.packages
      ).then((dataBaseOptions) => {
        if (dataBaseOptions) {
          this.setState({
            cached: Object.assign(this.state.cached, {
              [selected.designPartName]: dataBaseOptions,
            }),
            selectedElementOptions: dataBaseOptions,
          });
        }
      });
    } else {
      if (
        JSON.stringify(this.state.selectedElementOptions) !==
        JSON.stringify(this.state.cached[selected.designPartName])
      ) {
        this.setState({
          selectedElementOptions: this.state.cached[selected.designPartName],
        });
      }
    }
  };

  multipleSelect = (selectedInstance) => {
    const filtered = this.props.modelData
      .getActiveLabProperties()
      .filter((id) => this.props.viewer.getSelection().includes(id.instanceID));
    this.setState({ selectedInstances: filtered });
  };

  selectProperty = (selectedInstanceID, selectingParent) => {
    if (!selectedInstanceID) return null;
    let found = false;
    let property = this.props.activeLab.properties.filter((property) => {
      if (property.instanceID === selectedInstanceID) {
        found = true;
        if (selectingParent) this.props.viewer.select([selectedInstanceID]);

        return property;
      }
    });

    if (!found) {
      const instanceTree = this.props.viewer.model.getData().instanceTree;
      const parentID = instanceTree.getNodeParentId(selectedInstanceID);

      return this.selectProperty(parentID, true);
    }

    return property;
  };

  onAggregateSelectionChangedEvent = () => {
    this.state.selectValue = null;
    this.state.selectedNameOption = null;
    this.state.subCat = null;
    var selection = this.props.viewer.getSelection();
    if (selection.length > 0) {
      let selectedProp = this.selectProperty(selection[0], false);
      if (selectedProp && selectedProp.length > 0) {
        this.multipleSelect(selectedProp[0]);
        if (this.checkIfCategoryIsCalculable(selectedProp[0].revitCategory)) {
          if (selectedProp[0].ExistInDatabase === true) {
            this.handleCalculatedSelection(selectedProp[0]);
          } else {
            this.handleCalculableSelection(selectedProp[0]);
          }
        } else {
          this.handleNotPossibleToCalculate();
        }
      } else {
        this.handleNotPossibleToCalculate();
      }
    } else {
      this.handleEmptySelection();
    }
  };

  handleClick = () => {
    if (this.state.selectedNameOption !== null) {
      this.state.selectedInstances.forEach((element) => {
        element.role = this.state.selectedNameOption.role;
        element.elementName = this.state.selectedNameOption.elementName;
        element.elementId = this.state.selectedNameOption.elementId;
        element.databaseID = this.state.selectedNameOption.elementVersionId;
        element.elementVersionId =
          this.state.selectedNameOption.elementVersionId;
        element.isLatest = this.state.selectedNameOption.isLatestVersion;
        element.calculateElement =
          this.state.selectedNameOption.calculateElement;
        element.CO2 =
          element.calculatedArea * this.state.selectedNameOption.co2;
        element.Cost =
          element.calculatedArea *
          this.state.selectedNameOption.unitCost *
          calculationMarkUp(
            this.state.selectedNameOption.elementVersionId,
            this.state.selectedNameOption.elementId
          );
        element.elementCO2 = this.state.selectedNameOption.co2;
        element.elementUnitCost = this.state.selectedNameOption.unitCost;
        element.designPartName = this.state.selectedNameOption.designPartName;
        element.designPartCategoryName =
          this.state.selectedNameOption.designPartCategoryName;
        element.ExistInDatabase = true;
        element.Updated = true;
        element.calculateElement = true;
        element.co2Rating = this.state.selectedNameOption.co2Rating;
        element.costRating = this.state.selectedNameOption.costRating;
      });
    }
    this.props.updateLabState();
  };

  addElement = () => {
    if (this.state.selectedNameOption !== null) {
      this.state.selectedInstances.forEach((element) => {
        let newElement = { ...element };
        newElement.role = this.state.selectedNameOption.role;
        newElement.elementName = this.state.selectedNameOption.elementName;
        newElement.elementId = this.state.selectedNameOption.elementId;
        newElement.databaseID = this.state.selectedNameOption.elementVersionId;
        newElement.elementVersionId =
          this.state.selectedNameOption.elementVersionId;
        newElement.isLatest = this.state.selectedNameOption.isLatestVersion;
        newElement.calculateElement =
          this.state.selectedNameOption.calculateElement;
        newElement.CO2 =
          newElement.calculatedArea * this.state.selectedNameOption.co2;
        newElement.Cost =
          newElement.calculatedArea *
          this.state.selectedNameOption.unitCost *
          calculationMarkUp(
            this.state.selectedNameOption.elementVersionId,
            this.state.selectedNameOption.elementId
          );
        newElement.elementCO2 = this.state.selectedNameOption.co2;
        newElement.elementUnitCost = this.state.selectedNameOption.unitCost;
        newElement.designPartName =
          this.state.selectedNameOption.designPartName;
        newElement.designPartCategoryName =
          this.state.selectedNameOption.designPartCategoryName;
        newElement.ExistInDatabase = true;
        newElement.Updated = true;
        newElement.calculateElement = true;
        newElement.co2Rating = this.state.selectedNameOption.co2Rating;
        newElement.costRating = this.state.selectedNameOption.costRating;
        //THIS IS NOT VALID REACT PATTERN. NEED CHANGE
        this.props.activeLab.properties.push(newElement);
      });
    }
    this.props.updateLabState();
  };

  restoreDropDowns = () => {
    this.props.viewer.clearThemingColors();
    this.setState({
      data: [],
      selectedElementOptions: [],
      selectedDesignPartOption: [],
      selectedCategoryOption: null,
      selectedNameOption: null,
    });
  };

  changePop() {
    this.setState({ hidePop: true });
  }

  renderCurrentBuildingPartRecipe = () => {
    const { t } = this.props;
    if (this.state.selectedInstance) {
      if (this.state.selectedInstance.elementName === null) {
        return t("recipes.other.selectSubcategoryFirst");
      }
    }
    if (this.state.showCurrentmaterialSection) {
      return this.state.selectedInstance.elementName.toString();
    }
    if (
      this.state.showNotPossibleNoCalculate &&
      this.props.activeLab.main !== true
    ) {
      return t("recipes.other.componentCannotBeChanged");
    } else if (
      this.state.showNotPossibleNoCalculate &&
      this.props.activeLab.main === true
    ) {
      return t("recipes.other.componentHasNoRecipe");
    } else {
      return t("recipes.other.noComponentSelected");
    }
  };

  handleCategoryChange() {
    var selection = this.props.viewer.getSelection();
    this.props.viewer.select(selection);
    this.setState({ changeCategory: !this.state.changeCategory });
  }

  setSortingOptionsBy = (e) => {
    this.setState(
      {
        sortingOptionsBy: e.target.id
          ? e.target.id
          : this.state.sortingOptionsBy,
      },
      () => {
        this.sortSelectedElementOptions(this.state.sortingOptionsBy);
      }
    );
  };

  sortSelectedElementOptions = (property) => {
    let sorted = null;
    switch (property) {
      case "co2e":
        sorted = this.state.selectedElementOptions.sort(
          (elm1, elm2) => elm1.co2 - elm2.co2
        );
        break;
      case "cost":
        sorted = this.state.selectedElementOptions.sort(
          (elm1, elm2) => elm1.unitCost - elm2.unitCost
        );
        break;
      case "co2e_cost":
        sorted = this.state.selectedElementOptions.sort(
          (elm1, elm2) =>
            Math.sqrt(elm1.co2 * elm1.unitCost) -
            Math.sqrt(elm2.co2 * elm2.unitCost)
        );
        break;
    }
    let grades = Math.floor(131 / sorted.length);
    let grades2 = Math.floor(112 / sorted.length);
    sorted.forEach((element, index) => {
      element.color = chroma(37 + grades * index, 143 - grades2 * index, 51)
        .darken(0)
        .brighten(0.3)
        .hex();
    });
    this.state.selectedElementOptions = sorted;
  };

  sortingInformation = () => {
    const { t } = this.props;
    const sortBy = this.state.sortingOptionsBy;
    if (sortBy === "co2e") {
      return t("BuildingMaterialSelector.sort.CO2");
    } else if (sortBy === "cost") {
      return t("BuildingMaterialSelector.sort.cost");
    } else {
      return t("BuildingMaterialSelector.sort.combo");
    }
  };

  selectCategoryDropDown = () => {
    const { t } = this.props;
    const { subCat, cached, data, sortingOptionsBy } = this.state;

    const value =
      subCat && subCat.designPartName
        ? { label: "test", key: 0, designPartName: subCat.designPartName }
        : [];

    var update = true;
    if (this.state.selectedInstance) {
      this.state.selectedDesignPartOption.forEach((ele) => {
        if (ele.designPartName === this.state.selectedInstance.designPartName)
          update = false;
      });
      if (update && this.state.selectedInstance.designPartName)
        this.getElementOfCategory(this.state.selectedInstance.revitCategory);
    }

    const handleChange = (select) => {
      if (select) {
        let elements = cached[select.designPartName] || [];
        if (elements.length === 0) {
          elements = data.filter(
            (element) => element.designPartName === select.designPartName
          );
          this.state.cached[select.designPartName] = elements;
        }
        this.setState(
          {
            selectedElementOptions: elements,
          },
          () => {
            this.sortSelectedElementOptions(sortingOptionsBy);
          }
        );
        this.state.subCat = select;
      }
    };

    var disabled =
      this.state.selectedDesignPartOption.length > 0 &&
      !this.props.activeLab.main
        ? false
        : true;

    return (
      <Select
        styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
        value={value}
        classNamePrefix={"typeahead-select"}
        onChange={(e) => {
          handleChange(e);
        }}
        options={
          this.props.showValues === false
            ? []
            : this.state.selectedDesignPartOption
        }
        getOptionLabel={(option) =>
          t("recipes.designPartName." + option.designPartName)
        }
        getOptionValue={(option) => option.designPartName}
        placeholder={t("recipes.other.selectSubcategory")}
        isDisabled={disabled}
        isClearable={false}
      />
    );
  };

  selectElementDropDown = (colourStyle) => {
    const { t } = this.props;
    const { selectedInstance, selectValue, sortingOptionsBy, subCat } =
      this.state;

    let placeholder = t("recipes.other.selectRecipe");
    if (this.state.showNotPossibleNoCalculate) {
      placeholder = this.props.activeLab.main
        ? t("recipes.other.componentHasNoRecipe")
        : t("recipes.other.componentCannotBeChanged");
    } else if (this.state.subCat === null) {
      placeholder = t("recipes.other.noComponentSelected");
      if (selectedInstance && selectedInstance.elementName === null) {
        placeholder = t("recipes.other.selectSubcategoryFirst");
        this.props.activeLab.properties.forEach((ele) => {
          if (this.props.viewer.getSelection().includes(ele.instanceID)) {
            if (ele.revitCategory !== selectedInstance.revitCategory) {
              value = [];
              placeholder = t("BuildingMaterialSelector.severalComponents");
            }
          }
        });
      }
    }

    var value = [];
    if (
      selectedInstance &&
      selectedInstance.designPartName &&
      subCat === null
    ) {
      if (this.props.showValues) this.addToCache(selectedInstance);
      value = [
        this.state.selectedElementOptions.find(
          (ele) => ele.elementName === selectedInstance.elementName
        ),
      ];
      if (this.props.showValues)
        this.sortSelectedElementOptions(sortingOptionsBy);
      if (value[0] === undefined) value = [selectedInstance];

      this.props.activeLab.properties.forEach((ele) => {
        if (this.props.viewer.getSelection().includes(ele.instanceID)) {
          if (ele.elementName !== selectedInstance.elementName) {
            value = [];
            placeholder = t("BuildingMaterialSelector.severalRecipes");
          }
          if (ele.revitCategory !== selectedInstance.revitCategory) {
            value = [];
            placeholder = t("BuildingMaterialSelector.severalComponents");
          }
        }
      });
    }

    var disabled = true;
    if (selectedInstance && this.state.selectedElementOptions.length !== 0) {
      disabled = selectedInstance.elementName ? false : disabled;
      parsingSetting.lookupTable.forEach((x) => {
        if (
          x.designPartCategoryName ===
            this.state.selectedElementOptions[0].designPartCategoryName &&
          selectedInstance.revitCategory === x.category
        )
          disabled = false;
      });
      disabled =
        placeholder === t("BuildingMaterialSelector.severalComponents")
          ? true
          : disabled;
    }

    this.state.subCat = null;
    const handleSelectElement = (select) => {
      if (select) {
        this.setState({
          selectedNameOption: select,
          selectValue: select,
        });
      }
    };

    var options = this.state.selectedElementOptions;
    var currentRecipe = this.state.selectedInstance
      ? options.find(
          (item) =>
            item.elementVersionId ===
            this.state.selectedInstance.elementVersionId
        )
      : null;

    const groupBy = (arr, property) => {
      return arr.reduce((acc, cur) => {
        acc[cur[property]] = [...(acc[cur[property]] || []), cur];
        return acc;
      }, {});
    };

    options =
      currentRecipe && currentRecipe.role && !this.state.changeCategory
        ? groupBy(options, "role")[currentRecipe.role]
        : options;
    options =
      currentRecipe && !currentRecipe.role && !this.state.changeCategory
        ? groupBy(options, "role")["null"]
        : options;

    return (
      <Select
        isClearable={false}
        styles={colourStyle}
        classNamePrefix={"typeahead-select"}
        onChange={(e) => handleSelectElement(e)}
        options={this.props.showValues === false ? [] : options}
        value={selectValue || value}
        getOptionLabel={(option) => option.elementName}
        getOptionValue={(option) => option.elementName}
        placeholder={placeholder}
        isDisabled={disabled}
        noOptionsMessage={() =>
          this.props.showValues === false
            ? t("BuildingMaterialSelector.requiresSubscription")
            : null
        }
      />
    );
  };

  renderTableCost = () => {
    return (
      <RecepieCost
        selectedInstance={this.state.selectedInstance}
        activeLab={this.props.activeLab}
        showValues={this.props.showValues}
        cached={this.state.cached}
        selectedNameOption={this.state.selectedNameOption}
      />
    );
  };


  render() {
    const { t } = this.props;
    const { visible, activeLab, showValues } = this.props;
    const { selectedInstance, changeCategory, sortingOptionsBy } = this.state;
    const tableCost = this.renderTableCost();
    const colourStyle = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        if (data.color === undefined) {
          return {
            ...styles,
            backgroundColor: undefined,
            color: undefined,
          };
        } else {
          const color = chroma(data.color);
          return {
            ...styles,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? data.color
              : isFocused
              ? color.alpha(0.1).css()
              : undefined,
            color: isDisabled
              ? "#ccc"
              : isSelected
              ? chroma.contrast(color, "white") > 2
                ? "white"
                : "black"
              : data.color,
            cursor: isDisabled ? "not-allowed" : "default",

            ":active": {
              ...styles[":active"],
              backgroundColor: !isDisabled
                ? isSelected
                  ? data.color
                  : color.alpha(0.3).css()
                : undefined,
            },
          };
        }
      },
    };

    if (!visible) return null;

    return (
      <div id="material-selector" style={{ width: "100%" }}>
        {activeLab.main === true ? (
          <div id="material-selector">
            <div style={{ marginTop: "-10px" }}>
              <div className="alert alert-success" role="alert">
                <button
                  type="button"
                  className="close close-success"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span>Close</span>
                </button>
                <h2>{t("BuildingMaterialSelector.startTextHeader")}</h2>
                <div style={{ whiteSpace: "pre-line" }}>
                  <p>{t("BuildingMaterialSelector.startText")}</p>
                </div>
              </div>
            </div>
            <section style={{ background: "white" }}>
              <h1>{t("BuildingMaterialSelector.selectedComponent")}</h1>
              <p>
                <strong>{this.renderCurrentBuildingPartRecipe(false)}</strong>
              </p>
              {tableCost}
            </section>
          </div>
        ) : (
          <div id="material-selector" style={{ width: "100%" }}>
            {this.state.hidePop ? null : (
              <div className="alert alert-success" role="alert">
                <button
                  type="button"
                  className="close close-success"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={this.changePop}
                >
                  <span>Close</span>
                </button>
                <h2>{t("BuildingMaterialSelector.testMaterial")} </h2>
                <div id="change-material-info">
                  <p>{t("BuildingMaterialSelector.testMaterialText")}</p>
                </div>
              </div>
            )}
            <section style={{ marginTop: "0px", background: "white" }}>
              <h1>{t("BuildingMaterialSelector.changeComponent")}</h1>
              {selectedInstance === null ? (
                <h3 style={{ marginTop: "15px", padding: "4px" }}>
                  {t("recipes.other.noComponentSelected")}
                </h3>
              ) : changeCategory === false &&
                selectedInstance.elementVersionId !== null ? (
                selectedInstance.designPartName === null ? (
                  <h3 style={{ marginTop: "15px", padding: "4px" }}>
                    {t("recipes.other.noComponentSelected")}
                  </h3>
                ) : (
                  <h3 style={{ marginTop: "15px", padding: "4px" }}>
                    {t(
                      "recipes.designPartName." +
                        selectedInstance.designPartName
                    )}
                  </h3>
                )
              ) : (
                <div style={{ margin: "10px 0px 10px 0px" }}>
                  {this.selectCategoryDropDown()}
                </div>
              )}
              {showValues === false ? null : (
                <div className="custom-control custom-checkbox mt-2">
                  <input
                    type="checkbox"
                    id="change-main"
                    value="change-main"
                    className="custom-control-input"
                    checked={changeCategory}
                    onChange={this.handleCategoryChange}
                  />
                  <label htmlFor="change-main" className="custom-control-label">
                    {t("BuildingMaterialSelector.ChangeSubcategory")}
                  </label>
                </div>
              )}
              <div
                id="material-selector-sorting"
                className="btn-group d-none d-block"
              >
                <p style={{ margin: "2px 0px 0px 0px" }}>
                  {this.sortingInformation()}
                </p>
                <div style={{ margin: "5px 0px 10px 0px" }}>
                  <button
                    style={{
                      paddingLeft: "10px",
                      marginLeft: "5px",
                      paddingRight: "10px",
                      height: "calc(1.8em + 0.38rem + 2px)",
                      width: "31%",
                    }}
                    id="co2e"
                    type="button"
                    className={`btn btn-secondary btn-xs ${
                      sortingOptionsBy === "co2e" ? "active" : ""
                    }`}
                    onClick={(e) => this.setSortingOptionsBy(e)}
                  >
                    CO2e
                  </button>
                  <button
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      height: "calc(1.8em + 0.38rem + 2px)",
                      width: "31%",
                    }}
                    id="co2e_cost"
                    type="button"
                    className={`btn btn-secondary btn-xs ${
                      sortingOptionsBy === "co2e_cost" ? "active" : ""
                    }`}
                    onClick={(e) => this.setSortingOptionsBy(e)}
                  >
                    {t("BuildingMaterialSelector.combo")}
                  </button>
                  <button
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      height: "calc(1.8em + 0.38rem + 2px)",
                      width: "31%",
                    }}
                    id="cost"
                    type="button"
                    className={`btn btn-secondary btn-xs ${
                      sortingOptionsBy === "cost" ? "active" : ""
                    }`}
                    onClick={(e) => this.setSortingOptionsBy(e)}
                  >
                    {t("BuildingMaterialSelector.cost")}
                  </button>
                </div>
              </div>
              {this.selectElementDropDown(colourStyle)}
              {/* {componentSelector} */}
              {tableCost}
            </section>
            <button
              onClick={this.handleClick}
              className="btn btn-primary-green mt-4"
            >
              {t("BuildingMaterialSelector.changeRecipe")}
            </button>
            <div>
              <button
                onClick={this.addElement}
                className="btn btn-primary-green mt-4"
              >
                +
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const MyComponent = withTranslation()(MaterialSelector);
export default MyComponent;
