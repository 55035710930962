import React from 'react';
import MainPage from '../../pages/main/MainPage';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { requestData } from '../../helpers/axios/Get';
import {
  HUB_URL,
  FETCH_USER_DATA_ERROR,
  FETCH_USER_DATA_PENDING,
  FETCH_USER_DATA_SUCCESS,
  FETCH_PROJECTS_DATA_PENDING,
  FETCH_PROJECTS_DATA_SUCCESS,
  FETCH_PROJECTS_DATA_ERROR,
  USER_URL
} from '../../helpers/constants/Constants';

class IfLoggedIn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectCheck: this.redirectCheck,
      redirectBack:  this.redirectBack,
    };
  }

  componentDidMount() {
    this.props.onrequestProjectsDataReducer({ withCredentials: true });
    this.props.onrequestUserDataReducer({ withCredentials: true });
  }

  renderRedirectBack = () => {
    if (this.props.user.error.data) {
      return this.redirectBack();
    }
    else if (this.props.user.data) {
      
      if (this.props.user.data.status === 500 || 
          this.props.user.data.status === 401) {
        return this.redirectBack();
      }
    }
  }

  redirectBack = () => {
    return <Redirect to='/' />;
  }

  redirectCheck = () => {
    if (!this.props.user || !this.props.user.data || this.props.user.data.length <= 0) return this.state.redirectBack();
    if (this.props.user.data.status === 500 || this.props.user.data.status === 401)    return this.state.redirectBack();
  }

  getUser = () => this.props.user.data;

  render() {
    if (this.props.user.data != null && typeof this.props.user.data.name === 'string') {
      return (
        <MainPage
          redirectCheck = {() => this.redirectCheck()}
          getUser = {this.getUser}
        />
      );
    } else {
      return (
        <section className={'loading'}>
          {this.renderRedirectBack()}
          <h1>Laddar...</h1>
        </section>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.requestUserDataReducer,
});

const mapDispatchToProps = (dispatch) => {

  return {
    onrequestProjectsDataReducer: (config) => dispatch(requestData(
      HUB_URL,
      {
        pending: FETCH_PROJECTS_DATA_PENDING,
        success: FETCH_PROJECTS_DATA_SUCCESS,
        error: FETCH_PROJECTS_DATA_ERROR,
      },
      config,
    )),
    onrequestUserDataReducer: (config) => dispatch(requestData(
      USER_URL,
      {
        pending: FETCH_USER_DATA_PENDING,
        success: FETCH_USER_DATA_SUCCESS,
        error: FETCH_USER_DATA_ERROR,
      },
      config,
    )),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IfLoggedIn);
