
import React, { Component } from 'react';
import ResultCharts from './ResultCharts';
import { withTranslation } from 'react-i18next';

class ResultChartCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    };

    calc = () => {
        let groupedBydesignPartName = this.groupBydesignPartName();
        let groupedByCategory = this.groupByCategory(groupedBydesignPartName);
        let data = {
            groupedByCategory: groupedByCategory,
            groupedByDesignPartName: groupedBydesignPartName,

        };    
        return data;
    }

    groupBydesignPartName = () => {
        const { t } = this.props;
        var result = [];
        let onlyObjectsInDatabase = this.props.activeLab.properties.filter(x => {
           if (x.ExistInDatabase === true && x.calculateElement === true) {               
                return x;
            }
        });
       
        let _self = this    
        onlyObjectsInDatabase.reduce(function (res, value) {          
            let originalElement= _self.props.modelData.Labs[0].properties.filter(element =>
                         {
                                 return element.instanceID === value.instanceID            
                         })[0]
                             
            if (!res[value.designPartName]) {
                res[value.designPartName] = {
                    name: value.designPartName,
                    designPartName: value.designPartName,
                    displayName :  t('recipes.designPartName.'+ value.designPartName),
                    cost: 0,
                    category: value.category,
                    CO2: 0,
                    baselineCO2: 0,
                    baselineCost: 0,
                    totalArea: 0,
                    ids: [] 
                };
                result.push(res[value.designPartName])
            }
            res[value.designPartName].CO2 += value.CO2;
            res[value.designPartName].cost += value.Cost;
            res[value.designPartName].totalArea += value.calculatedArea;
             res[value.designPartName].baselineCO2 += originalElement ? originalElement.CO2 : 0;
             res[value.designPartName].baselineCost += originalElement ? originalElement.Cost : 0;
            // res[value.designPartName].ids.push(value.categoryID);
            return res;
        }, {});       
        return result;
    }

    groupByCategory = (groupedBydesignPartName) => {
        const { t } = this.props;
        var result = [];
        groupedBydesignPartName.reduce(function (res, value) {
            if (!res[value.category]) {
                res[value.category] = {
                    name: value.category,
                    category: value.category, 
                    displayName :  t('recipes.designPartName.'+ value.category),
                    CO2: 0,
                    cost: 0,
                    baselineCO2: 0,
                    totalArea: 0,
                    baselineCost: 0,
                    ids: []
                };
                result.push(res[value.category])
            }
            res[value.category].CO2 += value.CO2;
            res[value.category].cost += value.cost;
            res[value.category].baselineCO2 += value.baselineCO2;
            res[value.category].baselineCost += value.baselineCost;
            res[value.category].ids.push(value.ids);
            return res;
        }, {});
        result.forEach(res => {          
            res.CO2 = Math.round(res.CO2);
            res.cost = Math.round(res.cost);
            res.baselineCO2 = Math.round(res.baselineCO2);
            res.baselineCost = Math.round(res.baselineCost);
        });
        return result;
    }

    handleBarClick = (e) => {
        if (!this.state.isInDesignPartNameMode) {
            let filtered = this.state.GroupedByDesignPartName.filter(x => {
                if (x.category === e.name) return x
            });
            this.setState({
                data: filtered,
                isInDesignPartNameMode: true
            });
        }
    }

    render() {
        const data = this.calc();
        return (
            <div id="result-charts" className={`${this.props.visible ? 'd-block' : 'd-none'}`}>
                <ResultCharts data={data} activeLab={this.props.activeLab} />
            </div>
        );
    }
}

const MyComponent = withTranslation()(ResultChartCalculator)
export default MyComponent;