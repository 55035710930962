import { GetElementsByIds } from "../components/labArea/DataConnector";

export async function labVersionHandler(data) {
  return await updateOldLab(data);
}

function updateOldLab(data) {
  var version =
    data.labVersion === undefined ? "-1" : data.labVersion.toString();
  console.log("Loaded Lab Version: " + version);

  // version -1 controll
  version = version === "-1" ? "0" : version;

  // version 0 controll
  data = version === "0" ? updateOldLab0(data) : data;
  version = version === "0" ? "0.1" : version;

  // // version 0.1 controll
  data = version === "0.1" ? updateOldLab01(data) : data;
  version = version === "0.1" ? "0.2" : version;

  // version 0.2 controll
  data = version === "0.2" ? updateOldLab02(data) : data;
  version = version === "0.2" ? "0.3" : version;

  // version 0.3 controll
  data = version === "0.3" ? updateOldLab03(data) : data;
  version = version === "0.3" ? "0.4" : version;

  return data;
}

async function updateOldLab03(data) {
  const uniqdatabaseID = [
    ...new Set(
      data.Labs.flatMap((lab) => lab.properties.map((obj) => obj.databaseID))
    ),
  ];
  const packages =
    data.model && data.model.Packages
      ? data.model.Packages.map((p) => p.PackageID)
      : ["01"];
      
  const dbData = await GetElementsByIds(uniqdatabaseID, packages);

  data.Labs.forEach((lab) => {
    lab.properties.forEach((obj) => {
      const matchingElement = dbData.find(
        (el) => el.elementVersionId === obj.databaseID
      );
      obj.unit = obj.unit ? obj.unit : (matchingElement ? matchingElement.unit : null);
    });
  });
  return data;
}

function updateOldLab02(data) {
  data.Labs.forEach((lab) => {
    lab.recipeMap = lab.recipeMap ? lab.recipeMap : {};
  });
  return data;
}

function updateOldLab01(data) {
  data.model.Packages = data.model.Packages ? data.model.Packages : [{"PackageName":"SWECO NYB","PackageID":"01","active":true}];
  data.needUpdateIds = true;
  data.Labs.forEach((lab) => {
    lab.recipeMap = lab.recipeMap ? lab.recipeMap : {};
    lab.properties.forEach((obj) => {
      obj.filtered = obj.filtered ? obj.filtered : true;
      obj.role = obj.role ? obj.role : "Generell";
      obj.revitCategory = obj.category;
      obj.C3type = obj.C3type ? obj.C3type : "model";
      if (obj.category === "Railings" || obj.category === "Stairs") {
        obj.category = "StairsAndItems";
      }
    });
  });

  return data;
}

async function updateOldLab0(data) {
  const uniqdatabaseID = [
    ...new Set(
      data.Labs.flatMap((lab) => lab.properties.map((obj) => obj.databaseID))
    ),
  ];
  const packages =
    data.model && data.model.Packages
      ? data.model.Packages.map((p) => p.PackageID)
      : ["01"];
  data.needUpdateIds = true;
  console.log("data.needUpdateIds", data.needUpdateIds);
  const dbData = await GetElementsByIds(uniqdatabaseID, packages);

  data.Labs.forEach((lab) => {
    lab.properties.forEach((obj) => {
      const matchingElement = dbData.find(
        (el) => el.elementVersionId === obj.databaseID
      );
      obj.isLatest = matchingElement ? matchingElement.isLatestVersion : null;
      obj.calculateElement = obj.calculateElement ? obj.calculateElement : true;
    });
  });

  return data;
}
