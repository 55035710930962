import React from 'react';
import Select from 'react-select';
 import {HOST_URL} from '../../helpers/constants/Constants'
//import {REACT_APP_AZURESTORAGE} from '../../helpers/constants/Constants'


class PublicModelNavigation extends React.Component {

  constructor(props) {
    super(props);
    document.body.classList.add('project-viewer');

    this.state = {
      show: true,
      selectedTypeOption: [],
      models: [
        // {
        //   fileName: 'cc_Generisk_Kontorskomplex.rvt',
        //   urn:  HOST_URL + '/models/cc_Generisk_Kontorskomplex/Resource/3D View/SwecoCarbonCostTool 1013131/SwecoCarbonCostTool.svf'
        // },
        {
          fileName: 'cc_Generisk_Förskola.rvt',
          urn:  HOST_URL+ '/models/cc_Generisk_Förskola/Resource/3D View/SwecoCarbonCostTool 1017138/SwecoCarbonCostTool.svf'
        },
        {
          fileName: 'cc_Generisk_Bostadshus.rvt',
          urn:  HOST_URL+ '/models/cc_Generisk_Flerbostadshus/Resource/3D View/SwecoCarbonCostTool 1227194/SwecoCarbonCostTool.svf'
        },
        {
          fileName: 'cc_Generisk_Paddelhall.rvt',
          urn:  HOST_URL+ '/models/cc_Generisk_Paddelhall/Resource/3D View/SwecoCarbonCostTool 1011323/SwecoCarbonCostTool.svf'
        }
      ]
    };
  }

  handleChange = (e) => {
    this.props.selectModel(e)
  }

  showHideModelNav = () => {
    this.props.showHideModelNav(false);
  }

  render() {
    return (
      <section id='model-navigation' className={`collapse ${false? 'show' : ''}`}>
        {/* <div id='project-finder-top'>
          <div id="projectFinder">
            <Select
              classNamePrefix={'typeahead-select'}
              value={this.state.selectedTypeOption}
              onChange={this.handleChange}
              options={this.state.models}
              getOptionLabel={option => option.fileName}
              getOptionValue={option => option.path}
              placeholder='Välj modell'
            />
          </div>
          <button type="button" className={`btn close nav-close ${this.state.show ? 'd-block' : 'd-none'}`} onClick={this.showHideModelNav} data-keyboard="true">
            <span className="sr-only">Hide navigation</span>
          </button>
        </div> */}
      </section>
    );
  };
}

export default PublicModelNavigation;