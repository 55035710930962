import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import "rc-tabs/assets/index.css";
import { withTranslation } from 'react-i18next';
/* global Autodesk, THREE */ // eslint-disable-line

const defaultTabKey = "1";

class ModelProperties extends Component {

  constructor(props) {
      super(props);
      this.state = {
          selected: {},
          tabKey: defaultTabKey,
          start: 0,
          properties: [],
          prevSelected: this.props.viewer.getSelection()[0],
          table: ''
      };

      this.buildProperties = this.buildProperties.bind(this);
      this.onTabClick = this.onTabClick.bind(this);
      this.propertiesToTable = this.propertiesToTable.bind(this);
      this.updateProperty =this.updateProperty.bind(this);
  }

  componentDidMount() {
    this.buildProperties()
  }

  componentDidUpdate() {
    if (!isNaN(this.props.viewer.getSelection()[0])) {
      if (this.state.prevSelected !== this.props.viewer.getSelection()[0]) {
        this.buildProperties()
      }
    }
  }

  onTabClick(key) {
      this.setState({
        tabKey: key
      });
  }

  buildProperties() {
    this.setState({
      prevSelected: this.props.viewer.getSelection()[0]
    })
    var selected = this.props.viewer.getSelection() //this.props.Array.from(this.props.selected)
    if(selected !== 0) {
      this.props.viewer.getProperties(selected[0], (result) => { 
        var array = result.properties
        let arrayMap = {dbId: result.dbId};

        array.forEach(element => {
            var makeKey = element.displayCategory;
            if(!arrayMap[makeKey]) {
                arrayMap[makeKey] = [];
            }
            arrayMap[makeKey].push(element);
        });
        this.setState ({
          properties: arrayMap
        })
      })
    }
  }

  expand(id, id2) {
    var table = document.getElementById(id);
    var main = document.getElementById(id2);
    if (table && table.style.display === 'block') {
      table.style.display = 'none'
      if (id2) main.style['border-bottom'] = '0px solid rgba(0, 0, 0, 0.29)';
    }
    else if (table) {
      table.style.display = 'block'
      if (id2) main.style['border-bottom'] = '0.5px solid rgba(0, 0, 0, 0.29)';
    }
  }

  propertiesToTable() {
    const selection = this.props.viewer.getSelection()[0].toString();
    return Object.entries(this.state.properties).map(([name, value]) => {
      if (value instanceof Object && value !== null) {
        const key = name === "Dimensions" ? "1" : `${name}-0`;
        return (
          <div id={key+'-header'} key={key+'-header'} style={{width: '100%'}}>
            <div onClick={() => {this.expand(key+'-table', key+'-header')}} className='quantityTableMain' >
              <p style={{margin: '0px'}}>{name}</p>
            </div>
            <div id={key+'-table'} style={{display: 'none'}}>
              {this.iterate(value, selection)}
            </div>
          </div>
        );
      }
      return null;
    });
  }
  
  iterate(element, selection) {
    const { t } = this.props;
    const onC = (e) => this.updateProperty(e);
    const [key, value] = Object.entries(element)[0];
  
    if (element.length === 1) {
      return (
        <div id='propertyTableQuantity'>
          <table
            id={`${selection}_table_${key.displayCategory}`}
            style={{ width: '100%', height: '100%', tableLayout: 'fixed' }}
            className='propertyTable'
          >
            <tbody>
              {Object.entries(value).map(([name, val], idx) => (
                <tr
                  id={`${selection}tr`}
                  key={`${idx.toString()}${name}`}
                  style={{ width: '100%', height: '21px' }}
                >
                  <td style={{ width: '35%', overflow: 'hidden' }} id='name'>
                    {name}
                  </td>
                  <td style={{ width: '35%', overflow: 'hidden' }} id='value'>
                    <div className='cellValue' style={{ height: '21px', display: 'flex', justifyContent: 'space-between'}}>
                      {this.props.viewer.getSelection().length === 1 ? val : '******'}
                      {this.props.changeQuantity === true && val !== null && val !== false && val !== '' && val !== 0 && val !== undefined && (isFinite(val) === true || Number.isInteger(Number(val))) ? (
                          <button type='button' onClick={(e, f) => onC(element)}>
                          {t("modelTree.choose")}
                          </button>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (  
        <div className='quantityTableSubGroup'> 
          {element.map(item =>    (    
              <div id={item.displayName.toString() + selection+'-header'} style={{width: '100%'}}>
                <div onClick={() => {this.expand(item.displayName.toString() + selection+'-table')}} style={{cursor: 'pointer', width: '100%'}}  className='quantityTableSub'>
                  <p style={{margin: '0px', paddingLeft: '20px'}}>{item.displayName}</p>
                </div>
                <div id={item.displayName.toString() + selection+'-table'} style={{display: 'none'}}>
                  {this.iterate([item])}
                </div>
            </div>)
          )}
        </div>
      )
    } 
  }

  updateProperty = (e) => {
    const { t } = this.props;
    var updated = false
    var selected = this.props.viewer.getSelection()
    var properties = this.props.modelData.getActiveLabProperties()
    selected.forEach((select) => {
        this.props.viewer.getProperties(select, (result) => {
        const table = result.properties.find(p => p.displayName === e[0].displayName);
        const unit = (table?.units || '').toLowerCase();
        const area = table?.displayValue || 1;
      
        let newArea = '';
        if (unit.includes('millimeter') || unit.includes('mm')) {
          newArea = area / 1000000;
        } else if (unit.includes('meter') || unit.includes('m2') || unit.includes('m^2')) {
          newArea = area;
        } else if (unit.includes('feet') || unit.includes('ft')) {
          newArea = (area * area) / 10.76364;
        } else {
          alert( t("modelTree.alert") + t("modelTree.editQuantity") + '"')
          //prompt('Please enter the value in m2:');
          return
        }
        properties.forEach(prop => {
          if (select === prop.instanceID && prop.calculatedArea !== newArea) {
            prop.calculatedArea = newArea;
            prop.CO2 = prop.elementCO2 * newArea;
            prop.Cost = prop.elementUnitCost * newArea;
            prop.ExistInDatabase = true;
            updated = true
          }
        });
      })
    })
    this.props.modelData.updateActivLab(properties);
    this.props.updateLabState()
    if (document.getElementById('quantityFilter')) {
      document.getElementById('quantityFilter').click()
      setTimeout(() => {
        document.getElementById('quantityFilter').click()
      }, 100)
      this.props.viewer.clearSelection()
      this.props.viewer.clearSelection()
    }
    this.props.updateFilter(false)
    this.props.goBack()
  }

  render() {
    const STYLE = `
    .rc-tabs-tab {
      font-size: 15px;
      font-family: "SwecoSans-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    }
    .rc-tabs-nav-list {
      /* display: flex; */
    }
    .rc-tabs-nav-operations {
      display: none;
    }

    .rc-tabs-tab-btn {
      padding-left: 6px;
      padding-right: 6px;
    }

    .rc-tabs-ink-bar {
      background: #9dd354;
    }

    .rc-tabs-tab-active {
      font-weight: normal;
      background-color: #3f3f42;
      color: white;
    }

    textarea {
      border: none;
      overflow: auto;
      outline: none;
      height: none
  
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
  
      resize: none; /*remove the resize handle on the bottom right*/
    }
    .cellValue {
      overflow: auto;
    }

    .cellValue::-webkit-scrollbar {
      display: none;
  }
    `;

    return (
      <>
        {/* <style dangerouslySetInnerHTML={{ __html: STYLE }} />
        <Tabs 
          //style={{minHeight: '240px'}}
          style={{height: 'auto'}}
          defaultActiveKey={defaultTabKey} 
          //moreIcon={false}
          //renderTabBar={renderTabBar}
          >
            {this.props.viewer.getSelection()[0] !== undefined ? this.propertiesToTable(): null}
        </Tabs> */}
        {this.propertiesToTable()}
        </>
    );
  }
}

const MyComponent = withTranslation()(ModelProperties)
export default MyComponent;