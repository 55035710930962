import { deepCopy } from "../../helpers/helpers";

export default class ModelData {
  constructor(properties, BTA, labs, model, updateid = false) {
    this.needUpdateIds = updateid;
    this.BTA = BTA;
    this.labVersion = "0.3";
    this.modelName = model ? `${model.fileName} - ${model.text}` : "";
    this.model = model ? model : {};
    this.Labs = labs
      ? labs
      : [
          {
            properties: properties,
            active: true,
            name: "Original",
            main: true,
            recipeMap: {},
          },
        ];

    this.loadedFromFile = properties === null;
    this.labNameCounter = this.Labs.length - 1;
  }

  static constructFromFile = (data) =>
    new ModelData(null, data.BTA, data.Labs, data.model, data.needUpdateIds);

  setForgeModel = (model) => {
    this.model = model;
    if (this.modelName === "")
      this.setModelName(`${model.fileName} - ${model.text}`);
  };

  setModelName = (name) => (this.modelName = name);

  getActiveLab = () => {
    let activeLab = null;
    this.Labs.forEach((lab) => {
      if (lab.active) activeLab = lab;
    });
    return activeLab;
  };

  getActiveLabProperties = () => {
    if (this.Labs.length <= 0) return null;
    let properties = this.getActiveLab().properties;
    return properties;
  };

  newLab = (lab) => {
    this.labNameCounter++;
    this.setActiveLab(
      this.Labs.push({
        properties: deepCopy(this.Labs[0].properties),
        active: false,
        name: `${lab} ${this.labNameCounter}`,
        main: false,
        recipeMap: {},
      }) - 1
    );
    return this;
  };

  removeLab = (idx) => {
    this.Labs.splice(idx, 1);
    return this;
  };

  setActiveLab = (idx) => {
    this.Labs.forEach((lab, i) => (lab.active = idx === i));
  };

  updateActivLab = (properties) => {
    this.Labs.forEach((lab) => {
      if (lab.active) lab.properties = properties;
    });
  };

  labNameExists = (name) => {
    let exists = false;
    this.Labs.forEach((lab) => {
      if (lab.name.toLowerCase() === name.trim().toLowerCase()) exists = true;
    });
    return exists;
  };

  setLabName = (idx, name) => (this.Labs[idx].name = name);
}
