import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { requestData } from '../../helpers/axios/Get';
import store from '../../helpers/store/Store';
import { Trans, withTranslation } from 'react-i18next';

import {
  FETCH_SELECTED_PROJECT_DATA_PENDING,
  FETCH_SELECTED_PROJECT_DATA_SUCCESS,
  FETCH_SELECTED_PROJECT_DATA_ERROR,
  API_URL,
} from '../../helpers/constants/Constants';
import {
  properties,
  getSelectedProject,
  getSelectedModelArray,
} from '../../helpers/actions/Actions';

class ProjectFinder extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
    };
  }

  nameShortening = (text) => {
    let maxLength = this.props.settings.maxProjectBrowserNameLength;
    let shortendName = text.length >= maxLength ? text.substring(0, maxLength) + '...' : text;
    return <span> {shortendName} </span>;
  };

  handleChange = selectedOption => {
    store.dispatch(properties(null));
    store.dispatch(getSelectedModelArray([]));

    this.setState(
      { selectedOption },
      () => {
        this.props.onrequestSelectedProjectDataReducer(this.state.selectedOption.id, { withCredentials: true, });
        this.props.getSelectedProject(this.state.selectedOption);
      });
  }

  componentDidMount() {
    this.props.redirectCheck();
  }
  
  render() {
    const { selectedOption } = this.state;
    return (
      <div id="projectFinder">
        <Select
          classNamePrefix={'typeahead-select'}
          value={selectedOption}
          onChange={this.handleChange}
          options={this.props.data}
          getOptionLabel={option => this.nameShortening(option.text)}
          getOptionValue={option => option.text}
          placeholder={<Trans i18nKey="projectFinder.selectProject"/>}
        />
        <button type="button" className={`btn close nav-close ${this.state.show ? 'd-block' : 'd-none'}`} onClick={this.showHide} data-keyboard="true">
          <span className="sr-only">Hide navigation</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.requestProjectsDataReducer.data,
  selected: state.getSelectedProjectReducer.data,
  settings: state.requestGeneralSettingsReducer.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSelectedProject: (selected) => {
      dispatch(getSelectedProject(selected))
    },
    onrequestSelectedProjectDataReducer: (id, config) => dispatch(requestData(
      `${API_URL}?id=${id}`,
      {
        pending: FETCH_SELECTED_PROJECT_DATA_PENDING,
        success: FETCH_SELECTED_PROJECT_DATA_SUCCESS,
        error: FETCH_SELECTED_PROJECT_DATA_ERROR,
      },
      config,
    )),
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(ProjectFinder);

const MyComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProjectFinder))

export default MyComponent;