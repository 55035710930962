import { 
  SELECTED_MODEL_ARRAY 
} from '../constants/Constants';
  
  const initalState = { data: [], }
  
  export const getModelArrayReducer = (state = initalState, action = {}) => {
    
    switch (action.type) {
      case SELECTED_MODEL_ARRAY: return { ...state, data: action.payload, }
      default: return state;
    }
  }