import React, { Fragment } from 'react';
import { readJSONFile, saveLabsToJSON } from '../../helpers/helpers';
import logo from '../../assets/sweco_black.svg';
import LanguageSelector from '../language/LanguageSelector';
import { Trans, withTranslation } from 'react-i18next';
import ProjectBrowser from '../projectBrowser/ProjectBrowser';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectBrowser: false,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const projectBrowserButton = document.getElementById('ProjectBrowserButton');
    const projectBrowserButtonText1 = document.getElementById('ProjectBrowserButtonText1');
    const projectBrowserButtonText2 = document.getElementById('ProjectBrowserButtonText2');
    if (
      e === 'hide' ||
      (this.state.projectBrowser &&
        !e.target.closest('#projectBrowser') &&
        e.target !== projectBrowserButton &&
        e.target !== projectBrowserButtonText1 &&
        e.target !== projectBrowserButtonText2)
    ) {
      this.setState({
        projectBrowser: false
      });
    }
  };

  handleSave = () => {
    const { t } = this.props;
    saveLabsToJSON(this.props.modelData, this.props.model.fileName.replace('.rvt', ''));
    window.alert(t('header.savedAlert'));
  }

  handleLoad = e => {
    if (e.target.files[0]) {
      readJSONFile(e.target.files[0], this.props.loadModelFromJSON);
    }
    e.target.value = '';
  }

  handleClick = () => {
    const { t } = this.props;
    if (window.confirm(t('header.openAlert'))) {
      document.querySelector('#file-input').click();
    };
  }

  renderSaveLoad = () => {
    return (
      <>
        <button
          type='button'
          className='btn btn-primary-green btn-sm'
          onClick={this.handleSave}
          disabled={!this.props.model}
        ><Trans i18nKey="header.saveLab"/></button>
        <button
        style={{marginRight: "0.5rem"}}
          className='btn btn-secondary btn-sm'
          onClick={this.handleClick}
        > <Trans i18nKey="header.openLab"/></button>
      </>
    );
  }

  projectBrowserActivate = () => {
    this.setState({
      projectBrowser: !this.state.projectBrowser
    })
  }

  renderProjectBrowser = () => {
    return (
      <div id='projectBrowser' className={`${this.state.projectBrowser ? 'expanded' : ''}`}>
        <ProjectBrowser
          selectModel={(model) => this.props.selectModel(model)}
          redirectCheck={() => this.props.redirectCheck()}
          handleClickOutside={(e) => this.handleClickOutside(e)}
          showValues = {this.props.showValues}
        />
      </div>
    )
  }

  render() {
    const characters = 40
    const modelName = this.props.model ? this.props.model.fileName.replace('.rvt', '').substring(0, characters) : "";
    const modelTitle = <h1 className='headerMini2' id="ProjectBrowserButtonText2" style={{ color: this.props.model ? 'black':'grey', cursor: 'pointer' }} onClick={(e) => {this.projectBrowserActivate()}} > 
      {this.props.model ? 
        modelName + (this.props.model.fileName.length > characters ? "..."  : '')+ (this.props.model.version ? " - v" + this.props.model.version.toString() : "")
        : <Trans i18nKey="header.select"/> }
      </h1>;
    const projectBrowser = this.renderProjectBrowser()
    return (
      <>
        {projectBrowser}
        <header>
          <div style={{display: 'flex', float: 'left', alignItems: 'flex-end'}}>
            <a href="https://www.sweco.se/" rel="noreferrer" target="_blank" style={{margin: '0'}}><img style={{height: '46px', float: 'left'}} src={logo} alt='logo' href="https://www.sweco.se/" rel="noreferrer" target="_blank"/></a>
            <h1 className='headerMini1' id="ProjectBrowserButtonText1" style={{ float: 'left', paddingLeft: '25px', cursor: 'pointer' }} onClick={() => this.projectBrowserActivate()} >{this.props.isPublic ? 'Carbon Cost Compass Demo -':'Carbon Cost Compass -' }&nbsp;</h1> 
            {modelTitle}
            <button
              type           = 'button'
              className      = 'btn icon icon-arrow'
              id             = 'ProjectBrowserButton'
              onClick        ={(e) => {this.projectBrowserActivate()}}
              style={{ marginBottom: '10px',transform: this.state.projectBrowser ? 'rotate(0deg)' : 'rotate(-90deg)', backgroundPosition: 'center'}}
            >
            </button>
          </div>
          <div style={{float: 'right', alignItems: 'baseline'}}>
            <a target="_blank" rel="noreferrer" href="https://www.sweco.se/transforming-society-together/digitalisering/carbon-cost-compass/"><span className="icon icon-sm icon-external-link" /><div className="aboutC3" style={{display: 'contents'}}><Trans className="aboutC3" i18nKey="header.aboutC3"/></div></a>
            {!this.props.isPublic ? this.renderSaveLoad() : null}
            <LanguageSelector
              className='btn btn-secondary btn-sm'
              style={{marginLeft: "10px", paddingLeft: "10px"}}
            />
              <input
                type='file'
                id='file-input'
                name='file-input'
                accept='application/json'
                hidden={true}
                onChange={this.handleLoad}
                style={{marginLeft: "10px"}}
            />
          </div>
        </header>
      </>
    );
  }
}

//export default Header;
const MyComponent = withTranslation()(Header)
export default MyComponent;