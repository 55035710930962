import {API_LOG} from '../../helpers/constants/Constants'
const axios = require('axios');

export const UsageLogger = (message, user, projectName, modelName) => {
    const json = 
        { userId: user.email, 
        scope: { projectName : projectName, 
                 modelName: modelName,
                 fullName: user.name},
        message: message
        };

    if (API_LOG !== undefined){
    axios.post(API_LOG, json, { withCredentials: true }).then(console.log("Usage logged!"));
    }else{
        console.error("Logging not working. API_LOG is undefined.");
    }

  
}