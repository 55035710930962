import React      from 'react';
import IfLoggedIn from '../components/ifLoggedIn/IfLoggedIn';
import Login      from '../pages/login/Login';
import Reconnect      from '../pages/reconnect/Reconnect';
import Demo       from '../pages/demo/Demo';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';

class Router extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route 
            exact 
            path = '/' 
            component = {Login}
          />
          <Route 
            exact 
            path = '/main' 
            render = { props => <IfLoggedIn {...props} />}
          />
          <Route
            exact
            path = '/demo'
            render = { props => <Demo {...props} />}
          />
          <Route
            exact
            path = '/reconnect'
            render = { props => <Reconnect {...props} />}
          />
          <Route render = {() => <Redirect to = '/' />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.requestUserDataReducer.data
});

export default connect(mapStateToProps)(Router);
