import { Component, Suspense } from 'react';
import { LOGIN_URL } from '../../helpers/constants/Constants';
import logo from '../../assets/sweco_black.svg';
import LanguageSelector from '../language/LanguageSelector';
import { withTranslation } from 'react-i18next';

class LoginAutodesk extends Component {

  forgeSignIn = () => {
    fetch(LOGIN_URL)
      .then(res => res.text())
      .then((rootUrl) => {
        window.location.href = (rootUrl);//rootUrl; <-???
      })
      .catch(console.log);
  }

  render() {
    const { t } = this.props;
    return (
      <div className={"container login-page-bg-img"}>
        <div style={{position:'fixed', top: '0', right: '0', zIndex: '1000'}}>
            <LanguageSelector/>
        </div>
        <div className={"card"}>
          <img style={{width: '130px'}}src={logo} alt='logo'/>
          <h1 className={'noneMobile'}>Carbon Cost Compass</h1>
          <h1 className={'mobile'} style={{fontSize: '1.5rem'}}>Carbon Cost Compass</h1>
          <p>{t('login.text')}</p>
          <a style={{fontSize: '0.875rem'}} target="_blank" rel="noreferrer" href="https://www.sweco.se/transforming-society-together/digitalisering/carbon-cost-compass/"><span className="icon icon-sm icon-external-link"></span>{t('login.about')}</a>
          <div className="mobile">
              <div className={"alert alert-warning mt-4"} role="alert">
              <i className={"far fa-exclamation-circle mr-2"}></i>
              <p><strong>{t('login.OBS')}</strong> {t('login.alert')}</p>
            </div>
          </div>
          <button
            onClick={this.forgeSignIn}
            className={"btn btn-primary-green"}
            id='loginBtn'
          >{t('login.loginButton')}</button>
          <a style={{fontSize: '0.875rem'}} className={'noneMobile'}
            href="/demo"
          >{t('login.demo')}</a>
        </div>
      </div>
    );
  }
}

const MyComponent = withTranslation()(LoginAutodesk)

// export default MyComponent;

//export default LoginAutodesk;

export default function App() {
  return (
    <Suspense fallback="loading">
	    <MyComponent />
    </Suspense>
  );
}