import { 
  FETCH_SELECTED_PROJECT_DATA_PENDING,
  FETCH_SELECTED_PROJECT_DATA_SUCCESS,
  FETCH_SELECTED_PROJECT_DATA_ERROR
} from '../constants/Constants';

const initialState = {
  pending: false,
  data:    [],
  error:   '',
}

export const requestSelectedProjectDataReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_SELECTED_PROJECT_DATA_PENDING: return { ...state, pending: true, };
    case FETCH_SELECTED_PROJECT_DATA_SUCCESS: return { ...state, pending: false, data: action.payload, };
    case FETCH_SELECTED_PROJECT_DATA_ERROR:   return { ...state, pending: false, error: action.payload, };
    default: return state;
  }
}