import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import LanguageSelector from '../language/LanguageSelector';

class Disclaimer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: true
        };

    }

    hide = () => {
        this.setState({ show: false });
    }

    render() {
        return (
            <>
            <div className={`${this.state.show ? 'd-flex' : 'd-none'}`} style={{position:'fixed', padding: '15px', right: '0', zIndex: '100000'}}>
                <LanguageSelector/>
            </div>
            <section id='disclaimer' className={`${this.state.show ? 'd-flex' : 'd-none'}`}>
                <div style={{whiteSpace: "pre-line"}}>
                    <h2><Trans i18nKey="popupDialogue.header"/></h2>
                    <p>
                    <Trans i18nKey="popupDialogue.text"/></p>
                    <button className={"btn btn-primary-green btn-sm"} onClick={this.hide}><Trans i18nKey="popupDialogue.understand"/></button>
                </div>
            </section>
            </>
        );
    }
}

//export default Disclaimer;

const MyComponent = withTranslation()(Disclaimer)
export default MyComponent;