import { 
  GET_AGGREGATE_PROPERTIES,
  GET_SELECTED_PROJECT,
  GET_SELECTED_MODEL,
  GET_STATE_OBJ_PROPERTIES,
  SELECTED_MODEL_ARRAY
} from '../constants/Constants';

export function properties(payload) {
  return { type: GET_AGGREGATE_PROPERTIES, payload, }
};

export const showObjProperties = (bool) => ({
  type: GET_STATE_OBJ_PROPERTIES, payload: bool,
});

export const getSelectedProject = (id) => ({
  type: GET_SELECTED_PROJECT, payload: id,
});

export const getSelectedModel = (item) => ({
  type: GET_SELECTED_MODEL, payload: item,
});

export const getSelectedModelArray = (array) => ({
  type: SELECTED_MODEL_ARRAY, payload: array,
});

export const reSet = () => ({
  type: 'RESET_APP', payload: [],
});