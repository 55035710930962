import React, { Component } from 'react';

import LabArea from '../../components/labArea/LabArea';
import Header from '../../components/header/Header';
import PublicModelNavigation from '../../components/modelNavigation/PublicModelNavigation';
import Disclaimer from '../../components/disclaimer/Disclaimer';
//import { GetModelThree } from '../../components/labArea/RevitModelDataExtractor';
import {GetModelData} from '../../components/labArea/StaticModelReader'

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublic: true,
      model: null,
      modelData: null,
      showDisclaimer: true,
      showModelNav: true,
      showValues: false,
      
      onLoadingComplete: null,
    };
  }

  selectModel = (model) => {
    this.setState({ model: model });
  };

  setModelData = data => {
    let modelData = data;
    modelData.setForgeModel(this.state.model);
    this.setState({ modelData: modelData, });
  }

  onModelLoaded = (viewer, onLoadingComplete) => {
    this.setState(
      { onLoadingComplete: onLoadingComplete, },
      this.loadModelData(viewer)
    );
  }

  loadModelData = viewer => {

    GetModelData(this.state.model.fileName).then(res   =>{ this.setModelData(res)      
    })
    .catch(err  => console.log(err))
    .finally(() => this.state.onLoadingComplete(viewer));   



    // GetModelThree(viewer)
    //   .then(res   => this.setModelData(res))
    //   .catch(err  => console.log(err))
    //   .finally(() => this.state.onLoadingComplete(viewer));
  }

  showHideModelNav = (show) => {
    this.setState({ showModelNav: show }, this.resizeEvent());
  }

  redirectCheck = () => {
    console.log('this is public');
  }

  resizeEvent = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
  }

  render() {
    return (
      <>
        <Disclaimer />
        <Header
          selectModel={(model) => this.selectModel(model)}
          model = {this.state.model}
          redirectCheck = {() => this.redirectCheck()}
          isPublic = {this.state.isPublic}
          showValues = {this.state.showValues}
        />
        <div id='ccms-content'>
          <PublicModelNavigation
            selectModel={(model) => this.selectModel(model)}
            showHideModelNav={this.showHideModelNav}
            showModelNav={this.state.showModelNav}
          />
          <LabArea
            model={this.state.model}
            modelData = {this.state.modelData}
            setModelData = {this.setModelData}
            onModelLoaded = {this.onModelLoaded}
            isPublic = {this.state.isPublic}
            redirectCheck = {() => this.redirectCheck()}
            showHideModelNav={this.showHideModelNav}
            showModelNav={this.state.showModelNav}
            resizeEvent = {this.resizeEvent}
            showValues = {this.state.showValues}
          />
        </div>
      </>
    );
  }
}

export default Demo;