import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';

function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
    const ref = useRef(null)
  
    useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate])
  
    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + " cursor-pointer"}
        {...rest}
      />
    )
  }

function C3QTable(props) {
    const { t, i18n} = useTranslation();
    const {activeLab, modelData, showValues, selectedMap, updateSelectedMap } = props;
    const [data, setData] = useState(activeLab  && activeLab.properties? activeLab.properties : null)
    const [columnVisibility, setColumnVisibility] = useState({})
    const [sorting, setSorting] = useState([])
    const [allChecked, setAllChecked] = useState(true)
    const [rowSelection, setRowSelection] = useState({})
    const filteredData = useMemo(() => { return data ? data.filter((row) => row.filtered !== false && row.visible ) : [] });
    
    const columns = useMemo( () => [
        {
            header:  t('C3Q.select'),
            id: 'select',
            header: ({ table }) => (
                <div className="px-1" onClick={()=>setAllChecked(table.getIsAllRowsSelected())}>
                    <IndeterminateCheckbox
                        {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({ row }) => (
                <div className="px-1" >
                    <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                    />
                </div>
            ),
        },
        {
            header:  t('C3Q.category'),
            footer: props => props.column.id,
            accessorKey: 'category',
            cell: info => i18n.exists("recipes.designPartCategoryName." + info.getValue()) ? t("recipes.designPartCategoryName." + info.getValue()) : info.getValue(),
        },
        {
            header: t('C3Q.designPartName'),
            footer: props => props.column.id,
            accessorKey: 'designPartName',
            cell: info => t("recipes.designPartName." + info.getValue()),
        },
        {
            header: t('C3Q.elementName'),
            footer: props => props.column.id,
            accessorKey: 'elementName',
            cell: info => info.getValue(),
        },
        {
            header: t('C3Q.CO2'),
            footer: props => props.column.id,
            accessorKey: 'CO2',
            cell: info => showValues ? isNaN(info.getValue()) ? info.getValue() : Math.round(info.getValue()*100)/100 : '---',
        },
        {
            header: t('C3Q.Cost'),
            footer: props => props.column.id,
            accessorKey: 'Cost',
            cell: info => showValues ? isNaN(info.getValue()) ? info.getValue() : Math.round(info.getValue()*100)/100 : '---',
        },
        {
            header: t('C3Q.calculatedArea'),
            footer: props => props.column.id,
            accessorKey: 'calculatedArea',
            cell: info => isNaN(info.getValue()) ? info.getValue() : Math.round(info.getValue()*100)/100,
        }
    ], [])

    const table = useReactTable({
        data: filteredData,
        columns,
        state: {
            columnVisibility,
            rowSelection,
            sorting
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getRowId: (row) => row.instanceID,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onRowSelectionChange: setRowSelection,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    })

    useEffect(() => {
        if (activeLab && activeLab.properties) {
            setData(activeLab.properties);
        }
    }, [activeLab.properties, activeLab, activeLab.properties.toString(), JSON.stringify(activeLab.properties)]);

    useEffect(() => {
        const prevSelection = 
            Set.from(table.getSelectedRowModel().rows
                .filter((row) => row.getIsSelected())
                .map((row) => row.original.instanceID))

        if (!areSetsEqual(prevSelection, selectedMap)) {
            const newSelection = new Set([...selectedMap]);
            const selection = {};
            newSelection.forEach((rowId) => {
                selection[rowId] = true;
            });
            setRowSelection(selection);
        }
    }, [selectedMap]);

    useEffect(() => {
            if (!allChecked) {
                // data.filter((row) => row.filtered !== false && row.visible )
                const ids = activeLab.properties.filter((row) => row.filtered !== false && row.visible );

                updateSelectedMap(Set.from(ids.map(a => a.instanceID)))
                setRowSelection(Set.from(ids.map(a => a.instanceID)))
            } else {
                updateSelectedMap(new Set())
                setRowSelection(new Set())
            }
    }, [allChecked]);
    
    function areSetsEqual(set1, set2) {
        if (set1.size !== set2.size) return false;
      
        for (const item of set1) {
            if (!set2.has(item)) {
                return false;
            }
        }
        return true;
    }

    return ( data ?
        <div id="C3Q-container">
            <div id="C3Q-toggle" >
                <div className="custom-control custom-checkbox m-1">
                    <input
                            type="checkbox"
                            className="custom-control-input"
                            id= "toggle-all"
                        {...{
                            type: 'checkbox',
                            checked: table.getIsAllColumnsVisible(),
                            onChange: table.getToggleAllColumnsVisibilityHandler(),
                        }}
                        />
                    <label
                        className="custom-control-label"
                        htmlFor="toggle-all"
                        >{' '}
                        {t('C3Q.toggleAll')}
                    </label>
                </div>
                {table.getAllLeafColumns().map(column => {
                    return (
                        <div key={column.id} className="custom-control custom-checkbox m-1">
                            <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id= {"toggle-"+ column.id.toString()}
                                {...{
                                    type: 'checkbox',
                                    checked: column.getIsVisible(),
                                    onChange: column.getToggleVisibilityHandler(),
                                }}
                                />
                            <label
                                className="custom-control-label"
                                htmlFor={"toggle-"+ column.id.toString()}
                                >{' '}
                                {t('C3Q.'+column.id)}
                            </label>
                        </div>
                    )
                })}
            </div>
            <div id="C3Q-table" >
                <table>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id} >
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} colSpan={header.colSpan} >
                                        {header.isPlaceholder ? null : (
                                            <div
                                                style={{cursor: header.column.getCanSort() ? 'pointer' : "", userSelect: header.column.getCanSort() ?  'none' : ""}}
                                            {...{

                                                onClick: header.column.getToggleSortingHandler()
                                            }}
                                            >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {{
                                                asc: <span className="btn icon icon-arrow" style={{transform: 'rotate(180deg)', backgroundPosition: 'center center'}}/>,
                                                desc: <span className="btn icon icon-arrow" style={{backgroundPosition: 'center center'}}/>
                                            }[header.column.getIsSorted()] ?? null}
                                            </div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr
                                key={row.id}
                                onClick={() => {row.getIsSelected() ? 
                                    setRowSelection(({...rowSelection, [row.id]: false})) : 
                                    setRowSelection({...rowSelection, [row.id]: true});
                                    var selection = new Set([...selectedMap]);
                                    row.getIsSelected() ? selection.delete(row.id): selection.add(row.id);
                                    updateSelectedMap(selection);
                                }
                                }
                                className={row.getIsSelected() ? 'selected-row' : ''}
                            >
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    : null
    )
}

export default C3QTable;