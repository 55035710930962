import React         from 'react';
import ReconnectSession from '../../components/reconnectSession/ReconnectSession';
import { connect }   from 'react-redux';

class Reconnect extends React.Component {
  render() {
    return (
      <div className = "login-page">
        <ReconnectSession />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Reconnect);
