import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList, ReferenceLine, ResponsiveContainer } from 'recharts';
import { Trans, withTranslation } from 'react-i18next';

import graphcolors from '../../config/graphColors.json';

class CompareLabStackedBarChart extends Component {
    // constructor(props) {
    //     super(props);
    // }

    renderBars = () => {
        let categories = ['Walls', 'Roofs', 'Doors', 'Windows', 'Floors']
        let bars = categories.map((key, index) =>
        (
            <>
                <Bar yAxisId="CO2" key={'CO2Bar_'+index.toString()} dataKey={'categories.' + key + '.CO2'} stackId="a" fill={graphcolors[key]} />
                <Bar yAxisId="Cost" key={'costBar_'+index.toString()} dataKey={'categories.' + key + '.cost'} stackId="a" fill={graphcolors[key]} />
            </>
        ));
        return bars;
    }

    handleResize = () => {
        if(document.getElementById('chart-table-container')) {
            document.getElementById('chart-table-container').style.width = (window.innerWidth - 400 - 340) + 'px';
            document.getElementById('chart-size-container').style.height = (document.getElementById("viewer-container").offsetHeight/2) - 56 - 20 - 70;
        }
    }

    
    printElement = () => {
        var elem = document.getElementById("printThis")
        var domClone = elem.cloneNode(true);
        
        var $printSection = document.getElementById("printSection");
        
        if (!$printSection) {
            $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);
        }
        
        $printSection.innerHTML = "";
        $printSection.appendChild(domClone);
        window.print();
    }

    render() {
        const data = this.props.data;
        const CustomBarCO2Label = (prop) => {
            return (
                <foreignObject x={prop.x + 5} y={prop.y - 30} height="30" width="30" >
                    <span className="icon icon-lg icon-cloud" />
                </foreignObject>)
        }
        const CustomBarCOSTLabel = (prop) => {
            return (
                <foreignObject x={prop.x + 5} y={prop.y - 30} height="30" width="30" >
                    <span className="icon icon-lg icon-coins" />
                </foreignObject>)
        }

        window.addEventListener('resize', this.handleResize)

        var categories = new Set();
        data.map(x => categories = new Set([...categories, ...new Set(Object.keys(x.categories))]))

        categories = Array.from(categories)

        return (
            <>
                <div id="chart-axis-labels">
                    <p >
                        <span className="icon icon-md icon-cloud" />
                        CO2e (kg/<Trans i18nKey="compareLabStackedBarChart.GFA"/>)
                    </p>
                    <button
                        style={{borderLeft: '1px solid gray'}}
                        className="btn btn-secondary btn-sm"
                        onClick={this.printElement}
                    >
                        <Trans i18nKey="compareLabStackedBarChart.print"/>
                    </button>
                    <p >
                        <span className="icon icon-md icon-coins" />
                        <Trans i18nKey="compareLabStackedBarChart.cost"/> (<Trans i18nKey="compareLabStackedBarChart.SEK"/>/<Trans i18nKey="compareLabStackedBarChart.GFA"/>)
                    </p>
                </div>
                <div id="chart-size-container" style={{width: "100%", height: this.props.height*1.4}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data} barSize={30}
                            margin={{ top: 30, right: 0, left: 0, bottom: -30 }}>
                            <CartesianGrid />
                            <XAxis dataKey="name" height={70} />
                            <YAxis orientation='left' yAxisId="CO2" />
                            <YAxis orientation='right' yAxisId="Cost" />

                            {categories.map((category, index) => {

                                return (
                                    <Bar key={index + "1chart"} yAxisId="CO2" dataKey={'categories.' + category + '.CO2'} stackId="a" fill={graphcolors[category]}>
                                        {index + 1 === categories.length ? <LabelList content={CustomBarCO2Label} position="top" /> : null}
                                    </Bar>)
                            })}

                            {categories.map((category, index) => {
                                return (
                                    <Bar key={index + "2chart"} yAxisId="Cost" dataKey={'categories.' + category + '.cost'} stackId="a" fill={graphcolors[category]} >
                                        {index + 1 === categories.length ? <LabelList content={CustomBarCOSTLabel} position="top" /> : null}
                                    </Bar>)
                            })}

                            {this.props.indicationValues ?
                                <>
                                    <ReferenceLine yAxisId="CO2" y={this.props.indicationValues.min} stroke="red" strokeDasharray="3 3" />
                                    <ReferenceLine yAxisId="CO2" y={this.props.indicationValues.max} stroke="red" strokeDasharray="3 3" />
                                </>
                                : null
                            }
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <div className='legend-container'>
                    {categories.map((category, index) => (
                        <div key={'div2_'+index.toString()}><div className={'legend ' + category} /><p><Trans i18nKey={"recipes.category." + ("StairsAndItems" === category ? category : category)}/></p></div>
                    ))}
                    <div><div className='legend indication-interval' /><p><Trans i18nKey="compareLabStackedBarChart.indicationIntervalCO2e"/></p></div>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th><Trans i18nKey="compareLabStackedBarChart.name"/></th>
                            <th><span className="icon icon-md icon-cloud" /><Trans i18nKey="compareLabStackedBarChart.totCO2e"/></th>
                            <th><span className="icon icon-md icon-coins" /><Trans i18nKey="compareLabStackedBarChart.totCost"/></th>
                            <th><span className="icon icon-md icon-cloud" /><Trans i18nKey="compareLabStackedBarChart.CO2ePerGFA"/></th>
                            <th><span className="icon icon-md icon-coins" /><Trans i18nKey="compareLabStackedBarChart.SEKperGFA"/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.tickData.map((obj, index) =>
                                <tr key={index + '3name'}>
                                    <th>{obj.name}</th>
                                    <td>{obj.sumOfCO2} <Trans i18nKey="compareLabStackedBarChart.tonne"/></td>
                                    <td>{obj.sumOfCost} k<Trans i18nKey="compareLabStackedBarChart.SEK"/></td>
                                    <td>{obj.sumOfCO2perBTA} kg/m<sup>2</sup></td>
                                    <td>{obj.sumOfCostperBTA} <Trans i18nKey="compareLabStackedBarChart.SEK"/>/m<sup>2</sup></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <div className="white-box">
                    <div id='printThis' className='printThis'>
                        <div style={{margin: '50px 35px 0px 35px'}}>
                            <p style={{float:'left'}}><span className="icon icon-md icon-cloud" />CO2e (kg/<Trans i18nKey="compareLabStackedBarChart.GFA"/>)</p>
                            <p style={{float:'right'}}><span className="icon icon-md icon-coins" /><Trans i18nKey="compareLabStackedBarChart.cost"/> (<Trans i18nKey="compareLabStackedBarChart.SEK"/>/<Trans i18nKey="compareLabStackedBarChart.GFA"/>)</p>
                        </div>
                        <div  className='printChart' style={{width: "100%", height: this.props.height*2}}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={data} barSize={30}
                                    margin={{ top: 30, right: 10, left: 15, bottom: -40 }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" height={70} />
                                    <YAxis yAxisId="CO2" height={70}/>
                                    <YAxis orientation='right' yAxisId="Cost" />
                                    {categories.map((category, index) => {
                                        return (
                                            <Bar key={index + "4print"} yAxisId="CO2" dataKey={'categories.' + category + '.CO2'} stackId="a" fill={graphcolors[category]}>
                                                {index + 1 === categories.length ? <LabelList content={CustomBarCO2Label} position="top" /> : null}
                                            </Bar>)
                                    })}

                                    {categories.map((category, index) => {
                                        return (
                                            <Bar key={index + "5ptint"} yAxisId="Cost" dataKey={'categories.' + category + '.cost'} stackId="a" fill={graphcolors[category]} >
                                                {index + 1 === categories.length ? <LabelList content={CustomBarCOSTLabel} position="top" /> : null}
                                            </Bar>)
                                    })}
                                    {this.props.indicationValues ?
                                        <>
                                            <ReferenceLine yAxisId="CO2" y={this.props.indicationValues.min} stroke="red" strokeDasharray="3 3" />
                                            <ReferenceLine yAxisId="CO2" y={this.props.indicationValues.max} stroke="red" strokeDasharray="3 3" />
                                        </>
                                        : null}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="print-style" >
                            <div><p></p></div>
                            <div><p></p></div>
                            {categories.map((category, index) => (
                                <div key={'div1_'+index.toString()}><div className={'legend ' + category} /><p><Trans i18nKey={"recipes.category." + category.toLowerCase()}/></p></div>
                            ))}
                            <div><div className='legend indication-interval' /><p><Trans i18nKey="compareLabStackedBarChart.indicationIntervalCO2e"/></p></div>
                            <div><p></p></div>
                            <div><p></p></div>
                        </div>
                        <div style={{margin: '40px 60px 0px 60px'}}>
                            <table className="table table-striped" >
                                <thead>
                                    <tr>
                                        <th><Trans i18nKey="compareLabStackedBarChart.name"/></th>
                                        <th><span/><Trans i18nKey="compareLabStackedBarChart.totCO2e"/></th>
                                        <th><span/><Trans i18nKey="compareLabStackedBarChart.totCost"/></th>
                                        <th><span/><Trans i18nKey="compareLabStackedBarChart.CO2ePerGFA"/></th>
                                        <th><span/><Trans i18nKey="compareLabStackedBarChart.SEKperGFA"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.tickData.map((obj, index) =>
                                            <tr key={index + '6tick'}>
                                                <th>{obj.name}</th>
                                                <td>{obj.sumOfCO2} <Trans i18nKey="compareLabStackedBarChart.tonne"/></td>
                                                <td>{obj.sumOfCost} k<Trans i18nKey="compareLabStackedBarChart.SEK"/></td>
                                                <td>{obj.sumOfCO2perBTA} kg/m<sup>2</sup></td>
                                                <td>{obj.sumOfCostperBTA} <Trans i18nKey="compareLabStackedBarChart.SEK"/>/m<sup>2</sup></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

//export default CompareLabStackedBarChart;

const MyComponent = withTranslation()(CompareLabStackedBarChart)
export default MyComponent;