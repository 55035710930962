import { 
  FETCH_FILE_WHITELIST_SUCCESS,
  FETCH_FILE_WHITELIST_PENDING,
  FETCH_FILE_WHITELIST_ERROR,
} from '../constants/Constants';

const initialState = {
  pending: false,
  data:    [],
  error:   '',
}

export const requestFileWhiteListReducer = (state = initialState, action) => {
  
  switch (action.type) {
      case FETCH_FILE_WHITELIST_PENDING: return { ...state, pending: true, }
      case FETCH_FILE_WHITELIST_SUCCESS: return { ...state, pending: false, data: action.payload, }
      case FETCH_FILE_WHITELIST_ERROR:   return { ...state, pending: false, error: action.payload, }
      default: return state;
  }
}
