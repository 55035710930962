import {
  API_URL,
  ISSUE_URL,
  USER_URL,
  TOKEN_URL
} from '../constants/Constants';

const axios = require('axios');

export const requestData = (url, action, config, callback) => (dispatch) => {
  
  dispatch({ type: action.pending });

  axios.get(url, config)
    .then(res => {
      dispatch({ type: action.success, payload: res.data });
      if (callback) callback();
    })
    .catch(err => {
      dispatch({ type: action.error, payload: err.response });
      if (callback) callback();
    });
}

export const axiosGet = (url, config, callbackSuccess, callbackFail) => {
  
  axios.get(url, config)
    .then(res  => { if (callbackSuccess) callbackSuccess(res); })
    .catch(err => { if (callbackFail)    callbackFail(err); });
}

export const getForgeToken = (onGetAccessToken) => {

  axios.get(TOKEN_URL, { withCredentials: true })
    .then(data => onGetAccessToken(data.data.token, null))
    .catch(console.log);
}

export const getHubChildStruct = (id, config) => {
  return axios.get(`${API_URL}?id=${id}`, config);
}

export const getIssues = (projectId, config) => {
  return axios.get(ISSUE_URL + projectId, config);
}

export const getUserProfile = (config) => {
  return axios.get(USER_URL, config);
}