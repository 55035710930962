import { 
  FETCH_PROJECTS_DATA_PENDING,
  FETCH_PROJECTS_DATA_SUCCESS,
  FETCH_PROJECTS_DATA_ERROR,
} from '../constants/Constants';

const initialState = {
  pending: false,
  data:    [],
  error:   '',
}

export const requestProjectsDataReducer = (state = initialState, action) => {

  switch (action.type) {
      case FETCH_PROJECTS_DATA_PENDING: return { ...state, pending: true, };
      case FETCH_PROJECTS_DATA_SUCCESS: return { ...state, pending: false, data: action.payload, };
      case FETCH_PROJECTS_DATA_ERROR:   return { ...state, pending: false, error: action.payload, };
      default: return state;
  }
}