import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
/* global Autodesk, THREE */ // eslint-disable-line

function SelectInModel(props) {
	const { selectedMap, viewer, activeLab, modelData, updateSelectedMap } = props;
	const { t, i18n } = useTranslation();
	const [selection, setSelection] = useState(new Set([...selectedMap]));

	useEffect(() => {

		viewer.addEventListener(
			Autodesk.Viewing.SELECTION_CHANGED_EVENT,
			handleSelectionChange
		);

		return () => {
			viewer.removeEventListener(
				Autodesk.Viewing.SELECTION_CHANGED_EVENT,
				handleSelectionChange
			);
		};
	}, []);

	useEffect(() => {
		if (!areSetsEqual(selection, selectedMap)) {
			updateSelectedMap(selection);
		}
	}, [selection]);

	useEffect(() => {
		const currentSelection = viewer.getSelection();
		if (!areSetsEqual(currentSelection, selectedMap)) {
			viewer.select(Array.from(selectedMap));
		}
	}, [selectedMap]);

	const handleSelectionChange = (e) => {
		// console.log(new Set(e.dbIdArray))

		const ids = new Set(viewer.getSelection())


		if (!areSetsEqual(ids, selectedMap)) {
			const currentSelection = e.dbIdArray;
			currentSelection.forEach((currentS) =>
				selectProperty(currentS, false)
			);
			const newSelection = new Set(viewer.getSelection());
			setSelection(newSelection);
		} else if (new Set(e.dbIdArray).size === 0 && selectedMap.size === 0) {
			setSelection(new Set());
		}
	};

	function areSetsEqual(set1, set2) {
		if (set1.size !== set2.size) return false;

		for (const item of set1) {
			if (!set2.has(item)) return false;
		}
		return true;
	}

	const selectProperty = (selectedInstanceID, selectingParent) => {
		const property = activeLab.properties.find(
			(property) => property.instanceID === selectedInstanceID
		);
		if (property) {
			if (selectingParent) viewer.select([selectedInstanceID]);
		} else {
			const instanceTree = viewer.model.getData().instanceTree;
			const parentID = instanceTree.getNodeParentId(selectedInstanceID);
			if (parentID) selectProperty(parentID, true);
		}
	};

	return null;
}

export default SelectInModel;
