import React from 'react';
/* global Autodesk, THREE */

class Viewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _viewer: null,
            document: null
        };
    }

    launchViewer = (urn) => {
        if (this.state._viewer != null) {
            this.state._viewer.tearDown()
            this.state._viewer.finish()
            this.setState({ _viewer: null })
        }
        var myViewerDiv = document.getElementById('Viewer');
        var config3d = {
            theme: 'light-theme'
        };
        var viewer = new Autodesk.Viewing.Private.GuiViewer3D(myViewerDiv, config3d);

        var options = {
            'env': 'Local',
            'document': urn
        }
        viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, (e) => {

            this.setState({
                _viewer: viewer
            });
            this.props.onModelLoaded(viewer);

        });
        Autodesk.Viewing.Initializer(options, function () {
            viewer.start(options.document, options);
        });
    }

    componentDidMount() {
        console.log('localviewer', this.props.urn)
        console.log(this.props.urn)
        this.launchViewer(this.props.urn, null);
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props.urn)
        if (prevProps.urn !== this.props.urn) {
            this.launchViewer(this.props.urn, null);
        }
    }

    render() {
        return (
            <div id="Viewer" className={`${this.props.visible ? 'd-block' : 'd-none'}`} > </div>
        )
    }
}
export default Viewer