import React           from 'react';
import Router          from './router/Router';
import { requestData } from './helpers/axios/Get'
import { connect }     from 'react-redux';
import {
  FETCH_GENERAL_SETTINGS_PENDING,
  FETCH_GENERAL_SETTINGS_SUCCESS,
  FETCH_GENERAL_SETTINGS_ERROR,
  FETCH_FOLDER_BLACKLIST_PENDING,
  FETCH_FOLDER_BLACKLIST_SUCCESS,
  FETCH_FOLDER_BLACKLIST_ERROR,
  FETCH_FILE_WHITELIST_PENDING,
  FETCH_FILE_WHITELIST_SUCCESS,
  FETCH_FILE_WHITELIST_ERROR,
} from './helpers/constants/Constants';

class App extends React.Component {
  
  componentDidMount() {
    this.props.onrequestFileWhiteListReducer();
    this.props.onrequestFolderBlackListReducer();
    this.props.onrequestGeneralSettingsReducer();
  }
  
  componentDidUpdate() {}

  render() {
    return (
      <Router />
    );
  }
};

const mapStateToProps = (state) => ({
  state
});

const mapDispatchToProps = (dispatch) => {
  return {
    onrequestFileWhiteListReducer: () => dispatch(requestData(
      '/config/FileExtensionWhiteList.json',
      {
        pending: FETCH_FILE_WHITELIST_PENDING,
        success: FETCH_FILE_WHITELIST_SUCCESS,
        error:   FETCH_FILE_WHITELIST_ERROR,
      }
    )),
    onrequestFolderBlackListReducer: () => dispatch(requestData(
      '/config/FolderExtensionBlackList.json',
      {
        pending: FETCH_FOLDER_BLACKLIST_PENDING,
        success: FETCH_FOLDER_BLACKLIST_SUCCESS,
        error:   FETCH_FOLDER_BLACKLIST_ERROR,
      }
    )),
    onrequestGeneralSettingsReducer: () => dispatch(requestData(
      '/config/GeneralSettings.json',
      {
        pending: FETCH_GENERAL_SETTINGS_PENDING,
        success: FETCH_GENERAL_SETTINGS_SUCCESS,
        error:   FETCH_GENERAL_SETTINGS_ERROR,
      }
    )),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
