import React from "react";
import { connect } from "react-redux";
import Header from "../../components/header/Header";
import ModelNavigation from "../../components/modelNavigation/ModelNavigation";
import LabArea from "../../components/labArea/LabArea";
import Disclaimer from "../../components/disclaimer/Disclaimer";
import { GetModelThree } from "../../components/labArea/RevitModelDataExtractor";
import ModelData from "../../components/modelData/ModelData";
import { withTranslation } from "react-i18next";
import { GetIFCModelThree } from "../../components/labArea/IfcModelDataExtractor";
import { labVersionHandler } from "../../versionHandlers/LabVersionHandler";
import { UsageLogger } from "../../helpers/logger/logger";
import { getHubChildStruct } from "../../helpers/axios/Get";
const axios = require("axios");

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPublic: false,
      model: null,
      modelData: null,
      showModelNav: true,
      loadingFromFile: false,
      onLoadingComplete: null,
      showValues: true,
    };
  }

  selectModel = (model) => {
    if (!model.urn) model = Object.assign(model, { urn: model.id });
    this.setState({ model: model });
  };

  setModelData = (data) => {
    let modelData = this.state.loadingFromFile ? this.state.modelData : data;
    const model = data.loadedFromFile ? data.model : this.state.model;
    modelData.setForgeModel(model);
    this.setState({ modelData: modelData, loadingFromFile: false });
  };

  onModelLoaded = (viewer, onLoadingComplete) => {
    this.setState(
      { onLoadingComplete: onLoadingComplete },
      this.loadModelData(viewer)
    );
  };

  loadModelData = (viewer) => {
    let fileType = this.state.model.fileType;
    let projectName = this.props.selected.text;
    let modelName = this.state.model.fileName;
    if (fileType === "rvt") {
      GetModelThree(viewer)
        .then((res) => this.setModelData(res))
        .catch((err) => console.log(err))
        .finally(() => {
          this.state.onLoadingComplete(viewer);
          UsageLogger(
            "Model opens",
            this.props.getUser(),
            projectName,
            modelName
          );
        });
    } else if (fileType === "ifc") {
      GetIFCModelThree(viewer)
        .then((res) => {
          this.setModelData(res);
          console.log(" REESS", res);
        })
        //.then(res   =>  console.log(' REESS',res))
        .catch((err) => console.log(err))
        .finally(() => {
          this.state.onLoadingComplete(viewer);
          UsageLogger(
            "Model opens",
            this.props.getUser(),
            projectName,
            modelName
          );
        });
      //  .finally(() =>console.log(viewer) );//this.state.onLoadingComplete(viewer));
    }
  };

  loadModelFromJSON = async (data) => {
    const { t } = this.props;
    if (data) {
      var labData = await labVersionHandler(data);
      let update = false;
      try {
        await axios
          .all([
            getHubChildStruct(
              "https://developer.api.autodesk.com/data/v1/projects/" +
                labData.model.projectID +
                "/items/" +
                labData.model.hrefbim360,
              { withCredentials: true }
            ),
          ])
          .then(
            axios.spread((items) => {
              var newVersion = Number(
                items.data[0].text.split(":")[0].replace("v", "")
              );
              if (newVersion > labData.model.version) {
                if (
                  window.confirm(
                    t("mainPage.oldLab", {
                      newVersion: newVersion.toString(),
                      oldVersion: labData.model.version.toString(),
                    })
                  )
                ) {
                  update = true;
                  labData.Labs = [];
                  labData.modelName =
                    items.data[0].fileName + ": " + items.data[0].text;
                  labData.model.text = items.data[0].text;
                  labData.model.fileName = items.data[0].fileName;
                  labData.model.id = items.data[0].id;
                  labData.model.urn = items.data[0].id;
                  labData.model.version = Number(
                    items.data[0].text.split(":")[0].replace("v", "")
                  );
                }
              }
            })
          );
      } catch (error) {
        console.error(error);
        this.setState(
          {
            model: labData.model,
            modelData: ModelData.constructFromFile(labData),
            loadingFromFile: true,
          },
          this.selectModel(labData.model)
        );
        this.state.modelData.setActiveLab(0);
      }

      if (!update) {
        this.setState(
          {
            model: labData.model,
            modelData: ModelData.constructFromFile(labData),
            loadingFromFile: true,
          },
          this.selectModel(labData.model)
        );
        this.state.modelData.setActiveLab(0);
      } else {
        this.selectModel(labData.model);
      }
    }
  };

  saveToLocalStorage = () => {
    const user = this.props.getUser();
    if (!user || !user.id || !this.state.modelData) return;

    const storage = window.localStorage;

    if (storage.getItem(user.id)) storage.removeItem(user.id);
    storage.setItem(user.id, JSON.stringify(this.state.modelData));
  };

  loadFromLocalStorage = (loadLab) => {
    const { t } = this.props;
    const user = this.props.getUser();
    if (!user || !user.id) return;

    const storage = window.localStorage;
    const data = storage.getItem(user.id);

    if (loadLab === false) {
      if (data !== null || data !== "null") {
        this.loadModelFromJSON(JSON.parse(data));
        storage.removeItem(user.id);
      } else {
        return;
      }
    } else {
      if (
        data === null ||
        data === "null" ||
        !window.confirm(t("mainPage.resume"))
      )
        return;
      this.loadModelFromJSON(JSON.parse(data));
      storage.removeItem(user.id);
    }
  };

  showHideModelNav = (show) => {
    this.setState({ showModelNav: show }, this.resizeEvent());
    if (document.getElementById("chart-table-container")) {
      document.getElementById("chart-table-container").style.width =
        window.innerWidth - 400 - 340 + "px";
    }
  };

  resizeEvent = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 10);
  };

  render() {
    return (
      <>
        <Disclaimer />
        <Header
          selectModel={(model) => this.selectModel(model)}
          model={this.state.model}
          redirectCheck={() => this.props.redirectCheck()}
          modelData={this.state.modelData}
          loadModelFromJSON={this.loadModelFromJSON}
          isPublic={this.state.isPublic}
          showValues={this.state.showValues}
        />
        <div id="ccms-content">
          <ModelNavigation
            selectModel={(model) => this.selectModel(model)}
            redirectCheck={() => this.props.redirectCheck()}
            showHideModelNav={this.showHideModelNav}
            showModelNav={this.state.showModelNav}
            showValues={this.state.showValues}
          />
          <LabArea
            model={this.state.model}
            modelData={this.state.modelData}
            setModelData={this.setModelData}
            onModelLoaded={this.onModelLoaded}
            isPublic={this.state.isPublic}
            redirectCheck={() => this.props.redirectCheck()}
            showHideModelNav={this.showHideModelNav}
            showModelNav={this.state.showModelNav}
            resizeEvent={this.resizeEvent}
            saveToLocalStorage={this.saveToLocalStorage}
            loadFromLocalStorage={this.loadFromLocalStorage}
            showValues={this.state.showValues}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.requestModelDataReducer.data,
  selected: state.getSelectedProjectReducer.data,
});

//export default connect(mapStateToProps)(MainPage);
const MyComponent = withTranslation()(connect(mapStateToProps)(MainPage));
export default MyComponent;
