import React, { Component } from 'react';
import DataViewer from './DataViewer';
import { Trans,  withTranslation } from 'react-i18next';

class DataViewerContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedLabName: this.props.data[0].name,
            selectedLab: true
        };
    }

    filterData = () => {
        let selectedLab = this.props.data.filter(lab => {
            return lab.name === this.state.selectedLabName
        })[0];

        let keys = Object.keys(selectedLab).filter(key => {
                if (key !== 'name' && key !== 'main' ) {
                    return key
                }
            });
        let sumCO2 = 0;
        let sumCost = 0;
      

        for (let key in selectedLab) {
            if (selectedLab[key].CO2) {
                sumCO2 = sumCO2 + selectedLab[key].CO2
                sumCost = sumCost + selectedLab[key].cost
            }
        }

        let result = {
            sum:
            {
                CO2: sumCO2,
                cost: sumCost
            },
            selectedLab: selectedLab,
            keys: keys,

        };

        return result;
    }

    handleChange = (select) => {
        this.setState({ selectedLabName: select.target.value })
    }

    render() {
        const result = this.filterData();
        return (
            <div id="data-viewer-container">
                <h2><Trans i18nKey="dataViewerContainer.header"/></h2>
                <select
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.selectedLabName}>
                    {this.props.data.map((lab, i) => {
                        return <option key={i} value={lab.name}>{lab.name}</option>;
                    })
                    }
                </select>
                <div className="chart-data-tables">
                    <DataViewer showValues={this.props.showValues} selectedLab={result} datatype='CO2' percentType='CO2Percent' name='kg CO2e' unit="kg"></DataViewer>
                    <DataViewer showValues={this.props.showValues} selectedLab={result} datatype='cost' percentType='CostPercent' name={<Trans i18nKey="dataViewerContainer.SEK"/>} unit="kr"></DataViewer>
                </div>
            </div>
        );
    }
}

//export default DataViewerContainer;
const MyComponent = withTranslation()(DataViewerContainer)
export default MyComponent;