import QuantityTool from './QuantityTool'
import InfoTool from './InfoTool'
import React from 'react';
import ReactDOM from 'react-dom';

/* global Autodesk, THREE, Hammer */ // eslint-disable-line

const QuantityToolName = 'quantity-tool';
const InfoToolName = 'info-tool';
//const SphereDrawToolName = 'sphere-draw-tool';
const AdvancedToolOverlay = 'Advanced-tool-overlay';

class ToolExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options, props) {
        super(viewer, options);
        this.props = props;
        this.QuantityTool = this.renderQuantityTool();
        this.InfoTool = this.renderInfoTool();
        this.observer = null
    }

    updateProps(props) {
        this.props = props;
        this.QuantityTool = this.renderQuantityTool();
        this.InfoTool = this.renderInfoTool();
        if(document.getElementById('draggable' + QuantityToolName)) {
            ReactDOM.render(this.QuantityTool, document.getElementById('draggable' + QuantityToolName));
        }
        if(document.getElementById('draggable-' + InfoToolName)) {
            ReactDOM.render(this.InfoTool, document.getElementById('draggable-' + InfoToolName));
        }
    }

    toolbarSize() {
        var toolbar = document.getElementById('guiviewer3d-toolbar')
        var treeContainer = document.getElementById('viewer-tree-container')
        let viewcubeWrapper = document.getElementsByClassName("viewcubeWrapper")

        if (treeContainer && viewcubeWrapper && toolbar) {
            viewcubeWrapper[0].style.right = "330px"
            toolbar.style.transition = "all 0.2s ease-out"
            if (treeContainer.className === 'expanded') {
                toolbar.style['max-width'] = 'calc(100% - 800px)'
                toolbar.style.left = '430px'
            } else {
                toolbar.style['max-width'] = 'calc(100% - 445px)'
                toolbar.style.left = '80px'
            }
        }
    }

    load(props) {
        this.props = props; // Store the props in the extension instance
        this.viewer.impl.createOverlayScene(AdvancedToolOverlay);
        this._createUI();
        console.log('AdvancedToolExtension loaded.');

        var treeContainer = document.getElementById('viewer-tree-container')
        this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if(mutation.attributeName == "class"){
                    this.toolbarSize()
                }
            });
        });
        this.observer.observe(treeContainer, {attributes: true});
        window.addEventListener('resize', this.toolbarSize)
        return true;
    }
    
    unload() {
        this.viewer.impl.removeOverlayScene(AdvancedToolOverlay);
        this._removeUI();
        if (this.observer) this.observer.disconnect();
        window.removeEventListener('resize', this.toolbarSize)
        console.log('AdvancedToolExtension unloaded.');
        return true;
    }

    onToolbarCreated() {
        this._createUI();
    }

    renderQuantityTool() {
        return (
            <QuantityTool
                {...this.options.props.props}
                viewer={this.viewer}
                options={this.options.props}
            />
        );
    }

    renderInfoTool() {
        return (
            <InfoTool
                {...this.options.props.props}
                viewer={this.viewer}
                options={this.options.props}
            />
        );
    }

    popUpTool(name) {
        const viewerContainer = this.viewer.container;
        const draggableElement = document.createElement('div');
        const resizableElement = document.createElement('div');
        draggableElement.id = 'draggable-'+ name;
        resizableElement.id = 'resizable-'+ name;
        draggableElement.className= 'draggable-element';
        resizableElement.className = 'resizable-element';
        resizableElement.appendChild(draggableElement)
        viewerContainer.appendChild(resizableElement);
        if (name === 'quantity-tool') {
            ReactDOM.render(this.QuantityTool, draggableElement);
            resizableElement.style['z-index'] = 10
        }
        if (name === 'info-tool') {
            ReactDOM.render(this.InfoTool, draggableElement);
            resizableElement.style['z-index'] = 11
        }
      
        const mc = new Hammer.Manager(draggableElement);
        const pan = new Hammer.Pan();
        mc.add(pan);

        const draggableElementWidth = draggableElement.offsetWidth;
        const draggableElementHeight = draggableElement.offsetHeight;
        const viewerContainerWidth = viewerContainer.offsetWidth;
        const viewerContainerHeight = viewerContainer.offsetHeight;
        let startPosY = 0;
        let startPosX = 0;
        let offsetX = 0;
        let offsetY = 0;

        var start = viewerContainerHeight/viewerContainerWidth > 2.2 ? (viewerContainerHeight - draggableElementHeight)*0.2 : (viewerContainerHeight - draggableElementHeight)*0.1

        draggableElement.style.left = `${(viewerContainerWidth - draggableElementWidth) / 2}px`;
        draggableElement.style.bottom = `${start}px`;

        draggableElement.addEventListener('overflow', function() {
            if (draggableElement.scrollHeight > draggableElement.clientHeight) {
                // Scrollbar is active, apply different padding
                draggableElement.style.padding = '100px';
            } else {
                // Scrollbar is not active, revert to default padding
                draggableElement.style.paddingRight = '10px';
            }
        });
      
        mc.on('panstart', (event) => {
            startPosX = event.center.x - offsetX;
            startPosY = event.center.y - offsetY;
        });
    
        mc.on('panmove', (event) => {
            offsetX = event.center.x - startPosX;
            offsetY = event.center.y - startPosY;

            resizableElement.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
        });
    }

    _createUI() {
        const toolbar = this.viewer.toolbar;
        if (toolbar && (!this.group || this.group === undefined)) {
            const controller = this.viewer.toolController;

            //quantity-tool-button
            this.quantityToolButton = new Autodesk.Viewing.UI.Button(QuantityToolName + '-button');
            this.quantityToolButton.onClick = (e) => {
                if (this.quantityToolButton.getState() === 0) {
                    controller.deactivateTool(QuantityToolName);
                    this.quantityToolButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
                    this.viewer.container.removeChild(document.getElementById('resizable-' + QuantityToolName));
                    this.options.props.updateLabState()
                } else {
                    this.popUpTool('quantity-tool');
                    controller.activateTool(QuantityToolName);
                    this.quantityToolButton.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
                }
            };
            this.quantityToolButton.setToolTip('Quantity Tool');

            //info-tool-button
            this.infoToolButton = new Autodesk.Viewing.UI.Button(InfoToolName + '-button');
            this.infoToolButton.onClick = (e) => {
                if (this.infoToolButton.getState() === 0) {
                    controller.deactivateTool(InfoToolName);
                    this.infoToolButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
                    this.viewer.container.removeChild(document.getElementById('resizable-' + InfoToolName));
                } else {
                    this.popUpTool('info-tool');
                    controller.activateTool(InfoToolName);
                    this.infoToolButton.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
                }
            };
            this.infoToolButton.setToolTip('Info Tool');
            
            this.group = new Autodesk.Viewing.UI.ControlGroup('advanced-tool-group');
            this.group.addControl(this.quantityToolButton);
            this.group.addControl(this.infoToolButton);
            toolbar.addControl(this.group);
        }
    }

    _removeUI() {
        if (this.group) {
            this.viewer.toolbar.removeControl(this.group);
            this.group = null;
        }
    }
}

//export default Autodesk.Viewing.theExtensionManager.registerExtension('ToolExtension', ToolExtension);
export default ToolExtension;