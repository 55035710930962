export const readJSONFile = (file, callback) => {
  if (!file) return;

  const reader = new FileReader();

  reader.addEventListener('load', e  => callback(JSON.parse(e.target.result)));
  reader.addEventListener('error', e => alert('File går ej att läsa!'));

  reader.readAsText(file);
}

export const saveLabsToJSON = (data, modelName) => {
  if (!data) return;

  const date     = new Date();
  const dateStr  = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  const filename = `${modelName}_${dateStr}.json`;
  const blob     = new Blob([JSON.stringify(data)], { type: 'application/json' });
  const link     = document.createElement('a');

  link.download = filename;
  link.href     = URL.createObjectURL(blob);
  link.click();
}

export const nrFormatter = number => {
  return new Intl.NumberFormat('sv-SE', { notation: "standard" }).format(number);
}

export const deepCopy = (original) => {

  let copy = [];
  
  original.forEach(element => {
    
    if (Array.isArray(element)) copy.push(deepCopy(element));
    else {
      if (typeof element === 'object') copy.push(deepCopyObject(element));
      else copy.push(element);
    }
  });

  return copy;
}

export const deepCopyObject = (original) => {

  let temp = {};

  for (let [key, value] of Object.entries(original)) {

    if (Array.isArray(value)) temp[key] = deepCopy(value);
    else {
      if (value === null) temp[key] = null;
      else if (value === undefined) temp[key] = undefined;
      else if (typeof value === 'object') temp[key] = deepCopyObject(value);
      else temp[key] = value;
    }
  }

  return temp;
}

export const calculationMarkUp = (elementVersionId, elementId) => {
  const markUp = 1
  if (elementVersionId && elementId) {
    const version = elementVersionId.substring(elementId.length).replace('|','')
    const k = Number(version) && Number(version) > 4 ? markUp : 1;
    return k
  } 
  return 1
}
