import React, { Component, Fragment } from 'react';
import Select, { components } from 'react-select';
import { Trans, withTranslation } from 'react-i18next';
import ParserSettings from '../../config/parsingSettings.json'

class LabTabs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      labs: [],
      selected: this.props.modelData.getActiveLab().name,
      maxNumberOfLabs: 8,
      open: false,
      value: null,
      options: [{value: 'Original', label: 'Original'}],
      maxChar: 15,
    }

    this.changeOpen = this.changeOpen.bind(this) 
    this.closeMenu = this.closeMenu.bind(this);
  }

  activateValidator = (modelData) => {
    if(modelData && modelData.properties) {
      var outOfdateElements = []
      const categories = ParserSettings.lookupTable.map(item => item.c3Category);
  
      modelData.properties.forEach(element => {
            if (element.isLatest === false && element.elementName) outOfdateElements.push(element);
            var number = Number(element.calculatedArea)
            if ((!number || number == 0)  && categories.includes(element.category) === true) outOfdateElements.push(element);
            if (element.ExistInDatabase === false && categories.includes(element.category) === true) outOfdateElements.push(element);
      });
      
      if (outOfdateElements.length !== 0 && modelData.main === false) this.props.activateValidator(true);
    }
  }

  addLab = (event) => {
    const { t } = this.props;
    const modelData = this.props.modelData.newLab(t("labTabs.lab"));
    this.state.options.push({value: modelData.getActiveLab().name, label: modelData.getActiveLab().name})
    for (let i = 0; i < this.props.modelData.Labs.length; i++) {
      if (this.props.modelData.Labs[i].name === event.value) {
        modelData.updateActivLab(JSON.parse(JSON.stringify(modelData.Labs[i].properties)))
        modelData.getActiveLab().recipeMap = modelData.Labs[i].recipeMap
          this.setState({
            selected: modelData.getActiveLab().name
          }, () => {
            this.props.setModelData(modelData);
            this.props.updateLabState();
            this.activateValidator(this.props.modelData.getActiveLab());
          });
        }
      }
  }

  onSelect = e => {
    this.props.activateValidator(false)
    if (e.target.nodeName === 'LABEL' || e.target.nodeName === 'SPAN') return;

    const targetIsParent = e.target.nodeName === 'DIV';
    const id = targetIsParent ? e.target.id : e.target.parentElement.id;
    const title = targetIsParent ? e.target.title : e.target.parentElement.title;

    if (title === this.state.selected) {
      let input = targetIsParent ? e.target.firstElementChild : e.target;
      if (input.classList.contains('d-none')) this.toggleNameChangeInput(input);
    }

    this.setSelected(title, id);
  }

  setSelected = (selected, idx) => {
    this.setState({ selected: selected }, () => {
      this.props.modelData.setActiveLab(parseInt(idx));
      this.props.updateLabState();
      this.activateValidator(this.props.modelData.getActiveLab());
    })
  }

  toggleNameChangeInput = (input) => {
    const show = input.classList.contains('d-none');
    input.classList.remove(show ? 'd-none' : 'd-block')
    input.classList.add(show ? 'd-block' : 'd-none');
    if (input.previousElementSibling) input.previousElementSibling.classList.add(show ? 'd-none' : 'd-block');
    if (show) input.focus();
  }

  handleNameChange = (input, idx) => {
    this.state.options[idx] = {value: input.value, label: input.value};
    if (!input.value || this.state.selected === input.value) {
      this.toggleNameChangeInput(input);}
    else if (this.props.modelData.labNameExists(input.value) || input.value.length >= this.state.maxChar+1) {
      alert(input.value, 'already exists or is to long');
      input.focus();
    }
    else {
      this.props.modelData.setLabName(idx, input.value.trim());
      this.setSelected(input.value, idx);
      this.toggleNameChangeInput(input);
    }
  }

  handleInput = (e, i) => {
    if (e.target.classList.contains('d-none')) return;
    else if (e.key && e.key === 'Escape') this.toggleNameChangeInput(e.target);
    else if (e.key === 'Enter' || e.type === 'blur') this.handleNameChange(e.target, i);
  }

  handleRemoveLab = (e, i) => {
    const { t } = this.props;
    const labs = this.props.modelData.Labs;

    for (let i = 0; i < this.state.options.length; i++) {
      if (labs[e.target.parentElement.id].name === this.state.options[i].value) {
        this.state.options.splice(i, 1);
      }
    };


    if (!window.confirm(t("labTabs.removeWarning.1")+labs[e.target.parentElement.id].name + t("labTabs.removeWarning.2") + labs[e.target.parentElement.id].name + t("labTabs.removeWarning.3"))) return;

    if (labs.indexOf(this.props.modelData.getActiveLab()) === i) {
      const newActiveLabIdx = i - 1;
      this.setSelected(labs[newActiveLabIdx].name, newActiveLabIdx);
    }


    this.props.setModelData(this.props.modelData.removeLab(i))
  }

  renderTabs = () => {
    let tabs = this.state.labs.map((lab, i) => {
      let active = lab.name === this.state.selected ? 'active' : '';
      return (
        <div
          key={"lab-" + i}
          id={i}
          className={`btn ${active}`}
          title={lab.name}
          onClick={(e) => this.onSelect(e)}
        >
          <label
            id={i}
            className={`${active}`}
            htmlFor={`input-${i}`}
          >
            {lab.name}
          </label>
          <input
            id={`input-${i}`}
            type="text"
            className={`form-control d-none`}
            onBlur={e => this.handleInput(e, i)}
            onKeyDown={e => this.handleInput(e, i)}
            placeholder={'Max '+ this.state.maxChar + ' tecken'}
            maxLength={this.state.maxChar}
          />
          {
            lab.main !== true ?
              <span
                className="close ml-2 icon-close-tab"
                onClick={(e) => this.handleRemoveLab(e, i)}
              /> : ''
          }
        </div>
      );
    });

    return tabs;
  }

  componentDidMount() {
    this.setState({
      labs: this.props.modelData.Labs,
    });
  }
  componentWillUnmount() {
    this.setState({ open: false }, () => {
      document.removeEventListener('click', this.closeMenu, {capture: true});
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.modelData.Labs) !== JSON.stringify(this.state.labs)) {
      this.setSelected(this.props.modelData.Labs[0].name, "0")
      this.setState({ labs: this.props.modelData.Labs});
    }
  }

  changeOpen() { 
    var newOptions = []
    for (let i = 0; i < this.props.modelData.Labs.length; i++) {
      var newlabs = {value: this.props.modelData.Labs[i].name, label: this.props.modelData.Labs[i].name}
      newOptions.push(newlabs)
    }
    if (newOptions.length !== 0) {
      this.setState({
        options: newOptions
      })
    }
    this.setState({ open: !this.state.open }, () => {
      document.addEventListener('click',this.closeMenu, {capture: true});
    });
  }
  
  closeMenu(event) {
    if (!event.target.closest('.dropdown')) {
      this.setState({ open: false }, () => {
        document.removeEventListener('click', this.closeMenu, {capture: true});
      });  
    }
  }


  render() {
    const dropDownStyle = {
			control: (provided) => ({
        ...provided,
        border: 0
      }),
      menu: (base) => ({
        ...base,
        width: "max-content",
        minWidth: "100px",
        borderRadius: "0px",
        marginTop: "5px",
        marginLeft: "6px",
        boxShadow: "rgba(0, 0, 0, 0.1) -2px 2px 6px 2px",

   }),
		  }
    const GroupHeading = () => {
      return (
        <>
          <p style={{textAlign: 'center', margin: '0px', paddingTop: '4px', paddingBottom: '12px', borderBottom: '0.5px solid lightgray'}}><strong><Trans i18nKey="labTabs.copyFrom"/></strong></p>
        </>
      );
    };

    const NoOptionsMessage = props => {
      return (
        <components.NoOptionsMessage {...props}>
          <span> <Trans i18nKey="labTabs.demoWarning"/> </span> 
        </components.NoOptionsMessage>
      );
    };

    return (
      <Fragment>
      <div
        id="lab-tabs"
        className={`${this.props.visible ? 'd-flex' : 'd-none'}`}
      >
        {/* lägg till if sats för om labs är 0 */}
        { this.renderTabs()}
        <button
          className={"btn icon-plus"}
          onClick={this.changeOpen}
          disabled={this.state.labs.length >= this.state.maxNumberOfLabs}
        />
      </div>
      <div style={{position:'relative', left: '-50px', bottom: '-15px', 'zIndex': '1000'}} className='dropdown'>
        {!this.state.open === true ? null:
          <Select 
            components={{ IndicatorsContainer:() => null, ValueContainer:() => null, NoOptionsMessage, GroupHeading}}
            menuIsOpen
            options = {this.props.showValues === false ? [] : [{"label": "Group 1","options": this.state.options}] }
            onChange={(e) => {this.addLab(e); this.changeOpen();}}
            styles={dropDownStyle}
            isSearchable={false} 
            
          />
        }
      </div>
      </Fragment>
    );
  }
}



//export default LabTabs;
const MyComponent = withTranslation()(LabTabs)
export default MyComponent;