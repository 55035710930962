import React from 'react';
import ProjectFinder from '../projectFinder/ProjectFinder';
import ProjectBrowser from '../projectBrowser/ProjectBrowser';

class ModelNavigation extends React.Component {

  constructor(props) {
    super(props);

    document.body.classList.add('project-viewer');

    this.state = {
      show: true,
    };
  }

  showHideModelNav = () => {
    this.props.showHideModelNav(false);
  }

  render() {
    return (
      <>
        <section id='model-navigation' className={`collapse ${false ? 'show' : ''}`}>
          {/* <div id='project-finder-top'>
            <ProjectFinder redirectCheck={() => this.props.redirectCheck()} />
            <button type="button" className={`btn close nav-close ${this.state.show ? 'd-block' : 'd-none'}`} onClick={this.showHideModelNav} data-keyboard="true">
              <span className="sr-only">Hide navigation</span>
            </button>
          </div>
          <ProjectBrowser
            selectModel={(model) => this.props.selectModel(model)}
            redirectCheck={() => this.props.redirectCheck()}
          /> */}
        </section>
      </>
    );
  };
}

export default ModelNavigation;