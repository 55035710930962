import parsingSetting from "../../config/parsingSettings.json";
import { GetElementsByIds } from "./DataConnector";
import ModelData from "../modelData/ModelData";
import { calculationMarkUp } from "../../helpers/helpers";

export async function GetModelThree(viewer) {
  let instancetree = viewer.getAllModels()[0].getData().instanceTree;
  function nodeParser(id) {
    let dbIds = [];
    instancetree.enumNodeChildren(
      id,
      function (e) {
        dbIds.push(e);
      },
      false
    );
    return dbIds;
  }
  function ExtractBTA() {
    return new Promise((resolve, reject) => {
      var searchStr = "Sweco_cc_BTA_placeholder";
      var searchPropList = new Array("Type Name");
      viewer.search(
        searchStr,
        (ids) => {
          getBTAfromProp(ids[0]).then((BTA) => {
            resolve(BTA);
          });
        },
        (error) => {
          reject(error);
        },
        searchPropList
      );
    });
  }
  function getBTAfromProp(instanceID) {
    return new Promise((resolve, reject) => {
      viewer.getProperties(instanceID, (props) => {
        props.properties.forEach((prop) => {
          if (prop.displayCategory === "Data") {
            if (prop.displayName === "sweco_cc_BTA (m2)") {
              resolve(prop.displayValue.replace(",", "."));
            } else {
            }
          }
        });
        resolve(null);
      });
    });
  }
  function searchCallback(ids) {
    alert(ids.length);
  }
  function searchErrorCallback(error) {
    console.log(error);
  }
  function calculatedArea(obj) {
    return new Promise((resolve, reject) => {
      viewer.getProperties(obj.instanceID, (props) => {
        let factor = 1;
        var area_m2 = null;
        var parser = parsingSetting.parser.filter((x) => {
          return x.category === obj.revitCategory;
        });
        //NÅGOT STÄMMER INTE HÄR KOLLA UPP
        if (parser.length > 0) {
          props.properties.forEach((prop) => {
            if (prop.displayCategory === "Dimensions") {
              parser[0].property.forEach((propName) => {
                if (propName === prop.displayName) {
                  factor = factor * prop.displayValue;
                }
              });
            }
          });
          obj.calculatedArea = factor * parser[0].factor;

          resolve(area_m2);
        } else {
          resolve();
        }
      });
    });
  }
  function getDataBaseIDfromProp(obj) {
    return new Promise((resolve, reject) => {
      let dirtyFlag = false;
      viewer.getProperties(obj.instanceID, (props) => {
        props.properties.forEach((prop) => {
          if (prop.displayCategory === "Analytical Model") {
            dirtyFlag = true;
            if (prop.displayName === "sweco_cc_Id") {
              obj.databaseID = prop.displayValue;
              resolve(prop.displayValue);
            } else {
              resolve(prop);
            }
          }

          if (!dirtyFlag) {
            resolve(prop);
          }
        });
      });
    });
  }
  async function read() {
    return new Promise((resolve, reject) => {
      let list = [];
      nodeParser(1).forEach((categoryID) => {
        let categoryName = instancetree.getNodeName(categoryID);
        nodeParser(categoryID).forEach((familyID) => {
          let familyName = instancetree.getNodeName(familyID);
          nodeParser(familyID).forEach((typeID) => {
            let typeName = instancetree.getNodeName(typeID);
            nodeParser(typeID).forEach((instanceID) => {
              let C3Cat = parsingSetting.lookupTable.filter((x) => {
                if (x.category === categoryName) return x.c3Category;
              });
              let cat;
              if (C3Cat.length === 0) {
                cat = categoryName;
              } else {
                cat = C3Cat[0].c3Category;
              }
              list.push({
                C3type: "model",
                role: null,
                category: cat,
                revitCategory: categoryName,
                categoryID: categoryID,
                family: familyName,
                familyID: familyID,
                revitType: typeName,
                revitTypeId: typeID,
                instanceID: instanceID,
                isLatest: null,
                dimensionprop: null,
                calculatedArea: 0,
                CO2: 0,
                Cost: 0,
                DatabaseType: null,
                ExistInDatabase: false,
                Updated: false,
                databaseID: null,
                designPartName: null,
                designPartCategoryName: null,
                elementId: null,
                elementVersionId: null,
                elementName: null,
                elementCO2: 0,
                elementUnitCost: 0,
                calculateElement: true,
                unit: null
              });
            });
          });
        });
      });
      resolve(list);
    });
  }
  return read().then((x) => {
    let task = x.map(async (prop) => {
      await getDataBaseIDfromProp(prop);
      await calculatedArea(prop);
      return prop;
    });
    return Promise.all(task).then((res) => {
      return new Promise((resolve, reject) => {
        var distinctDatabaseIDs = res
          .map((item) => item.databaseID)
          .filter((value, index, self) => self.indexOf(value) === index)
          .filter((x) => x !== null)
          .filter((y) => y !== "");
        GetElementsByIds(distinctDatabaseIDs).then((datafromDataBas) => {
          x.forEach((prop) => {
            var databaseObject = datafromDataBas.filter((_databaseObject) => {
              if (_databaseObject.elementVersionId === prop.databaseID) {
                return x;
              }
            });
            if (databaseObject.length > 0) {
              prop.CO2 = prop.calculatedArea * databaseObject[0].co2;
              prop.Cost =
                prop.calculatedArea *
                databaseObject[0].unitCost *
                calculationMarkUp(
                  databaseObject[0].elementVersionId,
                  databaseObject[0].elementId
                );
              prop.role = databaseObject[0].role;
              prop.designPartName = databaseObject[0].designPartName;
              prop.elementId = databaseObject[0].elementId;
              prop.elementVersionId = databaseObject[0].elementVersionId;
              prop.elementName = databaseObject[0].elementName;
              prop.elementCO2 = databaseObject[0].co2;
              prop.elementUnitCost = databaseObject[0].unitCost;
              prop.designPartCategoryName =
                databaseObject[0].designPartCategoryName;
              prop.isLatest = databaseObject[0].isLatestVersion;
              prop.unit = databaseObject[0].unit;

              if (prop.calculatedArea !== 0) {
                prop.ExistInDatabase = true;
              }
            }
          });

          ExtractBTA().then((BTA) => {
            console.log("ExtractBTA()", BTA);

            const modelData = new ModelData(x, BTA);

            resolve(modelData);
          });
        });
      });
    });
  });
}
