import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./helpers/store/Store";
import { refreshAccessToken } from "./components/labArea/DataConnector";
import axios from "axios";

import "./i18n";
import "./styles/sweco-route-finder.scss";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

/*
let isRefreshing = false;
 let failedQueue = [];

       const processQueue = (error, token = null) => {
            failedQueue.forEach(prom => {
                if (error) {
                    prom.reject(error);
                } else {
                    prom.resolve(token);
                }
            });

            failedQueue = [];
        };

axios.interceptors.response.use(
            response => {
                return response;
            },
err => {
                const originalRequest = err.config;

                if (err.response.status === 401 && !originalRequest._retry) {
                    if (isRefreshing) {
                        return new Promise(function(resolve, reject) {
                            failedQueue.push({ resolve, reject });
                        })
                            .then(token => {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                return axios(originalRequest);
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    return new Promise(function(resolve, reject) {
                        axios
                            .post('/api/user/refresh', {
                                refreshToken: "fooToken"})
                            .then(({ data }) => {
                               
                                processQueue(null, data.fooToken);
                                resolve(axios(originalRequest));
                            })
                            .catch(err => {
                                processQueue(err, null);
                                store.dispatch(showMessage({ message: 'Expired Token' }));

                                reject(err);
                            })
                            .then(() => {
                                isRefreshing = false;
                            });
                    });
                }

                return Promise.reject(err);
            }
        );  */

let isRefreshing = false;
let requestArray = [];

const axiosApiInstance = axios.create();
axios.interceptors.response.use(
  function (response) {
    //If a request has been successfull(200) remove it from the queue
    if (requestArray.length != 0) {
      requestArray.forEach(function (x, i) {
        if (response.config.url == x.url) {
          requestArray.splice(i, 1);
        }
      });
    }
    return response;
  },
  async function (error) {
    // Do something with request error
    const originalRequest = error.config;
    //Save failed request and call them when access
    requestArray.push(originalRequest);
    console.log("failed request in queue:" + originalRequest.url);
    // If the user is unauthorized let try refreshing, but not if a process already started
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !isRefreshing
    ) {
      isRefreshing = true;
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();

      //When access_token is provided, we can retry previous unauthorized requests
      if (requestArray.length != 0) {
        requestArray.forEach((x) => {
          if (x != originalRequest) {
            try {
              axiosApiInstance(x);
            } catch (e) {
              console.log(e);
            }
          }
        });
      }
      requestArray = [];
      isRefreshing = false;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
