import React, { Component, Fragment } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { nrFormatter } from '../../helpers/helpers';
import { Trans, withTranslation } from 'react-i18next';

class BTAstackedBarChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        }
    }

    componentDidMount() {
        var BTAvalue = document.evaluate('/html/body/div/div/section[2]/div[2]/div[1]/div[3]/table/tbody/tr[5]/td/text()[1]', document, null, XPathResult.STRING_TYPE, null).stringValue
        this.state.BTAvalue = BTAvalue
    }

    componentDidUpdate() {
        var BTAvalue = document.evaluate('/html/body/div/div/section[2]/div[2]/div[1]/div[3]/table/tbody/tr[5]/td/text()[1]', document, null, XPathResult.STRING_TYPE, null).stringValue
        if (this.state.BTAvalue !== BTAvalue) {
            this.state.BTAvalue = BTAvalue
        }
    }

    data(data) {
        data[0].CO2 = 0
        data[0].A5 = 0
        data[0].sumOfOrginalCO2 = 0
        data[0].sumOfOrginalCOST = 0
        data[0].COST = 0

        return data
    }

    render() {
        const data = this.state.BTAvalue === 0 ? this.data(this.props.data) : this.props.data
        const activeLab = this.props.activeLab.name;
        const customTooltip = ({ active, payload}) => {
            if (active && payload) {
                return ( 
                <Fragment>
                    {payload.length === 3 ? 
                    <div className="custom-tooltip">
                        <p><strong><Trans i18nKey="BTAstackedBarChart.CO2ePerM2" components={[<sup />]}/></strong></p>
                        <table className="table table-sm table-borderless">
                            <tbody>
                                <tr><th>A1-A5</th><td>Original</td><td>{Math.round(payload[0].value)} kg CO2e/m<sup>2</sup></td></tr>
                                <tr><th>A1-A4</th><td>{activeLab}</td><td>{Math.round(payload[1].value)} kg CO2e/m<sup>2</sup></td></tr>
                                <tr><th>A5</th><td>{activeLab}</td><td>{Math.round(payload[2].value)} kg CO2e/m<sup>2</sup></td></tr>
                            </tbody>
                        </table>
                    </div> : null }
                    {payload.length === 2 ? 
                    <div className="custom-tooltip">
                        <p><strong><Trans i18nKey="BTAstackedBarChart.costPerM2" components={[<sup />]}/> (A1-A4)</strong></p>
                        <table className="table table-sm table-borderless">
                            <tbody>
                                <tr><th>Original</th><td>{nrFormatter(Math.round(payload[0].value))} <Trans i18nKey="BTAstackedBarChart.SEK"/>/m<sup>2</sup></td></tr>
                                {
                                    this.props.activeLab.main !== true ? <tr><th>{activeLab}</th><td>{nrFormatter(Math.round(payload[1].value))} <Trans i18nKey="BTAstackedBarChart.SEK"/>/m<sup>2</sup></td></tr> : null
                                }
                            </tbody>
                        </table>
                    </div>  : null }
                </Fragment>
                );
            }
            return null
        }

        return (
            <div >
                <div>
                    <p className='y-axis-label' style={{float:'left'}}><span className="icon-sm icon-cloud" /><Trans i18nKey="BTAstackedBarChart.CO2ePerM2" components={[<sup />]}/></p>
                    <p className='y-axis-label' style={{float:'right'}}><span className="icon-sm icon-coins"/><Trans i18nKey="BTAstackedBarChart.costPerM2" components={[<sup />]}/></p>  
                </div>
                <BarChart  width={380} height={this.props.height} barSize={50} data={data} margin={{ top: 5, right: 0, left: 0, bottom: -23 }}>
                    <XAxis xAxisId={0}/>
                    <XAxis xAxisId={1} hide />
                    <XAxis xAxisId={2} hide/>
                    <XAxis xAxisId={3} hide />
                    <YAxis yAxisId={0} />
                    <YAxis yAxisId={1} orientation="right"/>
                    <YAxis tickFormatter = {nrFormatter} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip content={customTooltip} />
                    <Bar dataKey="sumOfOrginalCO2" barSize={40} name="sumOfOrginalCO2" xAxisId={0} yAxisId={0} fill="#3f3f42" />
                    <Bar stackId="a" dataKey="CO2" unit='Kg CO2e' label='A1-A4' xAxisId={1} yAxisId={0} barSize={20} fill={'#b586a4'} />
                    <Bar stackId="a" dataKey="A5" unit='Kg CO2e' xAxisId={1} yAxisId={0} barSize={20} fill={'#f2b1dc'} />
                    <Bar dataKey="sumOfOrginalCOST" barSize={40} xAxisId={2} yAxisId={1} fill="#3f3f42" />
					<Bar dataKey="COST" barSize={20} xAxisId={3} yAxisId={1} fill="#dec55b" />
                </BarChart >
                <div style={{marginTop: '25px'}}>
                    <div className='legend-container'>
                        <div><div className='legend pink-dark' /><p>A1-A4</p></div>
                        <div><div className='legend pink' /><p>A5</p></div>
                        <div><div className='legend dark-gray' /><p>A1-A5 (Original)</p></div>
                    </div>
                    <div className='legend-container'>
                        <div><div className='legend ochre' /><p><Trans i18nKey="BTAstackedBarChart.SEKText"/></p></div>
                        <div><div className='legend dark-gray' /><p><Trans i18nKey="BTAstackedBarChart.SEKText"/> (Original)</p></div>
                    </div>
                </div>
            </div>
        );
    }
}

//export default BTAstackedBarChart;
const MyComponent = withTranslation()(BTAstackedBarChart)

export default MyComponent;