import React         from 'react';
import LoginAutodesk from '../../components/login/Login';
import { connect }   from 'react-redux';

class Login extends React.Component {
  render() {
    return (
      <div className = "login-page">
        <LoginAutodesk />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Login);
