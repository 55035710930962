import React, { Component } from 'react';
import { nrFormatter } from '../../helpers/helpers';
import BTAstackedBarChart from './BTAstackedBarChart';
import ResultChartCalculator from "./ResultChartCalculator";
import { Trans, withTranslation } from 'react-i18next';

class BTACharts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resultCO2: null,
            resultCOST: null,
            BTA: this.props.modelData.BTA ? this.props.modelData.BTA : 0,
            chartSelector: false,
            garphContainer: true,
        };
    }

    calculateCO2Sum = () => {    
        let BTA = this.state.BTA;
        let sumCO2 = this.props.activeLab.properties.reduce((a, b) => {
            if (b.calculateElement) {
              return +a + +b.CO2;
            }
            return a;
          }, 0);
        let sumOfOrginalCO2 = this.props.modelData.Labs[0].properties.reduce((a, b) => +a + +b.CO2, 0);
        let resultCO2 = Math.round(sumCO2 / BTA);
        let A5 = 18.55 //GET REAL VALUE ????
      
        return [{
            CO2: Number(resultCO2) ? resultCO2 : 0,
            A5: A5,
            TotalCO2: Math.round(sumCO2),
            sumOfOrginalCO2: Math.round((sumOfOrginalCO2 / BTA) + A5),
        }]
    }

    calculateCostSum = () => {
        let BTA = this.state.BTA;
        let sumCOST = this.props.activeLab.properties.reduce((a, b) => {
            if (b.calculateElement) {
              return +a + +b.Cost;
            }
            return a;
          }, 0);
        let sumOfOrginalCOST = this.props.modelData.Labs[0].properties.reduce((a, b) => +a + +b.Cost, 0);
        let resultCOST = Math.round(sumCOST / BTA);

        let res = [{
            name: '',
            COST: Number(resultCOST) ? resultCOST : 0,
            TotalCost: Math.round(sumCOST),
            sumOfOrginalCOST: Math.round(sumOfOrginalCOST / BTA),
        }];    
        return res;
    }

    handleBTAInput = e => {
        const val = e.target.value;
        if ((!Number(val) && val !== '') || val.substring(val.length - 1, val.length) === ' ') return;
        this.props.modelData.BTA = val;
        this.setState({ BTA: val, });
    }

    calculate = () => {
      
        let a = this.calculateCostSum()
        let b = this.calculateCO2Sum()
        this.setState({
            resultCOST: a,
            resultCO2: b
           
        }, () => { this.props.triggerReset(); });

        

    }

    componentDidMount() {
       
        console.log('MOUNTED', this.props.modelData.BTA)
       // this.calculate();
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log('BTACHARTS updated', prevState);      
        if (this.props.modelData.model.fileName !== prevProps.modelData.model.fileName) this.calculate();
        if (prevProps.activeLab.name !== this.props.activeLab.name) this.calculate();
        if (prevProps.activeLab.name !== this.props.activeLab.name) this.calculate();      
        else if (this.props.triggerCalculation) this.calculate();
        
        //else console.log('did not calculate')
    }

    chartSelector = (e, con) => {
        e.stopPropagation()
        this.setState({
            chartSelector: con
        })
    }

    garphActivate = () => {
        this.setState({
          garphContainer: !this.state.garphContainer
        })
    }

    mergeData(CO2data, COSTdata) {
        let combined = null
        if (CO2data  != null && COSTdata != null) {
            for (let i = 0; i < CO2data.length; i++) {
                CO2data[i]['name'] = 'CO2'
                COSTdata[i].name = 'KR'
            }
            combined = CO2data.concat(COSTdata)
        }
        return combined
    }

    render() {      
        const COSTdata =  this.calculateCostSum()
        const CO2data =this.calculateCO2Sum()
        const mergedData = this.mergeData(CO2data, COSTdata)

        let height = ((document.getElementById("viewer-container").offsetHeight - 250) * 0.25);

        return (
            <>
            
                <div id="graph-button" ref={this.test1} onClick={(e) => {this.garphActivate()}} style={{zIndex: '5', position: 'relative', cursor: 'pointer', padding: "5px", borderBottom: "0.5px solid #d8d8d8", display: 'flex', justifyContent: 'space-between'}}> 
                    <h1 id="graph-text"> <Trans i18nKey="labArea.chart"/></h1>
                    { this.state.garphContainer ? 
                        <div id="chart-selector" onClick={(e) => {this.chartSelector(e, !this.state.chartSelector)}} style={{ minWidth: '230px', zIndex: '5', position: 'relative', cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}}> 
                            <button
                                type           = 'button'
                                className      = 'btn icon icon-arrow'
                                id             = 'FilterButton'
                                onClick        ={(e) => {this.chartSelector(e, false)}}
                                style={{transform: 'rotate(90deg)', backgroundPosition: 'center', opacity: this.state.chartSelector ? '1' : '.25'}}
                            >
                            </button>
                            <h1 id="chart-selector-text" style={{fontSize: "17px"}}> {this.state.chartSelector ? <Trans i18nKey="BTACharts.components"/>:<Trans i18nKey="BTACharts.A1-5&cost"/>}</h1>
                            <button
                                type           = 'button'
                                className      = 'btn icon icon-arrow'
                                id             = 'FilterButton'
                                onClick        ={(e) => {this.chartSelector(e, true)}}
                                style={{transform: 'rotate(-90deg)', backgroundPosition: 'center', opacity: this.state.chartSelector ? '0.25' : '1'}}
                            >
                            </button>
                        </div> : null
                    }
                    <button
                        type           = 'button'
                        className      = 'btn icon icon-arrow'
                        id             = 'FilterButton'
                        onClick        ={(e) => {this.garphActivate()}}
                        style={{transform: this.state.garphContainer ? 'rotate(180deg)' : 'rotate(360deg)', backgroundPosition: 'center'}}
                    >
                    </button>
                </div>
                <div id="viewer-charts-container"  ref={this.test2} className={`${this.state.garphContainer ? 'expanded' : '' }`}>
                    <div id="charts-revert" >
                        <div id="charts-container" className={`${this.state.chartSelector ? 'expanded' : '' }`}>
                            <BTAstackedBarChart 
                                data={mergedData} 
                                height={height*1.2} 
                                activeLab={this.props.activeLab} 
                                visible={this.props.visible}
                            />
                            <ResultChartCalculator
                                modelData={this.props.modelData}
                                activeLab={this.props.activeLab}
                                visible={this.props.visible}
                            />
                        </div>
                    </div>
                </div>
                <div id="co2-cost-bta-info"  ref={this.test3} >
                    <table className="table table-sm table-striped" >
                        <tbody>
                            <tr>
                                <th><span className="icon-sm icon-coins" /><Trans i18nKey="BTACharts.totCost"/></th>
                                <td>{COSTdata ? nrFormatter(Math.round(COSTdata[0].TotalCost / 1000)) : 0} k<Trans i18nKey="BTACharts.SEK"/></td>
                            </tr>
                            <tr>
                                <th><span className="icon-sm icon-cloud" /><Trans i18nKey="BTACharts.totCO2e"/></th>
                                <td>{CO2data ? nrFormatter(Math.round(CO2data[0].TotalCO2 / 1000)) : 0} <Trans i18nKey="BTACharts.tonne"/></td>
                            </tr>
                            <tr>
                                <th><span className="icon-sm icon-coins" /><Trans i18nKey="BTACharts.costPerGFA"/></th>
                                <td>{COSTdata ? nrFormatter(COSTdata[0].COST) : 0} <Trans i18nKey="BTACharts.SEK"/>/m<sup>2</sup></td>
                            </tr>
                            <tr>
                                <th><span className="icon-sm icon-cloud" /><Trans i18nKey="BTACharts.CO2ePerGFA"/> </th>
                                <td>{CO2data ? nrFormatter(CO2data[0].CO2) : 0} kg/m<sup>2</sup></td>
                            </tr>
                            <tr>
                                <th><Trans i18nKey="BTACharts.GFA"/></th>
                                <td>{nrFormatter(this.props.modelData.BTA)} m<sup>2</sup></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="row" style={{padding: '0.8rem', paddingTop: '0'}}>
                        <label htmlFor="bta-input" className="col-lg-5 col-form-label"><Trans i18nKey="BTACharts.setGFA"/> (m<sup>2</sup>)</label>
                        <div className="col-lg-7">
                            <input name="bta-input" className="form-control" type="text" value={this.state.BTA > 0 ? this.state.BTA : ''}
                                onChange={this.handleBTAInput} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

//export default BTACharts;
const MyComponent = withTranslation()(BTACharts)
export default MyComponent;